import React, { ReactNode } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Disclosure, Transition } from "@headlessui/react";

interface ExpandableSectionProps {
  header: React.ReactNode | string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  variant?: "default" | "inline";
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  header,
  children,
  defaultOpen = false,
  variant = "default",
}) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className="bg-primary border border-secondary-1000 sm:rounded-lg">
          <Disclosure.Button className={`flex w-full justify-between ${variant === 'inline' ? 'bg-secondary-1200' : 'bg-secondary-1100 sm:bg-primary sm:rounded-lg  '} items-center px-5 py-4`}>
            <h2 className="font-medium">{header}</h2>
            <ChevronDownIcon
              className={`h-5 w-5 transition-transform duration-300 transform ${
                open ? "rotate-180" : ""
              }`}
            />
          </Disclosure.Button>

          <Transition
            enter="transition-all duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0 max-h-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition-all duration-200 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0 max-h-0"
          >
            <Disclosure.Panel className="overflow-hidden border-t border-secondary-1000">
              <div>{children}</div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default ExpandableSection;
