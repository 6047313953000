import classNames from "classnames";
import React, { Fragment } from "react";
import Pagination from "../table-components/Pagination";

import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import InfiniteScroll from "../table-components/InfiniteScroll";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}
interface RowData {
  _id: string;
}

interface Column<T> {
  key: string;
  label: string;
  render: (item: T) => React.ReactNode;
  className?: string;
  sortable?: boolean;
  responsiveClass?: string;
}
type PaginationScheme = "default" | "infiniteScroll" | "none";

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  noDataMessage?: string | React.ReactNode;
  onRowClick?: (item: T) => void;
  handleSort?: (sortKey: string) => void;
  sortColumn?: string;
  sortDirection?: string;
  onSort?: (sortKey: string) => void;
  isClickable?: boolean;
  height?: string;
  showHeader?: boolean;
  stickyFirstColumn?: boolean;
  paginationScheme?: PaginationScheme;
  pageSize?: string;
  handlePageSizeChange?: (props: PageSizeChangeProps) => void;
  page?: string | number;
  handlePageChange?: (currentPage: number) => void;
  handleRefresh?: () => void;
  totalPages?: number;
  loading?: boolean;
}

const Table = <T,>({
  data,
  columns,
  noDataMessage = "No data available",
  onRowClick,
  handleSort,
  sortColumn,
  sortDirection,
  isClickable = true,
  showHeader = true,
  stickyFirstColumn = false,
  paginationScheme = "default",
  pageSize = "10",
  handlePageSizeChange,
  page = "1",
  handlePageChange,
  handleRefresh,
  totalPages = 1,
  loading = true,
}: TableProps<T>) => {
  const tableData = Array.isArray(data) ? data : [];

  const tableContainerClasses = classNames(
    `flex flex-col flex-grow relative thin-scrollbar overflow-y-auto overflow-x-auto`,
    { "mb-14": paginationScheme === "default" }
  );


  return (
    <div className="flex flex-col h-full overflow-hidden relative">
      <InfiniteScroll
        className={tableContainerClasses}
        hasMore={Number(page) < totalPages}
        next={() => handlePageChange && handlePageChange(Number(page) + 1)}
        pullDownToRefresh
        refreshFunction={() => handleRefresh && handleRefresh()}
        disabled={paginationScheme !== "infiniteScroll"}
        loading={loading}
      >
        {!tableData || tableData.length === 0 ? (
          <div className="flex items-center justify-center h-full w-full sm:bg-primary bg-secondary-1100">
            <span className="text-secondary-300">{noDataMessage}</span>
          </div>
        ) : (
          <table className={`min-w-full h-fit`}>
            <thead className={showHeader ? "" : "hidden"}>
              <tr className="sticky top-0 z-10 border-none border-secondary-1200 shadow-sm cursor-pointer bg-secondary-1200">
                {columns.map((column, index) => (
                  <th
                    key={column.key}
                    onClick={() =>
                      column.sortable && handleSort && handleSort(column.key)
                    }
                    scope="col"
                    className={`py-3.5  table-cell whitespace-nowrap group px-4 text-left text-xs font-normal text-secondary-300 cursor-pointer ${
                      column.className || ""
                    } ${
                      stickyFirstColumn && index === 0
                        ? "sticky left-0 bg-secondary-1200 z-10 "
                        : ""
                    }`}
                  >
                    <div className="flex items-center">
                      <span>{column.label}</span>
                      {column.sortable && (
                        <>
                          {sortColumn === column.key ? (
                            sortDirection === "1" ? (
                              <ArrowLongUpIcon className="text-secondary-500 h-3 w-3" />
                            ) : (
                              <ArrowLongDownIcon className="text-secondary-500 h-3 w-3" />
                            )
                          ) : (
                            <ArrowLongUpIcon className="text-secondary-500 h-3 w-3 text-transparent group-hover:text-secondary-400" />
                          )}
                        </>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <Fragment key={rowIndex}>
                  <tr
                    key={rowIndex}
                    className={`border-y border-secondary-1100  cursor-pointer`}
                    onClick={() => isClickable && onRowClick && onRowClick(row)}
                  >
                    {columns.map((column, colIdx) => (
                      <td
                        key={colIdx}
                        className={`sm:py-3 sm:px-4 text-xs text-secondary-100 max-w-24 ${
                          column.className ?? ""
                        } ${
                          stickyFirstColumn && colIdx === 0
                            ? "sticky left-0 bg-white"
                            : ""
                        }`}
                      >
                        {column.render(row)}
                      </td>
                    ))}
                  </tr>
                  <tr className="h-1 sm:h-0 bg-secondary-1200 flex w-full" />
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </InfiniteScroll>
      {paginationScheme === "default" && (
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange ?? (() => {})}
          currentPage={page}
          setCurrentPage={handlePageChange ?? (() => {})}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default Table;
