import { useNavigate } from "react-router-dom";
import { CalendarMonthOutlined, Person } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";
import Container from "../general/Container";
import Table from "../general/Table";
import { WorkOrder } from "../../types/WorkOrder";
import { useHome } from "../../hooks/useHome";
import WorkOrderCard from "../cards/WorkOrderCard";

const HomeWorkOrderTable = () => {
  const { currentWorkOrders } = useHome();

  const workOrders = currentWorkOrders.workOrders;
  const columns = [
    {
      label: "Request Title",
      key: "title",
      render: (workOrder: WorkOrder) => (
        <>
          <span className="hidden sm:inline-flex">{workOrder.shortId}</span>
          <div className="flex w-full sm:hidden" key={workOrder._id}>
            <WorkOrderCard workOrder={workOrder} />
          </div>
        </>
      ),
      className: "w-full max-w-0 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Description",
      key: "details",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {capitalizeString(workOrder.details)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Location",
      key: "location",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {capitalizeString(workOrder.location)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {calculateDays(workOrder.dateReported)} days ago
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "status",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.status} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {workOrder.assignedTo ? (
              <span>
                <Person style={{ fontSize: "1rem" }} className="h-4 w-4 mr-1" />
                {capitalizeString(
                  workOrder.assignedTo.firstname.slice(0, 1)
                )}{" "}
                {capitalizeString(workOrder.assignedTo.lastname)}
              </span>
            ) : (
              <span>
                <Person style={{ fontSize: "1rem" }} className="h-4 w-4 mr-1" />
                Unassigned
              </span>
            )}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];

  return (
    <Table
      data={workOrders}
      columns={columns}
      showHeader={window.innerWidth > 640 ? true : false}
      noDataMessage="No work orders to display."
      paginationScheme="none"
    />
  );
};

export default HomeWorkOrderTable;
