import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close, SearchOffOutlined } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { formatDate } from "../../utils/FormatFunctions";
import { TaskInstance } from "../../types/TaskInstance";
import { useLog } from "../../hooks/useLog";

interface ViewLogProps {}

const ViewLog: React.FC<ViewLogProps> = ({}) => {
  const { handleViewInstance, selectedLog } = useLog();

  const logEntries = selectedLog?.logEntries;

  return (
    <div className="min-h-32 max-h-64 flex">
      {logEntries && logEntries.length > 0 ? (
        <table className="w-full">
          <thead>
            <tr className="bg-secondary-1200 border border-secondary-1000 rounded-sm">
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Due Date
              </th>
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Date Completed
              </th>
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Date Closed
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                
              </th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((logEntry: TaskInstance) => (
              <Fragment key={logEntry._id}>
                <tr key={`${logEntry._id}-gap`} className="h-2"></tr>
                <tr
                  key={`${logEntry._id}-title`}
                  className="border border-secondary-1000 rounded-sm"
                >
                  <td className="primaryspace-nowrap py-1 pl-4 pr-2 text-sm font-medium text-secondary-100 ">
                    {formatDate(logEntry.date, "MMM Do YYYY", "utc")}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">{formatDate(logEntry.date)}</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {<StatusBadge status={logEntry.status} />}
                      </dd>
                      <dt className="sr-only sm:hidden">Status</dt>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDate(logEntry.dateCompleted, "MMM Do YYYY")}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDate(logEntry.dateClosed, "MMM Do YYYY")}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {<StatusBadge status={logEntry.status} />}
                  </td>
                  <td className=" px-3 py-4 text-sm text-gray-500 table-cell">
                    <button
                      className="text-accent-500 hover:text-accent-400 underline"
                      onClick={() => handleViewInstance(logEntry._id)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex items-center w-full justify-center text-secondary-100 text-center bg-secondary-1100">
          <div className="flex flex-col w-full justify-center items-center bg-gray-100 text-gray-500 py-8">
            <SearchOffOutlined style={{ fontSize: "2rem" }} />
            <span className="text-sm">No logs completed yet</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLog;
