import React, { useState } from "react";
import HomeWorkOrderTable from "../../updatedcomponents/tables/HomeWorkOrderTable";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import Button from "../../updatedcomponents/general/Button";
import { useHome } from "../../hooks/useHome";
import { useFacility } from "../../hooks/useFacility";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment-timezone";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowTrendingUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import ProgressWheel from "../../updatedcomponents/data-display/ProgressWheel";
import { Add, TimerOutlined } from "@mui/icons-material";
import Selector from "../../updatedcomponents/general-ui/Selector";
import Widget from "../../updatedcomponents/data-display/Widget";
import { useNavigate } from "react-router-dom";
import ExpandableSection from "../../updatedcomponents/general-ui/ExpandableSection";
import HomeRecurringTasks from "../../updatedcomponents/tables/HomeRecurringTasks";
interface TimeFrameOption {
  value: number;
  unit: moment.unitOfTime.DurationConstructor;
  label: string;
  timeString: string;
  timeFrame: string;
  displayText: string;
}

const Home: React.FC = () => {
  const {
    dateParams,
    workOrderStats,
    recurringTaskStats,
    setDateParams,
    handleReset,
    applyIndividualFilter,
    wo_filters,
    rec_filters,
    users,
    resetFilters,
  } = useHome();
  const navigate = useNavigate();

  const workOrderProgressData = {
    incomplete: Number(workOrderStats[2].stat),
    complete: Number(workOrderStats[3].stat),
    closed: Number(workOrderStats[4].stat),
    unable: Number(workOrderStats[5].stat),
  };

  const recurrringTaskProgressData = {
    incomplete: Number(recurringTaskStats[0].stat),
    complete: Number(recurringTaskStats[1].stat),
    closed: Number(recurringTaskStats[2].stat),
    overdue: Number(recurringTaskStats[3].stat),
  };
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();

  const facility = currentUser?.facility.find(
    (facility) => facility._id === selectedFacility
  );

  const timeFrameOptions: TimeFrameOption[] = [
    {
      value: 0,
      unit: "days",
      label: "Today",
      timeString: " yesterday",
      timeFrame: "day",
      displayText: "yesterday",
    },
    {
      value: 1,
      unit: "days",
      label: "Yesterday",
      timeString: " two days ago",
      timeFrame: "2day",
      displayText: "two days ago",
    },
    {
      value: 3,
      unit: "days",
      label: "3 Day",
      timeString: " three days ago",
      timeFrame: "3day",
      displayText: "three days ago",
    },
    {
      value: 1,
      unit: "weeks",
      label: "Week",
      timeString: " last week",
      timeFrame: "week",
      displayText: "last week",
    },
    {
      value: 1,
      unit: "months",
      label: "1 Month",
      timeString: " a month ago",
      timeFrame: "month",
      displayText: "last month",
    },
    // {
    //   value: 3,
    //   unit: "months",
    //   label: "3 Months",
    //   timeString: " three months ago",
    //   timeFrame: "3month",
    // },
  ];

  const filterOptions = {
    workOrder: {
      status: [
        { label: "All Statuses", value: "" },
        { label: "Incomplete", value: "incomplete" },
        { label: "Complete", value: "complete" },
        { label: "Closed", value: "closed" },
        { label: "Unable", value: "unable" },
      ],
      urgency: [
        { label: "All Urgencies", value: "" },
        { label: "Standard", value: "standard" },
        { label: "Urgent", value: "urgent" },
      ],
      assignedTo: [
        { label: "All Assignments", value: "" },
        ...users.map((user) => ({
          label: `${user.firstname} ${user.lastname}`,
          value: user._id,
        })),
      ],
    },
    recurringTasks: {
      status: [
        { label: "All Statuses", value: "" },
        { label: "Overdue", value: "overdue" },
        { label: "Incomplete", value: "incomplete" },
        { label: "Complete", value: "complete" },
      ],
    },
  };

  return (
    <div className="relative bg-secondary-1200 flex flex-col h-full">
      <PageHeader
        title={
          <div>
            <span className="hidden sm:inline">{facility?.name ?? ""}</span>
            <span className="sm:hidden">Dashboard</span>
          </div>
        }
        subtitle=""
        verticalStack
        children={
          <div className="hidden sm:flex w-full justify-between">
            <div className="flex flex-grow justify-between">
              <div className="hidden md:flex flex-col sm:flex-row justify-start border border-secondary-1000 divide-x w-full sm:w-fit rounded-lg">
                {timeFrameOptions.map((option, index) => (
                  <button
                    key={option.timeFrame}
                    type="button"
                    className={`relative inline-flex items-center py-2 rounded-sm sm:rounded-none ${
                      index === 0 ? "sm:rounded-l-sm" : "-ml-px"
                    } ${
                      index === timeFrameOptions.length - 1
                        ? "sm:rounded-r-sm"
                        : ""
                    } ${
                      dateParams.selectedRange === option.timeFrame
                        ? "bg-secondary-1100   text-secondary-100 "
                        : "bg-inherit hover:bg-gray-50 text-gray-900"
                    } px-3 py-0.5 text-sm   focus:z-10  transition-all ease-in-out duration-200`}
                    onClick={() => {
                      setDateParams((prevParams) => ({
                        ...prevParams,
                        displayText: option.displayText,
                        startDate: moment()
                          .subtract(option.value, option.unit)
                          .toDate(),
                        selectedRange: option.timeFrame,
                      }));
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex flex-grow justify-end gap-6">
              <div className="flex gap-3">
                <DateFilter
                  setDateParams={setDateParams}
                  dateParams={dateParams}
                />
                {/* <Popover className={"relative w-full sm:w-fit"}>
                  <Popover.Button className="flex py-2.5 w-40 justify-between px-4 border border-secondary-1000 rounded-lg text-secondary-300 text-sm">
                    Dataset
                    <ChevronDownIcon className="h-5 w-5 ui-open:transition ui-open:rotate-180" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-30 top-14 left-1/2 transform -translate-x-1/2 bg-primary p-4 gap-y-2 rounded-lg border border-secondary-1000">
                    <div className="w-52 flex flex-col gap-4">
                      <div className="flex justify-between ">
                        <label className="text-sm">Work Orders</label>
                        <input
                          type="checkbox"
                          className="text-accent-500 focus:ring-accent-500  cursor-pointer rounded"
                        />
                      </div>
                      <div className="flex justify-between">
                        <label className="text-sm">Recurring Tasks</label>
                        <input
                          type="checkbox"
                          className="text-accent-500 focus:ring-accent-500 rounded cursor-pointer"
                        />
                      </div>
                      <button className="mt-2 flex-1 flex items-center justify-center rounded-sm bg-secondary-100 px-2.5 py-0.5 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200">
                        Select All
                      </button>
                    </div>
                  </Popover.Panel>
                </Popover> */}
              </div>
              <div>
                <Button
                  icon="Refresh"
                  onClick={handleReset}
                  styleString="secondary"
                  children="Reset"
                />
              </div>
            </div>
          </div>
        }
      />
      <div className="w-full py-2 px-4">
        <div className="flex sm:hidden justify-between bg-primary border border-secondary-1000 divide-x w-full rounded-lg">
          {timeFrameOptions.map((option, index) => (
            <button
              key={option.timeFrame}
              type="button"
              className={`relative flex-1 text-nowrap inline-flex items-center py-2 sm:rounded-none ${
                index === 0 ? "rounded-l-lg" : "-ml-px"
              } ${
                index === timeFrameOptions.length - 1 ? "rounded-r-lg" : ""
              } ${
                dateParams.selectedRange === option.timeFrame
                  ? "bg-accent-200   text-primary "
                  : "bg-primary hover:bg-secondary-1200 text-gray-900"
              } px-3 py-0.5 text-sm focus:z-10  transition-all ease-in-out duration-200`}
              onClick={() => {
                setDateParams((prevParams) => ({
                  ...prevParams,
                  startDate: moment()
                    .subtract(option.value, option.unit)
                    .toDate(),
                  selectedRange: option.timeFrame,
                  displayText: option.label,
                }));
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      <div className="sm:p-4 flex flex-col gap-2 sm:gap-4  relative thin-scrollbar overflow-y-auto">
        {/* Work Order Section */}
        <ExpandableSection defaultOpen={true} header="Work Order Data">
          <div>
            <div className="flex flex-col-reverse sm:flex-row sm:p-4 w-full justify-between sm:divide-x divide-secondary-1000">
              <div className="flex-1 p-5 flex flex-col gap-4">
                {/* Top Widgets */}
                <div className="flex flex-row w-full justify-between sm:divide-x divide-secondary-1000 gap-6">
                  {workOrderStats.map((stat, index) => {
                    if (index < 2) {
                      return (
                        <Widget
                          key={stat.name}
                          color="bg-secondary-900"
                          status={stat.name}
                          count={stat.stat}
                          icon={stat.icon}
                          percentage={stat.change}
                          changeType={stat.changeType}
                          comparison={stat.previousStat}
                          variant={"detailed"}
                          interval={dateParams.displayText}
                          onClick={() => {
                            stat.handleClick && stat.handleClick()
                          }}
                        />

                      );
                    }

                  })}
                </div>
                {/* Widget Grid */}
                <div className="grid grid-cols-2 gap-5 sm:w-3/4">
                  {workOrderStats.map((stat, index) => {
                    if (index >= 2) {
                      return (
                        <div
                          key={stat.name}
                          className={
                            index === 1
                              ? "flex divide-x divide-secondary-1000 gap-5"
                              : ""
                          }
                        >
                          <Widget
                            color={stat.color ?? "bg-secondary-1000"}
                            status={stat.name}
                            count={stat.stat}
                            percentage={stat.change}
                            changeType={stat.changeType}
                            comparison={stat.previousStat}
                            onClick={() => {
                              stat.handleClick && stat.handleClick()
                            }}
                            interval={dateParams.displayText}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              <div className="flex-1 flex justify-center items-center border-b border-secondary-1000 py-4 -mx-6 sm:mx-0 sm:py-0 sm:border-b-0">
                <ProgressWheel
                  type={"work-orders"}
                  values={workOrderProgressData}
                />
              </div>
            </div>
            <div>
              <ExpandableSection defaultOpen={false} header="Work Order Table">
                <div className="flex w-full justify-between items-center px-5 py-2.5 border-y border-secondary-1000 bg-secondary-1200">
                  <h3 className="font-medium text-lg">Work Orders</h3>
                  <div className="hidden sm:flex gap-4">
                    {Object.entries(filterOptions.workOrder).map(
                      ([filterKey, options]) => (
                        <Selector
                          key={filterKey}
                          className={`bg-primary min-w-${
                            filterKey === "assignedTo" ? "48" : "32"
                          }`}
                          onChange={(selectedValue) => {
                            applyIndividualFilter(
                              filterKey,
                              String(selectedValue.value),
                              "wo"
                            );
                          }}
                          placeholder={
                            filterKey.charAt(0).toUpperCase() +
                            filterKey.slice(1)
                          }
                          value={
                            wo_filters[filterKey]
                              ? options.find(
                                  (option) =>
                                    option.value === wo_filters[filterKey]
                                )
                              : options[0]
                          }
                          options={options}
                        />
                      )
                    )}
                    <Button
                      icon="Refresh"
                      onClick={() => resetFilters()}
                      styleString="secondary"
                      children="Reset"
                    />
                    <Button
                      onClick={() => navigate("/work-orders")}
                      styleString="primary"
                      children="All Orders"
                    />
                  </div>
                  <div className="flex sm:hidden">
                    <Button
                      onClick={() => navigate("/work-orders")}
                      styleString="primary"
                      children="All Orders"
                    />
                  </div>
                </div>

                <HomeWorkOrderTable />
              </ExpandableSection>
            </div>
          </div>
        </ExpandableSection>

        {/* Recurring Task Section */}
        <ExpandableSection defaultOpen={true} header="Recurring Task Data">
          <div>
            <div className="flex flex-col-reverse sm:flex-row sm:p-4 w-full justify-between sm:divide-x divide-secondary-1000">
              <div className="flex-1 p-5 flex flex-col gap-4">
                {/* Widget Grid */}
                <div className="grid grid-cols-2 gap-5 sm:w-3/4">
                  {recurringTaskStats.map((stat, index) => {
                    return (
                      <div
                        key={stat.name}
                        className={
                          index === 1
                            ? "flex divide-x divide-secondary-1000 gap-5"
                            : ""
                        }
                      >
                        <Widget
                          color={stat.color ?? "bg-secondary-1000"}
                          status={stat.name}
                          count={stat.stat}
                          percentage={stat.change}
                          changeType={stat.changeType}
                          comparison={ undefined}
                          onClick={() => {
                            stat.handleClick && stat.handleClick()
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex-1 flex justify-center items-center border-b border-secondary-1000 py-4 -mx-6 sm:mx-0 sm:py-0 sm:border-b-0">
                <ProgressWheel
                  type={"recurring"}
                  values={recurrringTaskProgressData}
                />
              </div>
            </div>
            <div>
              <ExpandableSection
                defaultOpen={false}
                header="Recurring Task Table"
              >
                <div className="flex w-full justify-between items-center px-5 py-2.5 border-y border-secondary-1000 bg-secondary-1200">
                  <h3 className="font-medium text-lg">Recurring Tasks</h3>
                  <div className="hidden sm:flex gap-4">
                    {Object.entries(filterOptions.recurringTasks).map(
                      ([filterKey, options]) => (
                        <Selector
                          key={filterKey}
                          className={`bg-primary min-w-${
                            filterKey === "assignedTo" ? "48" : "32"
                          }`}
                          onChange={(selectedValue) => {
                            applyIndividualFilter(
                              filterKey,
                              String(selectedValue.value),
                              "rec"
                            );
                          }}
                          placeholder={
                            filterKey.charAt(0).toUpperCase() +
                            filterKey.slice(1)
                          }
                          value={
                            rec_filters[filterKey]
                              ? options.find(
                                  (option) =>
                                    option.value === rec_filters[filterKey]
                                )
                              : options[0]
                          }
                          options={options}
                        />
                      )
                    )}

                    <Button
                      icon="Refresh"
                      onClick={() => resetFilters()}
                      styleString="secondary"
                      children="Reset"
                    />
                    <Button
                      onClick={() => navigate("/tasks")}
                      styleString="primary"
                      children="All Recurring Tasks"
                    />
                  </div>
                  <div className="flex sm:hidden">
                    <Button
                      onClick={() => navigate("/tasks")}
                      styleString="primary"
                      children="All Recurring Tasks"
                    />
                  </div>
                </div>
                <HomeRecurringTasks />
              </ExpandableSection>
            </div>
          </div>
        </ExpandableSection>

        {/* <HomeChart /> */}
      </div>
    </div>
  );
};

export default Home;
