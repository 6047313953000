import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface SliderContainerProps {
  show: boolean;
  setShow: (show: boolean) => void;
  title?: React.ReactNode | string;
  description?: React.ReactNode | string;
  children: React.ReactNode;
}

const SliderContainer: React.FC<SliderContainerProps> = ({
  show,
  setShow,
  title,
  description,
  children,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-secondary-100 bg-opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom=" translate-y-12 sm:translate-y-0 sm:scale-95"
              enterTo=" translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom=" translate-y-0 sm:scale-100"
              leaveTo=" translate-y-12 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-t-2xl sm:rounded-2xl bg-white pt-5 text-left shadow-xl transition-all sm:my-8 w-full min-h-[90vh] h-[90vh] sm:max-w-md">
                <div className="absolute inset-0 pt-5">
                  <div className="flex h-full flex-col overflow-auto ">
                    <div className="px-5 flex-shrink-0">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="leading-4 text-secondary">
                            {title}
                          </Dialog.Title>
                          <Dialog.Description className="text-sm leading-2 text-secondary-500 py-2">
                            {description}
                          </Dialog.Description>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="justify-center items-center relative rounded-lg bg-white text-secondary-400 hover:text-secondary-500 focus:outline-none"
                            onClick={() => setShow(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-2 overflow-auto">
                      {children}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SliderContainer;
