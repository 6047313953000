import React, { useState } from 'react';
import { PencilIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';

interface EditableTextProps {
  text: string;
  onSave: (newText: string) => void;
  className?: string;
  multiline?: boolean;
  disableEdit?: boolean;
}

const EditableText: React.FC<EditableTextProps> = ({ text, onSave, className, multiline = false, disableEdit = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const handleEdit = () => {
    setEditedText(text);
    setIsEditing(true);
  };

  const handleSave = () => {
    onSave(editedText);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedText(text);
    setIsEditing(false);
  };

  const sharedInputStyles = "bg-transparent border border-secondary-800/50 focus:ring-1 focus:ring-inset focus:border-transparent focus:ring-secondary-900 rounded-md";

  if (isEditing) {
    return (
      <div className={`flex w-fit items-center gap-2 ${className}`}>
        {multiline ? (
          <textarea
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            className={`flex-grow min-h-[100px] p-1  ${sharedInputStyles} text-inherit font-inherit`}
            autoFocus
          />
        ) : (
          <input
            type="text"
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            className={`w-fit font-medium ${className} ${sharedInputStyles} text-inherit font-inherit`}
            autoFocus
          />
        )}
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            className="p-1 text-accent-500 hover:bg-secondary-1100 rounded-sm"
          >
            <CheckIcon className="h-4 w-4" />
          </button>
          <button
            onClick={handleCancel}
            className="p-1 text-reds-500 hover:bg-secondary-1100 rounded-sm"
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex items-center gap-2 w-full ${className}`}>
      <div className="text-inherit truncate flex-1 min-w-0">
        {text}
      </div>
      {!disableEdit && (
        <button
          onClick={handleEdit}
          className="h-fit text-secondary-500 hover:text-secondary-300 flex-shrink-0"
        >
          <PencilIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default EditableText;