import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";

interface Modal {
  open: boolean;
  setOpen: (open: boolean) => void;
  content: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
}

const Modal: React.FC<Modal> = ({
  open,
  setOpen,
  content,
  title = "",
  subtitle = "",
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        onClose={setOpen}
        className="relative z-50 max-h-screen"
      >
        <Transition.Child


          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Backdrop className="fixed z-30 inset-0 bg-secondary-500/15 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-in-out duration-300 sm:duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-300 sm:duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-40 inset-0 w-screen overflow-y-auto">
            <div className="font-body flex min-h-full justify-center text-center items-center ">
              <Dialog.Panel
                onClick={(e) => e.stopPropagation()} // Stop click event propagation
                className="min-w-[350px] relative transform overflow-clip rounded-lg bg-primary p-2 sm:p-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-fit  max-h-[calc(100vh-64px)]"
              >
                <Dialog.Title as="div" className="flex w-full justify-between">
                  <h2 className="leading-loose text-lg">{title}</h2>
                  <button onClick={() => setOpen(false)}>
                    <Close className="text-secondary-500" />
                  </button>
                </Dialog.Title>
                {typeof subtitle === "string" ? (
                  <h3 className="font-light text-sm w-4/5 pb-1">{subtitle}</h3>
                ) : (
                  <div className="font-light text-sm w-4/5 pb-1">
                    {subtitle}
                  </div>
                )}
                <div className="border-t sm:pt-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-thin">
                  {content}
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
