import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

// Define the option type
interface Option {
  id: string;
  name: string;
  icon?: React.ComponentType<any>; // Optional icon component
  bgColor?: string;
  textColor?: string;
}

interface CustomSelectorProps {
  options: Option[];
  value: Option;
  onChange: (value: Option) => void;
  label?: string;
}

export function CustomSelector({
  options,
  value,
  onChange,
  label,
}: CustomSelectorProps) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative w-full z-30">
          {label && (
            <Listbox.Label className="block text-sm font-medium text-secondary-400">
              {label}
            </Listbox.Label>
          )}

          <div className="relative">
            <Listbox.Button className="relative w-full cursor-pointer rounded-lg border  border-secondary-1000 bg-secondary-1200 py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-accent-500 sm:text-sm">
              <div className="flex items-center">
                {value.icon && (
                  <div className="h-7 w-7 bg-secondary-1100 rounded-md mr-2 flex items-center justify-center p-1">
                    <value.icon
                      className="h-5 w-5 text-accent-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <span className={`block text-sm font-medium truncate ${value.textColor || ""}`}>
                  {value.name}
                </span>
              </div>

              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-secondary-500"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-secondary-1200 py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                        active
                          ? "bg-primary-100 text-primary-900"
                          : "text-secondary-100"
                      } ${option.bgColor || ""}`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {option.icon && (
                            <option.icon
                              className="h-5 w-5 mr-2 text-secondary-500"
                              aria-hidden="true"
                            />
                          )}
                          <span
                            className={`block truncate ${
                              selected ? "font-semibold" : "font-normal"
                            } ${option.textColor || ""}`}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                              active ? "text-primary-600" : "text-primary-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
