import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFacility } from "../../hooks/useFacility";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import Input, { InputType } from "../general-ui/Input";
import Button from "../general/Button";
import { useAuth } from "../../hooks/useAuth";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";

type Input = {
  id: string;
  label: string;
  placeholder: string;
  type: InputType;
  options?: string[];
  className?: string;
};

type FormValues = {
  location: string;
  requestedBy: string;
  requestOrigin: string;
  title: string;
  requestType: string;
  details: string;
  urgency: string;
  status?: string;
  photos?: any[] | null;
};

interface NewWorkOrderProps {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewWorkOrder: React.FC<NewWorkOrderProps> = ({ setShowPopup }) => {
  const { currentUser } = useAuth();
  const { selectedFacility } = useFacility();
  const { createWorkOrder, kiosks } = useWorkOrder();

  const inputs: Input[] = [
    {
      id: "title",
      label: "Request Title",
      placeholder: "Request Title",
      type: "text",
    },
    {
      id: "location",
      label: "Request Location",
      placeholder: "Location",
      type: "text",
    },
    {
      id: "requestedBy",
      label: "Requested By",
      placeholder: "Requested By",
      type: "text",
    },
    {
      id: "requestType",
      label: "Request Type",
      placeholder: "Request Type",
      type: "select",
      options: ["Electrical", "Plumbing", "HVAC", "Other"],
    },
    {
      id: "requestOrigin",
      label: "Request Origin",
      placeholder: "Request Origin",
      type: "select",
      options: ["user-login", ...kiosks.map((kiosk) => kiosk.location)],
    },
    {
      id: "urgency",
      label: "Urgency",
      placeholder: "Urgency",
      type: "select",
      options: ["standard", "urgent"],
    },
    {
      id: "details",
      label: "Description",
      placeholder: "Details",
      type: "textarea",
      className: "sm:col-span-2",
    },
  ];

  const schema = yup.object().shape({
    location: yup.string().required("*This field is required."),
    requestedBy: yup.string().required("*This field is required."),
    requestOrigin: yup.string().required("*This field is required."),
    title: yup.string().required("*This field is required."),
    requestType: yup.string().required("*This field is required."),
    details: yup.string().required("*This field is required."),
    urgency: yup.string().required("*This field is required."),
    status: yup.string(),
    photos: yup.array().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      location: "",
      requestedBy: `${currentUser.firstname} ${currentUser.lastname}`,
      requestOrigin: "user-login",
      title: "",
      requestType: "",
      details: "",
      urgency: "standard",
      status: "incomplete",
      photos: [],
    },
  });

  // Logic for attaching and removing images
  const [photos, setPhotos] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string>("");

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setFileError("Only image files (JPG, JPEG, PNG) are allowed");
        return;
      }
      setFileError("");
      const newPhotos = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setPhotos((prevPhotos) => {
        const updatedPhotos = [...prevPhotos, ...newPhotos];
        setValue("photos", updatedPhotos);
        return updatedPhotos;
      });
    },
  });

  const removeFile = (index: number) => {
    const updatedPhotos = photos.filter((photo, i) => i !== index);
    setPhotos(updatedPhotos);
    setValue("photos", updatedPhotos);
  };

  // Logic For Submitting Form
  const onSubmit = async (data: FormValues) => {
    try {
      const formData = new FormData();
      formData.append("facility", selectedFacility);
      formData.append("dateReported", new Date().toISOString());
      Object.keys(data).forEach((key) => {
        if (key === "photos") {
          data[key]?.forEach((file) => {
            formData.append("photos", file);
          });
        } else {
          formData.append(key, data[key as keyof FormValues] as string | Blob);
        }
      });

      createWorkOrder(formData);

      setPhotos([]);
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="font-body w-full max-h-full flex flex-col flex-grow overflow-y-auto thin-scrollbar bg-primary"
      noValidate
    >
      {/* Divider container */}

      <div className="grid grid-cols-1 gap-2 py-6 sm:grid-cols-2 sm:gap-4 sm:py-0">
        {/* Project name */}
        {inputs.map((input) => (
          <div className={input.className} key={input.id}>
            <Input
              key={input.id}
              label={input.label}
              name={input.id}
              type={input.type}
              options={input.options}
              register={register}
              errors={errors[input.id as keyof FormValues]}
            />
          </div>
        ))}

        {/* Attachments */}
        <div className="sm:col-span-2">
          <label
            htmlFor="attachments"
            className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
          >
            Attachments
          </label>
          <div>
            <ul className="flex flex-wrap mb-2">
              {photos &&
                photos.map((file, index) => (
                  <li
                    key={file.name}
                    className="relative flex w-fit border-gray-300 border rounded-sm mr-2 mb-2"
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-24 h-24 object-contain rounded-sm"
                    />
                    <button
                      className="absolute top-0 right-0 text-gray-300 rounded-full bg-secondary-1100 bg-opacity-35"
                      type="button"
                      onClick={() => removeFile(index)}
                    >
                      <Close />
                    </button>
                  </li>
                ))}
            </ul>
            <div
              {...getRootProps()}
              className={`flex flex-col items-center justify-center rounded-sm border-secondary-900 border-dashed border sm:p-6 p-4 cursor-pointer hover:bg-gray-50 text-secondary-600 hover:text-gray-600 ${
                isDragReject ? 'border-reds-500' : ''
              }`}
            >
              <input {...getInputProps()} />
              <p className="text-sm">
                Drag 'n' drop here, or click to select images
              </p>
              <p className="text-xs text-gray-500 mt-1">
                Allowed file types: JPG, JPEG, PNG
              </p>
              {fileError && (
                <p className="text-xs text-reds-500 mt-1">{fileError}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="flex flex-col sm:flex-row gap-8 border-t border-secondary-1000 mt-4 pt-4 items-center">
        <div className="flex  items-center gap-2 bg-accent-1100 border-accent-800 border rounded-md py-3 px-2">
          <ShieldExclamationIcon className="h-6 w-6 text-accent-200" />
          <p className="flex w-fit items-center text-accent-200  text-wrap text-xs ">
            *By completing the form below you are verifying that no patient
            information has been entered.
          </p>
        </div>
        <div className="flex w-full gap-2 justify-between">
          <Button
            type="button"
            styleString="secondary"
            onClick={() => setShowPopup(false)}
            children="Cancel"
            overrideStyles="w-full"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Submit"
            disabled={isSubmitting}
            overrideStyles="w-full"
          />
        </div>
      </div>
    </form>
  );
};

export default NewWorkOrder;
