import { useEffect, useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  ArrowBackOutlined,
  ArrowForward,
  CatchingPokemon,
} from "@mui/icons-material";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  formatDate,
  stripTrailingCharacter,
} from "../../utils/FormatFunctions";
import Button from "../../updatedcomponents/general/Button";
import Modal from "../../updatedcomponents/general-ui/Modal";
import Input from "../../updatedcomponents/general-ui/Input";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useManageOnboarding } from "../../hooks/useManageOnboarding";
import { User } from "../../types/User";
import { Kiosk } from "../../types/Kiosk";
import {
  createMigratedFacility,
  createMigratedKiosk,
  createMigratedUser,
  createMigratedMap,
  updateMigrationProgress,
  createMigratedTask,
} from "../../api/services/AdminService";
import { useToast } from "../../hooks/useToast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { Map } from "../../types/Map";
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

type SectionTypes = "facility" | "users" | "kiosks" | "maps" | "tasks";

interface MapFieldData {
  ID: string;
  [key: string]: string; // For dynamic field key
}

type MapFormData = {
  mapName: string;
  fieldKey: string;
  fields: MapFieldData[];
  _id: string;
};
const ManageOnboardingInstance = () => {
  const [showSection, setShowSection] = useState<SectionTypes | null>(null);
  const [expandedMapIndex, setExpandedMapIndex] = useState<number | null>(null);
  const [migrating, setMigrating] = useState<boolean>(false);
  const [migrateIndex, setMigrateIndex] = useState<number | null>(null);
  const [modalOpenTimestamp, setModalOpenTimestamp] = useState<number>(0);
  const { showResponse } = useToast();

  const {
    selectedForm,
    handleNavigateBack,
    setSelectedForm,
    facilityMaps,
    completeMigration,
  } = useManageOnboarding();

  const { register, handleSubmit, reset, control, watch, setValue } =
    useForm<MapFormData>({
      defaultValues: {
        mapName: "",
        fieldKey: "Identifier",
        fields: [],
      },
    });

  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: "fields",
  });
  const fieldKey = watch("fieldKey");
  const mapName = watch("mapName");

  useEffect(() => {
    if (selectedForm && migrateIndex !== null) {
      const map = selectedForm.maps[migrateIndex];
      if (map?.fields) {
        const transformedFields = map.fields.map(
          (field: any, index: number) => {
            const idPrefix = map.name
              .replace(/[aeiou]/gi, "")
              .slice(0, 3)
              .toUpperCase();
            const idSuffix =
              index < 10 ? `00${index}` : index < 100 ? `0${index}` : index;
            return {
              ID: `${idPrefix}-${idSuffix}`,
              [fieldKey]: field.identifier,
            };
          }
        );
        setValue("fields", transformedFields);
      }
    }
  }, [selectedForm, migrateIndex, fieldKey, setValue, modalOpenTimestamp]);

  if (!selectedForm) return null;
  const { facility, users, kiosks, maps, tasks, progress } = selectedForm;

  const toggleShowSection = (section: SectionTypes) => {
    setShowSection(section === showSection ? null : section);
  };
  const toggleMapFields = (index: number) => {
    setExpandedMapIndex(expandedMapIndex === index ? null : index);
  };

  const isSectionMigrated = (sectionName: string) => {
    return (
      selectedForm?.progress?.migratedSections?.includes(sectionName) || false
    );
  };

  const isUserMigrated = (originalUserId: string) => {
    const migratedUsers = selectedForm?.progress?.migratedUsers || [];
    return migratedUsers.some(
      (mapping: { originalId: string }) => mapping.originalId === originalUserId
    );
  };

  const isKioskMigrated = (originalKioskId: string) => {
    const migratedKiosks = selectedForm?.progress?.migratedKiosks || [];
    return migratedKiosks.some(
      (mapping: { originalId: string }) =>
        mapping.originalId === originalKioskId
    );
  };

  const isMapMigrated = (originalMapId: string) => {
    const migratedMaps = selectedForm?.progress?.migratedMaps || [];
    return migratedMaps.some(
      (mapping: { originalId: string }) => mapping.originalId === originalMapId
    );
  };

  const isTaskMigrated = (templateId: string | { _id: string }) => {
    const migratedTasks = selectedForm?.progress?.migratedTasks || [];
    const id = typeof templateId === "string" ? templateId : templateId._id;
    return migratedTasks.some(
      (mapping: { originalId: string }) => mapping.originalId === id
    );
  };

  const handleMigrateSubmit = async (section: string, values: any) => {
    try {
      if (section === "facility") {
        const facilityData = {
          name: values.name,
          location: values.address,
          formId: selectedForm._id,
          ...(values.region && { region: values.region }),
        };
        const response = await createMigratedFacility(facilityData);
        console.log("New facility data:", response);

        await updateMigrationProgress(selectedForm._id, "facility");

        if (response.form) {
          setSelectedForm(response.form);
        }

        setMigrating(false);
        showResponse(
          "Facility info migrated",
          "Facility information has been successfully migrated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      showResponse(
        "Error",
        "There was an error submitting the migration",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error submitting migration:", error);
    }
    try {
      if (section === "users") {
        const userData = {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone,
          access: values.access,
          facility: selectedForm.facility.migratedFacilityId,
          formId: selectedForm._id,
          originalUserId: values._id,
        };

        const response = await createMigratedUser(userData);

        if (response.form) {
          setSelectedForm(response.form);
        }

        setMigrating(false);
        showResponse(
          "Success",
          "User information has been successfully migrated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      // Handle the email exists error
      if (
        error instanceof Error &&
        (error as any).response?.data?.error === "Email already in use"
      ) {
        showResponse(
          "Error",
          "This email address is already registered in the system. Please use a different email.",
          <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
        );
      } else {
        showResponse(
          "Error",
          "There was an error migrating the user. Please try again.",
          <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
        );
      }
    }
    try {
      if (section === "kiosks") {
        console.log("Values received:", values);
        const kioskData = {
          name: values.name,
          location: values.location,
          pin: values.pin,
          facility: selectedForm.facility.migratedFacilityId, // Use migrated facility ID
          formId: selectedForm._id,
          originalId: values._id,
        };

        const { kiosk, form } = await createMigratedKiosk(kioskData);

        if (form) {
          setSelectedForm(form);
        }

        setMigrating(false);
        showResponse(
          "Success",
          "Kiosk information has been successfully migrated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      console.error("Error submitting migration:", error);
      showResponse(
        "Error",
        "There was an error submitting the migration",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
    try {
      if (section === "maps") {
        console.log("Submit values:", values);
        //only migrate if the map is not skipped
        if (values.skipped || !values.fields?.length) {
          // Skip migration
          console.log(
            "Skipping map migration - map is skipped or has no fields"
          );
          return;
        }

        // Migrate map
        const mapData = {
          name: values.mapName,
          fields: values.fields,
          facility: selectedForm.facility.migratedFacilityId,
          formId: selectedForm._id,
          originalId: values._id,
        };

        const { map, form } = await createMigratedMap(mapData);

        if (form) {
          setSelectedForm(form);
        }

        setMigrating(false);
        showResponse(
          "Success",
          "Map information has been successfully migrated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      console.error("Error submitting migration:", error);
      showResponse(
        "Error",
        "There was an error submitting the migration",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
    try {
      if (section === "tasks") {
        // Only migrate non-skipped tasks
        if (values.skipped) {
          console.log("Skipping task migration - task is skipped");
          return;
        }

        const taskData = {
          title: values.title,
          taskType: values.taskType,
          category: values.regulationCategory,
          source: values.source,
          recurrence: {
            frequency: values.frequency,
          },
          inputs: values.inputs,
          details: values.details,
          skipWeekends: false, // default value
          sourceTemplateId: values._id,
          startDate: values.startDate,
          map: values.map,
          facility: selectedForm.facility.migratedFacilityId,
          formId: selectedForm._id,
          originalId: values._id,
          templateId: values.templateId || values._id,
        };

        console.log("Submitting task data:", taskData);
        const response = await createMigratedTask(taskData);
        console.log("Response from create task:", response);

        if (response.form) {
          setSelectedForm(response.form);
        }

        setMigrating(false);
        showResponse(
          "Success",
          "Task information has been successfully migrated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      console.error("Error submitting migration:", error);
      showResponse(
        "Error",
        "There was an error submitting the migration",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const handleFormSwitch = (sectionId: SectionTypes, index: number) => {
    console.log(`Switching to form for ${sectionId} at index ${index}`);
    setModalOpenTimestamp(Date.now());
    setMigrateIndex(index);
    const section = selectedForm[sectionId];
    // If section is not an array, return section
    let data;
    if (sectionId === "facility") {
      data = section;
    } else if (sectionId === "users") {
      data = section[index];
    } else if (sectionId === "kiosks") {
      const kiosk = section[index];
      const facility = selectedForm.facility;
      const fourRandomDigits = Math.floor(1000 + Math.random() * 9000);
      data = {
        location: kiosk.location,
        name: `${facility.name} ${kiosk.location}`,
        pin: fourRandomDigits.toString(),
        _id: kiosk._id,
      };
      console.log(data);
    } else if (sectionId === "maps") {
      const maps = section[index];
      console.log(maps);
      data = {
        mapName: maps.name,
        fieldKey: "Identifier", //default key name
        fields: maps.fields.map((field: any) => ({
          identifier: field.identifier,
        })),
        _id: maps._id || maps.mapTemplateId._id,
      };
      console.log(data);
    } else if (sectionId === "tasks") {
      const task = selectedForm.tasks[index];
      data = {
        title: task.customTitle || task.templateId.title,
        startDate: task.startDate,
        _id: task.templateId._id,
        taskType: task.templateId.taskType,
        regulationCategory: task.templateId.category,
        source: task.templateId.source,
        frequency: task.templateId.recurrence.frequency,
        details: task.templateId.details,
        skipWeekends: task.skipWeekends ? undefined : false,
        inputs: task.templateId.inputs,
      };
      console.log("Form data prepared:", data);
    }
    reset(data);
    setMigrating(true);
  };

  const calculateId = (index: number) => {
    const idPrefix = mapName
      .replace(/[aeiou]/gi, "")
      .slice(0, 3)
      .toUpperCase();
    const idSuffix =
      index < 10 ? `00${index}` : index < 100 ? `0${index}` : index;
    return `${idPrefix}-${idSuffix}`;
  };

  const addNewRow = (index: number) => {
    // Get current form values
    const currentValues = watch();

    // Get all current field values with their actual input values
    const currentFields = fields.map((field, idx) => ({
      ...field,
      [fieldKey]:
        currentValues.fields?.[idx]?.[fieldKey] || field[fieldKey] || "",
    }));

    // Calculate insert position
    const insertIndex = index !== undefined ? index + 1 : currentFields.length;

    // Create new empty row
    const newRow = {
      ID: calculateId(insertIndex),
      [fieldKey]: "",
    };

    // Insert the new row
    const updatedFields = [
      ...currentFields.slice(0, insertIndex),
      newRow,
      ...currentFields.slice(insertIndex),
    ];

    // Update all IDs to maintain sequence
    const finalFields = updatedFields.map((field, idx) => ({
      ...field,
      ID: calculateId(idx),
    }));

    // Update form
    setValue("fields", finalFields);
  };

  // const removeRow = (index: number) => {
  //   remove(index);
  //   // Recalculate IDs for remaining rows
  // setTimeout(() => {
  //   fields.forEach((_, i) => {
  //     if (i >= index) {
  //       setValue(`fields.${i}.ID`, calculateId(i));
  //     }
  //   });
  // }, 0);
  // };

  const removeRow = (index: number) => {
    // Get current form values
    const currentValues = watch();

    // Get current fields with their values
    const currentFields = fields.map((field, idx) => ({
      ...field,
      [fieldKey]:
        currentValues.fields?.[idx]?.[fieldKey] || field[fieldKey] || "",
    }));

    // Remove the row
    const updatedFields = currentFields.filter((_, idx) => idx !== index);

    // Update IDs for all remaining fields
    const finalFields = updatedFields.map((field, idx) => ({
      ...field,
      ID: calculateId(idx),
    }));

    // Update entire fields array at once
    setValue("fields", finalFields);
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    move(fromIndex, toIndex);
    // Recalculate all IDs after move
    setTimeout(() => {
      fields.forEach((_, index) => {
        setValue(`fields.${index}.ID`, calculateId(index));
      });
    }, 0);
  };

  const sections = [
    {
      name: "facility",
      label: "Facility Information",
      content: (
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2 py-2 w-fit">
            <span className="text-sm font-light text-secondary-300">Name</span>
            <span className="text-sm text-secondary-100">{facility.name}</span>
            <span className="text-sm font-light text-secondary-300">
              Address
            </span>
            <span className="text-sm text-secondary-100">
              {facility.address}
            </span>
          </div>
          <span className="w-fit">
            <Button
              children="Migrate"
              styleString="primary"
              onClick={() => handleFormSwitch("facility", 0)}
              disabled={isSectionMigrated("facility")}
            />
          </span>
        </div>
      ),
      migrateContentForm: () => (
        <form
          onSubmit={handleSubmit((values) =>
            handleMigrateSubmit("facility", values)
          )}
          className="grid grid-cols-5 gap-x-4 items-center"
        >
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2.5 rounded divide-y">
            <Input
              label="Facility Name"
              name="name"
              type="text"
              register={register}
              disabled={true}
            />
            <Input
              label="Facility Address"
              name="address"
              type="text"
              register={register}
              disabled={true}
            />
          </div>
          <div className="flex justify-center col-span-1">
            <ArrowForward className="text-secondary-300" />
          </div>
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2 rounded divide-y">
            <Input
              label="Facility Name"
              name="name"
              type="text"
              placeholder="Enter new name"
              register={register}
            />
            <Input
              label="Facility Address"
              type="text"
              placeholder="Enter new address"
              name="address"
              register={register}
            />
          </div>
          <div className="col-span-5 flex w-full gap-2 pt-4">
            <Button
              styleString="secondary"
              children="Cancel"
              onClick={() => setMigrating(false)}
            />
            <Button children="Submit" type="submit" />
          </div>
        </form>
      ),
    },
    {
      name: "users",
      label: "Users",
      content: users.map((user: User, index: number) => (
        <div key={user._id} className="grid grid-cols-2">
          <div className="grid grid-cols-2 py-2 w-full" key={index}>
            <span className="text-sm font-light text-secondary-300">Name</span>
            <span className="text-sm text-secondary-100">
              {user.firstname} {user.lastname}
            </span>
            <span className="text-sm font-light text-secondary-300">Email</span>
            <span className="text-sm text-secondary-100">{user.email}</span>
            <span className="text-sm font-light text-secondary-300">Phone</span>
            <span className="text-sm text-secondary-100">{user.phone}</span>
            <span className="text-sm font-light text-secondary-300">
              Access
            </span>
            <span className="text-sm text-secondary-100">{user.access}</span>
          </div>
          <span className="w-fit">
            <Button
              children="Migrate"
              styleString="primary"
              onClick={() => handleFormSwitch("users", index)}
              disabled={isUserMigrated(user._id)}
            />
          </span>
        </div>
      )),
      migrateContentForm: () => (
        <form
          onSubmit={handleSubmit((values) =>
            handleMigrateSubmit("users", values)
          )}
          className="grid grid-cols-5 gap-x-4 items-center"
        >
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2.5 rounded divide-y">
            <Input
              label="First Name"
              name="firstname"
              type="text"
              register={register}
              disabled={true}
            />
            <Input
              label="Last Name"
              name="lastname"
              type="text"
              register={register}
              placeholder={users[migrateIndex || 0]?.lastname}
              disabled={true}
            />
            <Input
              label="Email"
              name="email"
              type="text"
              register={register}
              placeholder={users[migrateIndex || 0]?.email}
              disabled={true}
            />
            <Input
              label="Phone"
              name="phone"
              type="text"
              register={register}
              placeholder={users[migrateIndex || 0]?.phone}
              disabled={true}
            />
            <Input
              label="Access"
              name="access"
              type="text"
              register={register}
              placeholder={users[migrateIndex || 0]?.access}
              disabled={true}
            />
          </div>
          <div className="flex justify-center col-span-1">
            <ArrowForward className="text-secondary-300" />
          </div>
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2 rounded divide-y">
            <Input
              label="First Name"
              name="firstname"
              type="text"
              register={register}
            />
            <Input
              label="Last Name"
              name="lastname"
              type="text"
              register={register}
            />
            <Input label="Email" name="email" type="text" register={register} />
            <Input label="Phone" name="phone" type="text" register={register} />
            <Input
              label="Access"
              name="access"
              type="select"
              options={[
                "worker",
                "administrator",
                "director",
                "regional",
                "organization-admin",
              ]}
              register={register}
              placeholder={users[migrateIndex || 0]?.access}
            />
          </div>
          <div className="col-span-5 flex w-full gap-2 pt-4">
            <Button
              styleString="secondary"
              children="Cancel"
              onClick={() => setMigrating(false)}
            />
            <Button children="Submit" type="submit" />
          </div>
        </form>
      ),
    },
    {
      name: "kiosks",
      label: "Kiosks",
      content: kiosks.map((kiosk: Kiosk, index: number) => {
        const migratedKiosk = selectedForm.progress.migratedKiosks?.find(
          (mapping: { originalId: string }) => mapping.originalId === kiosk._id
        );
        return (
          <div className="grid grid-cols-2" key={kiosk._id}>
            <div className="grid grid-cols-2 w-full py-3">
              <dt className="text-sm font-light text-secondary-300">
                Location
              </dt>
              <dd className="text-sm text-secondary-100">{kiosk.location}</dd>
            </div>
            <div className="w-fit space-x-2">
              <Button
                children="Migrate"
                onClick={() => handleFormSwitch("kiosks", index)}
                disabled={isKioskMigrated(kiosk._id)}
                styleString="primary"
              />
              {migratedKiosk && (
                <Button
                  children="Copy Kiosk Link"
                  styleString="secondary"
                  onClick={() => handleCopyLink(migratedKiosk.newId)}
                />
              )}
            </div>
          </div>
        );
      }),
      migrateContentForm: () => (
        <form
          onSubmit={handleSubmit((values) =>
            handleMigrateSubmit("kiosks", values)
          )}
          className="grid grid-cols-5 gap-x-4 items-center"
        >
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2.5 rounded divide-y">
            <Input
              label="Location"
              name="location"
              type="text"
              register={register}
              disabled={true}
            />
          </div>
          <div className="flex justify-center col-span-1">
            <ArrowForward className="text-secondary-300" />
          </div>
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2 rounded divide-y">
            <Input
              label="Location"
              name="location"
              type="text"
              register={register}
            />
            <Input label="Name" name="name" type="text" register={register} />
            <Input label="Pin" name="pin" type="text" register={register} />
          </div>
          <div className="col-span-5 flex w-full gap-2 pt-4">
            <Button
              styleString="secondary"
              children="Cancel"
              onClick={() => setMigrating(false)}
            />
            <Button children="Submit" type="submit" />
          </div>
        </form>
      ),
    },
    {
      name: "maps",
      label: "Maps",
      content: maps.map((map: any, index: number) => (
        <div className="grid grid-cols-1 my-2" key={map._id}>
          <div className="grid grid-cols-3 items-center py-2">
            <span className="text-sm font-medium text-secondary-100">
              {map.name}
            </span>

            {!map.skipped ? (
              map.fields.length > 0 && (
                <button
                  type="button"
                  className="text-sm text-secondary-300 w-fit"
                  onClick={() => toggleMapFields(index)}
                >
                  {/* {expandedMapIndex === index ? "Hide" : "Show"} */}
                </button>
              )
            ) : (
              <span className="text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs w-fit">
                Skipped
              </span>
            )}
            {!map.skipped && (
              <span className="w-fit">
                <Button
                  children="Migrate"
                  onClick={() => handleFormSwitch("maps", index)}
                  disabled={isMapMigrated(map._id)}
                />
              </span>
            )}
          </div>
          {expandedMapIndex === index && (
            <div>
              {map.fields.map((field: any, fieldIndex: number) => (
                <div className="ml-6 border-l pl-1" key={fieldIndex}>
                  <span className="text-sm text-secondary-100">
                    {field.identifier}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )),
      migrateContentForm: () => (
        <form
          onSubmit={handleSubmit((values) =>
            handleMigrateSubmit("maps", values)
          )}
          className="grid grid-cols-5 gap-x-4 items-center"
        >
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2.5 rounded divide-y">
            <Input
              label="Map Name"
              name="mapName"
              type="text"
              register={register}
              placeholder={maps[migrateIndex || 0]?.name}
              disabled={true}
            />
            <h3>Items:</h3>
            <div className="flex flex-col  text-secondary-300">
              {maps[migrateIndex || 0]?.fields?.map(
                (field: any, index: number) => (
                  <span key={index} className="ml-4 text-sm text-secondary-100">
                    {field.identifier}
                  </span>
                )
              )}
            </div>
          </div>
          <div className="flex justify-center col-span-1">
            <ArrowForward className="text-secondary-300" />
          </div>
          <div className="flex flex-col gap-4 col-span-2 border border-secondary-1000 p-2 rounded divide-y">
            <Input
              label="Map Name"
              name="mapName"
              type="text"
              register={register}
              placeholder={maps[migrateIndex || 0]?.name}
            />
            <table className="table-auto w-full">
              <thead className="bg-secondary-1100">
                <tr>
                  <th className="w-1/2 border-secondary-1000 py-2 border">
                    <input
                      type="text"
                      className="bg-inherit border-none focus:ring-0 p-1 w-full font-medium text-xs"
                      value="ID"
                      disabled={true}
                    />
                  </th>
                  <th className="w-1/2 border-secondary-1000 py-2 border">
                    <Controller
                      name="fieldKey"
                      control={control}
                      defaultValue={fieldKey}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className="bg-inherit border-none focus:ring-0 p-1 w-full font-medium text-xs"
                        />
                      )}
                    />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => (
                  <tr key={field.id}>
                    <td className="border border-secondary-1000 p-2">
                      <Controller
                        name={`fields.${index}.ID`}
                        control={control}
                        render={({ field: { value } }) => (
                          <input
                            value={value}
                            readOnly
                            className="bg-inherit border-none focus:ring-0 p-1 w-full text-xs"
                          />
                        )}
                      />
                    </td>
                    <td className="border border-secondary-1000 p-2 h-12">
                      <Controller
                        name={`fields.${index}.${fieldKey}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            value={value}
                            onChange={onChange}
                            className="bg-inherit border-none focus:ring-0 p-1 w-full text-xs"
                          />
                        )}
                      />
                    </td>
                    <td className="border border-secondary-1000 p-2 h-12 flex gap-2">
                      <div className="bg-secondary-1000 flex flex-row gap-2 p-1  justify-center items-center text-secondary-300">
                        <button
                          disabled={index === 0}
                          type="button"
                          className="text-secondary-300 disabled:text-secondary-700"
                          onClick={() => moveRow(index, index - 1)}
                        >
                          <ChevronUpIcon className="h-4 w-4 " />
                        </button>

                        <button
                          disabled={index === fields.length - 1}
                          type="button"
                          className="text-secondary-300 disabled:text-secondary-700"
                          onClick={() => moveRow(index, index + 1)}
                        >
                          <ChevronDownIcon className="h-4 w-4 " />
                        </button>
                      </div>
                      <button
                        type="button"
                        className="text-reds-500"
                        onClick={() => addNewRow(index)}
                      >
                        <PlusIcon className="h-4 w-4" />
                      </button>
                      <button
                        type="button"
                        className="text-reds-500"
                        onClick={() => removeRow(index)}
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              type="button"
              styleString="bold"
              children="Add Row"
              icon="Add"
              onClick={() => addNewRow(fields.length - 1)}
            />
          </div>
          <div className="col-span-5 flex w-full gap-2 pt-4">
            <Button
              styleString="secondary"
              children="Cancel"
              onClick={() => setMigrating(false)}
            />
            <Button children="Submit" type="submit" />
          </div>
        </form>
      ),
    },
    {
      name: "tasks",
      label: "Tasks",
      content: tasks.map((task: any, index: number) => (
        <div className="grid grid-cols-2 py-2" key={task._id}>
          <div className="flex flex-col">
            <span className="text-sm text-secondary-100">
              {task.customTitle || task.templateId.title}
            </span>
            {task.skipped ? (
              <span className="text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs w-fit">
                Skipped
              </span>
            ) : (
              <span className="text-sm text-secondary-100">
                {formatDate(task.startDate ?? "")}
              </span>
            )}
          </div>
          <span className="w-fit py-2">
            <Button
              children="Migrate"
              onClick={() => handleFormSwitch("tasks", index)}
              disabled={task.skipped || isTaskMigrated(task.templateId)}
            />
          </span>
        </div>
      )),
      migrateContentForm: () => (
        <form
          onSubmit={handleSubmit((values) =>
            handleMigrateSubmit("tasks", values)
          )}
          className="grid grid-cols-5 gap-x-4 items-center"
        >
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2.5 rounded divide-y">
            <Input
              label="Task Name"
              name="title"
              type="text"
              register={register}
              disabled={true}
            />
            <Input
              label="Start Date"
              name="startDate"
              type="date"
              register={register}
              disabled={true}
            />
          </div>
          <div className="flex justify-center col-span-1">
            <ArrowForward className="text-secondary-300" />
          </div>
          <div className="flex flex-col col-span-2 border border-secondary-1000 p-2 rounded divide-y">
            <Input
              label="Task Name"
              name="title"
              type="text"
              register={register}
            />
            <Input
              label="Map"
              name="map"
              type="select"
              options={[
                { value: "", label: "No Map" },
                ...facilityMaps.map((map: any) => ({
                  value: map._id,
                  label: map.name,
                })),
              ]}
              register={register}
            />
            <Input
              label="Start Date"
              name="startDate"
              type="date"
              register={register}
            />
            <Input
              label="Task Type"
              name="taskType"
              type="select"
              options={[
                { value: "life-safety", label: "Life Safety" },
                { value: "prev-maint", label: "Preventative Maintenance" },
              ]}
              register={register}
            />
            <Input
              label="Regulation Category"
              name="regulationCategory"
              type="select"
              options={[
                { value: "fire safety", label: "Fire Safety" },
                { value: "electrical safety", label: "Electrical Safety" },
                { value: "operational safety", label: "Operational Safety" },
                { value: "gas safety", label: "Gas/Air Safety" },
              ]}
              register={register}
            />
            <Input
              label="Source"
              name="source"
              type="select"
              options={[
                { value: "internal", label: "Internal" },
                { value: "external", label: "External" },
              ]}
              register={register}
            />
            <Input
              label="Frequency"
              name="frequency"
              type="select"
              options={[
                { value: "daily", label: "Daily" },
                { value: "weekly", label: "Weekly" },
                { value: "bi-weekly", label: "Biweekly" },
                { value: "monthly", label: "Monthly" },
                { value: "quarterly", label: "Quarterly" },
                { value: "semi-annually", label: "Semi-Annually" },
                { value: "annually", label: "Annually" },
                { value: "biennially", label: "2-Year" },
                { value: "3-year", label: "3-Year" },
                { value: "4-year", label: "4-Year" },
                { value: "5-year", label: "5-Year" },
              ]}
              register={register}
            />
            <Input
              label="Details"
              name="details"
              type="text"
              register={register}
            />
            <Input
              label="Skip Weekends"
              name="skipWeekends"
              type="select"
              options={[
                { value: "false", label: "No" },
                { value: "true", label: "Yes" },
              ]}
              register={register}
            />
          </div>
          <div className="col-span-5 flex w-full gap-2 pt-4">
            <Button
              styleString="secondary"
              children="Cancel"
              onClick={() => setMigrating(false)}
            />
            <Button children="Submit" type="submit" />
          </div>
        </form>
      ),
    },
  ];

  const handleCopyLink = (kioskId?: string) => {
    try {
      const baseUrl = window.location.origin;
      const path = kioskId
        ? `/kiosk/${kioskId}`
        : `/onboarding/${selectedForm._id}`;

      navigator.clipboard.writeText(`${baseUrl}${path}`);

      showResponse(
        kioskId ? "Kiosk link copied" : "Link copied",
        kioskId ? "Kiosk link copied to clipboard" : "Link copied to clipboard",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    }
  };

  return (
    <>
      <PageHeader
        title={
          <button onClick={handleNavigateBack} className=" text-secondary-100">
            <div className="flex items-center gap-2">
              <ArrowBackOutlined
                className="text-secondary-300"
                style={{ fontSize: "1rem" }}
              />
              <span>Back to forms</span>
            </div>
          </button>
        }
        subtitle={`Form ${selectedForm?._id}`}
        buttons={
          <Button
            onClick={() => handleCopyLink()}
            children="Copy Link"
            styleString="secondary"
          />
        }
      />
      <div className="flex flex-col p-4 sm:px-8 w-full gap-y-2 divide-y divide-secondary-1000">
        {sections.map((section) => (
          <div key={section.name}>
            <div
              onClick={() => toggleShowSection(section.name as SectionTypes)}
              className="cursor-pointer flex w-full justify-between mt-2"
            >
              <label className="text-md font-light text-secondary-100 cursor-pointer">
                {section.label}
              </label>
              {progress.completedSections.includes(section.name) ? (
                <StatusBadge status="complete" />
              ) : (
                <StatusBadge status="incomplete" />
              )}
            </div>
            {showSection === section.name && section.content}
          </div>
        ))}
        <div className="pt-4">
          <Button
            children="Complete Migration"
            disabled={selectedForm.isMigrated}
            onClick={() => completeMigration(selectedForm._id)}
          />
        </div>
        <Modal
          title={`Migrate ${stripTrailingCharacter(
            capitalizeString(showSection),
            "s"
          )}`}
          open={migrating}
          content={sections
            .find((section) => section.name === showSection)
            ?.migrateContentForm()}
          setOpen={() => setMigrating(false)}
        />
      </div>
    </>
  );
};

export default ManageOnboardingInstance;
