import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  assignOnboardingForm,
  completeFormMigration,
  createOnboardingForm,
  fetchOnboardingForm,
  fetchOnboardingForms,
  fetchOrganizations,
  getFacilityMaps,
} from "../api/services/AdminService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Map } from "../types/Map";
import { Organization } from "../types/Organization";
import { User } from "../types/User";
import { useToast } from "../hooks/useToast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

interface ManageOnboardingContextValue {
  forms: any[];
  selectedForm: any | null;
  setSelectedForm: (form: any) => void;
  handleNewForm: (organizationId: string) => void;
  assignForm: (formId: string, userId: string) => void;
  handleViewForm: (id: string) => void;
  handleNavigateBack: () => void;
  facilityMaps: Map[];
  organizations: Organization[];
  updateFilters: (filters: { [key: string]: string }) => void;
  users: User[];
  completeMigration: (formId: string) => void;
}

export const ManageOnboardingContext = createContext<
  ManageOnboardingContextValue | undefined
>(undefined);

export const ManageOnboardingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { showResponse } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilters = (filters: { [key: string]: string }) => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });

    setSearchParams(newSearchParams);
  };

  const [formDataArray, setFormDataArray] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const { id } = useParams();

  const fetchForms = useCallback(async () => {
    // Fetch forms from API
    const filterOrganization = searchParams.get("organization");
    const filterStatus = searchParams.get("status");
    const id = searchParams.get("assignedTo");
    const isMigrated = searchParams.get("isMigrated") || "false";

    // Fetch forms from API with filters
    const forms = await fetchOnboardingForms({
      filters: {
        ...(filterOrganization && { organizationId: filterOrganization }),
        ...(filterStatus && { status: filterStatus }),
        ...(id && { assignedTo: id }),
        ...(isMigrated && { isMigrated: isMigrated }),
      },
    });
    const { organizations, users } = await fetchOrganizations();

    setFormDataArray(forms);
    setOrganizations(organizations);
    setUsers(users);
  }, [searchParams]);

  const [selectedForm, setSelectedForm] = useState<any | null>(null);
  const fetchFormById = useCallback(
    async (id: string) => {
      const currentForm = await fetchOnboardingForm(id);
      setSelectedForm(currentForm);
    },
    [id]
  );

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  useEffect(() => {
    if (id) {
      fetchFormById(id);
    }
  }, [id]);

  const createNewForm = async (organizationId: string) => {
    try {
      await createOnboardingForm(organizationId);
      fetchForms();
      showResponse(
        "Form Created",
        "New form has been successfully created",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      console.error("Error creating new form: ", error);
      showResponse(
        "Error",
        "There was an error creating the form",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const assignForm = async (formId: string, userId: string) => {
    try {
      await assignOnboardingForm(formId, userId);
      showResponse(
        "Form Assigned",
        "Form has been successfully assigned",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
      fetchForms();
    } catch (error) {
      console.error("Error assigning form: ", error);
      showResponse(
        "Error",
        "There was an error assigning the form",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const completeMigration = async (formId: string) => {
    try {
      await completeFormMigration(formId);
      showResponse(
        "Form Migrated",
        "Form has been successfully migrated",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
      handleNavigateBack();
      fetchForms();
    } catch (error) {
      console.error("Error completing form: ", error);
      showResponse(
        "Error",
        "There was an error completing the form migration",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
    }
  };

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate("/support/onboarding");
  };

  const handleViewForm = (id: string) => {
    navigate(`/support/onboarding/${id}`);
  };

  const [facilityMaps, setFacilityMaps] = useState<Map[]>([]);

  useEffect(() => {
    const fetchFacilityMaps = async () => {
      try {
        const { maps } = await getFacilityMaps(
          selectedForm?.facility.migratedFacilityId
        );
        setFacilityMaps(maps);
      } catch (error) {
        console.error("Error fetching facility maps:", error);
      }
    };

    if (selectedForm && selectedForm?.facility.migratedFacilityId) {
      fetchFacilityMaps();
    }
  }, [selectedForm?.facility.migratedFacilityId, selectedForm?.maps]);

  return (
    <ManageOnboardingContext.Provider
      value={{
        forms: formDataArray,
        selectedForm,
        setSelectedForm,
        handleNewForm: createNewForm,
        assignForm,
        handleViewForm,
        handleNavigateBack,
        facilityMaps,
        organizations,
        updateFilters,
        users,
        completeMigration,
      }}
    >
      {children}
    </ManageOnboardingContext.Provider>
  );
};
