import apiClient from "../apiClient";

export const fetchTeam = async (facilityId: string, queryString: string) => {
  try {
    const response = await apiClient.get(
      `/user/list/${facilityId}?${queryString}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { users: [], total: 0 };
  }
};

export const fetchUser = async (userId: string) => {
  try {
    const response = await apiClient.get(`/user/get/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createUser = async (data: object) => {
  try {
    await apiClient.post("/user/new", data);
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserHistory = async (facilityId: string, userId: string) => {
  try {
    const response = await apiClient.get(
      `/user/tasks/history/${facilityId}/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = async (userId: string) => {
  try {
    await apiClient.delete(`/user/single/${userId}`, {
      params: {
        userToDelete: userId,
        deletedBy: userId,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
