import React from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Button from "../../updatedcomponents/general/Button";
import { calculateDays, formatDate } from "../../utils/FormatFunctions";
import { useNavigate } from "react-router-dom";
import { useManageOnboarding } from "../../hooks/useManageOnboarding";
import Modal from "../../updatedcomponents/general-ui/Modal";
import Input from "../../updatedcomponents/general-ui/Input";
import AssignmentSelector from "../../updatedcomponents/general-ui/AssignmentSelector";
const ManageOnboarding = () => {
  const {
    forms,
    handleNavigateBack,
    handleViewForm,
    handleNewForm,
    organizations,
    updateFilters,
    users,
    assignForm,
  } = useManageOnboarding();
  

  const [showModal, setShowModal] = React.useState(false);
  const [selectedOrganization, setSelectedOrganization] = React.useState(
    organizations[0]?._id
  );
  return (
    <div className="flex flex-col">
      <PageHeader
        title="Manage Onboarding"
        buttons={
          <Button children="Create Form" onClick={() => setShowModal(true)} />
        }
      />
      {/* Filter bar for organization  */}
      <div className="flex justify-between items-center p-4 border-b border-secondary-1000">
        <div className="flex gap-4">
          <div className="flex flex-col">
            <label htmlFor="organization">Organization</label>
            <select
              className="block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset ring-secondary-800 text-secondary-100 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
              name="organization"
              id="organization"
              onChange={(e) => updateFilters({ organization: e.target.value })}
            >
              <option value="">All</option>
              {organizations.map((organization) => (
                <option key={organization._id} value={organization._id}>
                  {organization.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="organization">Assigned To</label>
            <select
              className="block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset ring-secondary-800 text-secondary-100 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
              name="userId"
              id="userId"
              onChange={(e) => updateFilters({ assignedTo: e.target.value })}
            >
              <option value="">All</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.firstname} {user.lastname}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="organization">Migrated</label>
            <select
              className="block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset ring-secondary-800 text-secondary-100 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
              name="userId"
              id="userId"
              onChange={(e) => updateFilters({ isMigrated: e.target.value })}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
        </div>
      </div>
      {forms.map((form) => (
        <div
          key={form._id}
          className="flex justify-between forms-center p-4 border-b border-secondary-1000 hover:bg-secondary-1200 cursor-pointer"
          onClick={() => handleViewForm(form._id)}
        >
          <div className="flex flex-col">
            <span className="font-light text-sm">{form._id}</span>
            <span className="text-sm">{form?.organization?.name ?? "N/A"}</span>
            <span>{form.facility.name || "Not Yet Entered"}</span>
            <div className="flex flex-col">
              <select
                className="block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset ring-secondary-800 text-secondary-100 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
                name="assignedTo"
                id="assignedTo"
                defaultValue={form.assignedTo?._id}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => assignForm(form._id, e.target.value)}
              >
                <option value="">Unassigned</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.firstname} {user.lastname}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col text-sm text-secondary-500 text-end">
            <span className="font-medium">
              {form.progress.completedSections.length}/6
            </span>
            <span>
              <label className="font-light">Date Created: </label>
              {formatDate(form.createdAt, "YYYY-MM-DD")}
            </span>
            <span>
              <label className="font-light">Last Saved: </label>
              {form.progress.lastSavedAt
                ? `${calculateDays(form.progress.lastSavedAt)} days ago`
                : "Not started"}
            </span>
          </div>
        </div>
      ))}
      <Modal
        open={showModal}
        setOpen={setShowModal}
        content={
          <form className="flex flex-col gap-4">
            <label htmlFor="organization">Select Organization</label>
            <select
              value={selectedOrganization}
              onChange={(e) => setSelectedOrganization(e.target.value)}
              name="organization"
              id="organization"
            >
              {organizations.map((organization) => (
                <option key={organization._id} value={organization._id}>
                  {organization.name}
                </option>
              ))}
            </select>
            <Button
              children="Create Form"
              onClick={() => {
                handleNewForm(selectedOrganization);
                setShowModal(false);
              }}
            />
          </form>
        }
      />
    </div>
  );
};

export default ManageOnboarding;
