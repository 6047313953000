import React from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import StatusBadge from '../general-ui/StatusBadges';
import { formatDate, getFirstInitial } from '../../utils/FormatFunctions';
import { UserIcon } from '@heroicons/react/24/solid';
import { PaperClipIcon } from '@heroicons/react/24/outline';

//Frequency type due date source status title
const RecurringTaskCard = ({taskInstance}: {taskInstance: TaskInstance}) => {
  return (
    <div
      className="flex flex-col w-full bg-primary"
    //   onClick={() => handleView(workOrder._id)}
    >
      <div className="px-5 py-3 gap-2.5 flex w-full justify-between">
        <div
          className="flex gap-4 items-center"
          onClick={(e) => e.stopPropagation()}
        >
        
          <StatusBadge status={taskInstance.status} />
        </div>
        <div className="text-sm font-light">
          {formatDate(taskInstance.date)}
        </div>
      </div>
      <div className="px-5 pb-3 flex flex-col gap-2">
        <span className="text-sm font-medium">{taskInstance.title}</span>
        <span className="text-sm font-light w-3/4 truncate">
          {taskInstance.details}
        </span>
      </div>
      <div className="border-t border-secondary-1000 px-5 py-2 flex justify-between">
        <div>
          {taskInstance.assignedTo ? (
            <span className="text-sm font-light flex items-center gap-2">
              {taskInstance.assignedTo.avatar ? (
                <img
                  src={taskInstance.assignedTo.avatar}
                  alt=""
                  className="h-4 w-4 flex-shrink-0 rounded-full"
                />
              ) : (
                <UserIcon className="w-4 h-4 bg-secondary-900 text-primary p-0.5 rounded-xl" />
              )}{" "}
              {getFirstInitial(taskInstance.assignedTo.firstname)}.{" "}
              {taskInstance.assignedTo.lastname}
            </span>
          ) : (
            <span className="text-sm font-light flex items-center gap-2">
              <UserIcon className="w-4 h-4 bg-secondary-900 text-primary p-0.5 rounded-xl" />
              Unassigned
            </span>
          )}
        </div>
        <div className="flex items-center text-sm gap-5">
         
          <div className="flex items-center gap-2">
            {taskInstance?.parentTask?.attachments?.length ?? 0}
            <PaperClipIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecurringTaskCard