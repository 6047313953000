import { Fragment, useRef, useState } from "react";
import { Note } from "@mui/icons-material";
import { WorkOrder } from "../../types/WorkOrder";
import Input from "../general-ui/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../general/Button";
import { useWorkOrder } from "../../hooks/useWorkOrder";

interface IncompleteNotesProps {
  setShowNotes: React.Dispatch<React.SetStateAction<boolean>>;
}
interface UnableNotes {
  note: string;
}

const IncompleteNotes: React.FC<IncompleteNotesProps> = ({ setShowNotes }) => {
  const {handleMarkUnable} = useWorkOrder();
  const schema = yup.object().shape({
    note: yup
      .string()
      .required("Note is required")
      .min(10, "At least 10 characters are required")
      .max(150, "Maximum of 150 characters allowed"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UnableNotes>({
    resolver: yupResolver(schema),
    defaultValues: {
      note: ""
    },
  });
 
  return (
    <form onSubmit={handleSubmit(handleMarkUnable)}>
      <div>
        <p className="py-2 text-sm text-secondary-300 text-left">
          Please provide a brief description explaining why this task cannot
          be completed.
        </p>
        <Input
          name="note"
          label="Note"
          type="textarea"
          errors={errors.note}
          register={register}
        />
      </div>
      <div className="mt-6 flex flex-col sm:flex-row-reverse w-full sm:gap-4 gap-2">
        <Button styleString="danger" children="Mark Unable" type="submit" />
        <Button
          styleString="secondary"
          children="Cancel"
          type="button"
          onClick={() => setShowNotes(false)}
        />
      </div>
    </form>
  );
};

export default IncompleteNotes;
