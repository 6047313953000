import React, { useEffect, useState } from "react";
import { useRecurring } from "../../hooks/useRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  ArrowBack,
  AttachmentOutlined,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import { File as FileType } from "../../types/File";
import Modal from "../../updatedcomponents/general-ui/Modal";
import useMultiForm from "../../hooks/useMultiForm";
import TaskInstanceForm from "../../updatedcomponents/forms/TaskInstanceForm";
import Button from "../../updatedcomponents/general/Button";
import AssignmentSelector from "../../updatedcomponents/general-ui/AssignmentSelector";
import { useNavigate, useParams } from "react-router-dom";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../../updatedcomponents/general/Spinner";

const RecurringTaskInstance = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { section } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDirty, setIsDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [targetSectionIndex, setTargetSectionIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    instanceToEdit,
    navigateBack,
    closeOutValidation,
    canCloseOut,
    handleCloseOut,
    showState,
    updateShowState,
    users,
    handleTaskAssignment,
    handleViewFile,
    handleSkipTask,
    handleUnskipTask,
  } = useRecurring();

  const {
    currentIndex,
    goToSection,
    goToNextSection,
    goToPreviousSection,
    isFirstSection,
    isLastSection,
  } = useMultiForm(instanceToEdit?.parentTask?.map?.fields ?? []);

  useEffect(() => {
    if (instanceToEdit) {
      closeOutValidation();
    }
  }, [closeOutValidation, currentIndex, instanceToEdit]);

  useEffect(() => {
    if (section !== undefined) {
      const sectionIndex = parseInt(section);
      if (!isNaN(sectionIndex)) {
        goToSection(sectionIndex);
      }
    }
  }, [section, goToSection]);

  const handleSectionClick = (index: number) => {
    if (isMobile) {
      navigate(
        `/tasks/${instanceToEdit?.parentTask._id}_${instanceToEdit?.date}/section/${index}`
      );
    } else if (isDirty) {
      setTargetSectionIndex(index);
      setShowUnsavedModal(true);
    } else {
      goToSection(index);
    }
  };

  const handleModalClose = () => {
    if (isDirty) {
      setShowUnsavedModal(true);
    } else {
      goToSection(-1);
    }
  };

  const handleNavigateUnsaved = () => {
    if (targetSectionIndex !== null) {
      console.log("targetSectionIndex", targetSectionIndex);
      setShowUnsavedModal(false);
      setIsDirty(false);
      goToSection(targetSectionIndex);
      setTargetSectionIndex(null);
    } else {
      setShowUnsavedModal(false);
      setIsDirty(false);
      goToSection(-1);
    }
  };

  return (
    <div className="flex flex-col h-full bg-primary">
      <PageHeader
        title={
          <button
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={() => navigateBack()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} />
            {instanceToEdit ? instanceToEdit.parentTask.title : "Back to Tasks"}
          </button>
        }
        buttons={
          !currentUser.access.includes("worker") && (
            <div className="flex gap-2">
              {instanceToEdit?.parentTask.skippable &&
                instanceToEdit.status !== "skipped" && (
                  <Button
                    styleString="secondary"
                    onClick={() => updateShowState("showSkip", true)}
                  >
                    Skip
                  </Button>
                )}
              <Button
                children="Close Out"
                disabled={!canCloseOut}
                onClick={() => handleCloseOut()}
              />
            </div>
          )
        }
      />
      <div className="flex justify-between my-2 px-4 py-2 text-secondary-100 border-b border-secondary-1000">
        <span className="font-medium py-2">Details</span>
        <button
          className="px-12 py-2 font-medium text-sm bg-secondary-1100 rounded-sm"
          onClick={() => updateShowState("showSpecs", !showState.showSpecs)}
        >
          {showState.showSpecs ? "Hide" : "Show"} Details
        </button>
      </div>
      {showState.showSpecs && instanceToEdit && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2 text-sm m-4">
          <label className="text-secondary-100">Due Date:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {formatDate(instanceToEdit.date, "MMM DD, yyyy", "utc")}
          </span>

          <label className="text-secondary-100">Status:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            <StatusBadge status={instanceToEdit.status} />
          </span>

          <label className="text-secondary-100">Source:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {capitalizeString(instanceToEdit.parentTask?.source)}
          </span>

          <label className="text-secondary-100">Details:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3 sm:w-1/2">
            {instanceToEdit.parentTask?.details}
          </span>
          <label className="text-secondary-100">Assigned To:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3 sm:w-1/3">
            <AssignmentSelector
              users={users}
              selectedUser={
                instanceToEdit.assignedTo ? instanceToEdit.assignedTo : null
              }
              handleChange={(user) => {
                handleTaskAssignment(instanceToEdit, user);
              }}
            />
          </span>
          <label className="text-secondary-100">Attachments:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {instanceToEdit.parentTask?.attachments?.length ? (
              instanceToEdit.parentTask.attachments.map(
                (attachment: FileType) => (
                  <div key={attachment._id} className="flex gap-2">
                    <button
                      className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer my-1"
                      onClick={() =>
                        attachment.url && handleViewFile(attachment.url)
                      }
                    >
                      <AttachmentOutlined fontSize="small" /> {attachment.name}
                    </button>
                  </div>
                )
              )
            ) : (
              <p className="text-sm italic">No attachments for this task</p>
            )}
          </span>
        </div>
      )}
      {!instanceToEdit && <Spinner />}
      {instanceToEdit && instanceToEdit.status === "skipped" ? (
        <div className="flex  justify-center items-center m-4 bg-secondary-1200 py-4 rounded-md">
          <p className="text-sm italic">This task is skipped</p>
        </div>
      ) : (
        <div className="flex flex-col flex-grow overflow-y-auto">
          {instanceToEdit &&
            instanceToEdit.parentTask?.map?.fields.map((mapField, index) => (
              <div key={index} className="px-4 mt-2">
                <div
                  className="flex justify-between border rounded p-4 items-center hover:cursor-pointer hover:shadow-sm"
                  onClick={() => handleSectionClick(index)}
                >
                  <div className="flex flex-col">
                    <h3 className="text-sm">
                      {Object.keys(mapField)[1]}:{" "}
                      {String(mapField[Object.keys(mapField)[1]])}
                    </h3>
                    <h4 className="text-sm">
                      {Object.keys(mapField)[0]}:{" "}
                      {String(mapField[Object.keys(mapField)[0]])}
                    </h4>
                  </div>
                  <div>
                    {instanceToEdit.sectionStatus &&
                    instanceToEdit.sectionStatus[index] ? (
                      <StatusBadge
                        status={instanceToEdit.sectionStatus[index]}
                      />
                    ) : (
                      <StatusBadge status="not started" />
                    )}
                    <ChevronRightOutlined fontSize="medium" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {!isMobile && (
        <>
          <Modal
            open={currentIndex > -1}
            setOpen={handleModalClose}
            title={
              instanceToEdit ? instanceToEdit.parentTask.title : "Task Details"
            }
            subtitle={
              <div className="flex flex-col">
                <h2 className="font-normal">
                  {formatDate(instanceToEdit?.date, "MMM DD, yyyy", "utc")}
                </h2>
                {currentIndex > -1 && instanceToEdit
                  ? instanceToEdit.parentTask.map &&
                    Object.entries(
                      instanceToEdit.parentTask.map.fields[currentIndex]
                    ).map(([key, value]) => (
                      <span key={key}>{`${key}: ${String(value)}`}</span>
                    ))
                  : ""}
              </div>
            }
            content={
              <TaskInstanceForm
                key={currentIndex}
                currentIndex={currentIndex}
                goToSection={handleSectionClick}
                goToNextSection={goToNextSection}
                goToPreviousSection={goToPreviousSection}
                isFirstSection={isFirstSection}
                isLastSection={isLastSection}
                onDirtyStateChange={setIsDirty}
              />
            }
          />
          {showUnsavedModal && (
            <GenericModal
              title="Unsaved Changes"
              buttonText="Leave"
              handleFunction={handleNavigateUnsaved}
              item={instanceToEdit}
              showModal={showUnsavedModal}
              setShowModal={setShowUnsavedModal}
            />
          )}

          <GenericModal
            title="Skip this task"
            color="accent-500"
            secondaryColor="secondary-1000"
            item={instanceToEdit}
            handleFunction={() => instanceToEdit && handleSkipTask(instanceToEdit)}
            buttonText="Skip"
            showModal={showState.showSkip}
            setShowModal={() => updateShowState("showSkip", false)}
          />
        </>
      )}
    </div>
  );
};

export default RecurringTaskInstance;
