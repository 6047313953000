import { TaskInstance } from "../../types/TaskInstance";
import apiClient from "../apiClient";

export const fetchRecurringTasks = async (
  facilityId: string,
  searchTerm: string
) => {
  try {
    const response = await apiClient.get(
      `/recurring-tasks/list/${facilityId}`,
      {
        params: {
          searchTerm: searchTerm,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recurring tasks:", error);
  }
};

export const fetchTasks = async (facilityId: string, queryString: string) => {
  try {
    const response = await apiClient.get(
      `/recurring-tasks/task/list/${facilityId}?${queryString}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recurring tasks:", error);
  }
};

export const fetchTemplates = async () => {
  try {
    const response = await apiClient.get(`/recurring-tasks/templates/list`);
    return response.data;
  } catch (error) {
    console.error("Error fetching templates:", error);
    return [];
  }
};

export const fetchVendors = async (facilityId: string) => {
  try {
    const response = await apiClient.get(
      `/recurring-tasks/vendors/list/${facilityId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching vendors:", error);
    return [];
  }
};

export const fetchMaps = async (facilityId: string) => {
  try {
    const response = await apiClient.get(
      `/recurring-tasks/maps/list/${facilityId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching maps:", error);
    return [];
  }
};

export const fetchDocuments = async (facilityId: string) => {
  try {
    const response = await apiClient.get(
      `/recurring-tasks/docs/list/${facilityId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return [];
  }
};

export const deleteRecurringTask = async (taskId: string) => {
  try {
    const response = await apiClient.delete(
      `/recurring-tasks/delete/${taskId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error deleting recurring task");
  }
};

export const createRecurringTask = async (data: any) => {
  try {
    const response = await apiClient.post(`/recurring-tasks/create`, data);
    return response.data;
  } catch (error) {
    throw new Error("Error creating recurring task");
  }
};

export const updateRecurringTask = async (taskId: string, data: any) => {
  try {
    const response = await apiClient.post(
      `/recurring-tasks/update/${taskId}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating recurring task");
  }
};

export const fetchTaskInstance = async (parentId: string, date: string) => {
  try {
    const response = await apiClient.get(`/recurring-tasks/task-instance`, {
      params: {
        parentId: parentId,
        date: date,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching task instance:", error);
  }
};

export const updateTaskInstance = async (parentId: string, data: any) => {
  try {
    const response = await apiClient.post(
      `/recurring-tasks/update/instance/${parentId}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating task instance");
  }
};

export const closeOutTaskInstance = async (
  parentId: string,
  childId: string
) => {
  try {
    await apiClient.post("/recurring-tasks/close-out/task", {
      logId: parentId,
      entryId: childId,
    });
  } catch (error) {
    throw error;
  }
};

export const assignTask = async (
  task: TaskInstance,
  selectedUser: string | null,
  selectedFacility: string
) => {
  try {
    const response = await apiClient.put(`/recurring-tasks/assign`, {
      assignedTo:
        !selectedUser || selectedUser === "unassign" || selectedUser === ""
          ? null
          : selectedUser,
      taskInfo: task,
      facility: selectedFacility,
    });
    return response.data;
  } catch (error) {
    console.error("Error assigning task:", error);
  }
};

export const skipTask = async (recurringId: string, date: string) => {
  try {
    const response = await apiClient.post(
      `/recurring-tasks/skip/${recurringId}`,
      {
        date,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error skipping task:", error);
    throw error;
  }
};

export const unskipTask = async (recurringId: string, date: string) => {
  try {
    const response = await apiClient.post(
      `/recurring-tasks/unskip/${recurringId}`,
      {
        date,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error unskipping task:", error);
    throw error;
  }
};
