import apiClient from "../apiClient";

export const fetchOnboardingForms = async (options?: {
  filters?: {
    organizationId?: string;
    status?: string;
    [key: string]: string | undefined;
  };
}) => {
  const queryParams = new URLSearchParams();
  if (options?.filters) {
    Object.entries(options.filters).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value);
      }
    });
  }

  const queryString = queryParams.toString();
  const response = await apiClient.get(
    `/admin/onboarding-forms?${queryString}`
  );

  return response.data;
};

export const fetchOrganizations = async () => {
  try {
    // Fetch orgs and users
    const response = await apiClient.get("/admin/peripherals");
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding forms: ", error);
  }
};

export const fetchOnboardingForm = async (id: string) => {
  try {
    const response = await apiClient.get(`/admin/onboarding-form/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding form: ", error);
  }
};

export const createOnboardingForm = async (organizationId: string) => {
  try {
    console.log("Organization ID: ", organizationId);
    const response = await apiClient.post("/onboarding/init", {
      organizationId,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating onboarding form: ", error);
  }
};

export const assignOnboardingForm = async (formId: string, userId: string) => {
  try {
    const response = await apiClient.post(`/admin/onboarding-form/assign`, {
      userId,
      formId,
    });
    return response.data;
  } catch (error) {
    console.error("Error assigning onboarding form: ", error);
  }
};

export const completeFormMigration = async (formId: string) => {
  try {
    const response = await apiClient.post(
      `/admin/onboarding-form/complete/${formId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error completing form migration: ", error);
  }
};

export const fetchTemplates = async () => {
  try {
    const response = await apiClient.get("/admin/templates/list");
    return response.data;
  } catch (error) {
    console.error("Error fetching templates: ", error);
  }
};

export const fetchRegulations = async () => {
  try {
    const response = await apiClient.get("/admin/regulations/list");
    return response.data;
  } catch (error) {
    console.error("Error fetching regs: ", error);
  }
};

export const CreateNewTemplate = async (templateData: any) => {
  try {
    const response = await apiClient.post(
      "/admin/template/create",
      templateData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating template: ", error);
    throw error;
  }
};

export const createMigratedFacility = async (facilityData: {
  name: string;
  location: string;
  region?: string;
  formId?: string;
}) => {
  try {
    const response = await apiClient.post(
      "/admin/facility/create",
      facilityData
    );
    return response.data;
  } catch (error) {
    throw new Error("Error creating facility");
  }
};

export const updateMigrationProgress = async (
  formId: string,
  section: string
) => {
  try {
    const response = await apiClient.post(
      `/admin/onboarding/migration/${formId}`,
      {
        section,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating migration progress:", error);
    throw error;
  }
};

export const UpdateTemplate = async (templateId: string, templateData: any) => {
  try {
    const response = await apiClient.post(
      `/admin/template/update/${templateId}`,
      templateData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating template: ", error);
    throw error;
  }
};
export const createMigratedUser = async (userData: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  facility: []; // This will be the new facility ID
  formId: string; // Onboarding form ID
  originalUserId: string; // Original user ID
}) => {
  try {
    const response = await apiClient.post("/user/new", userData);

    // After successful map creation, fetch the updated form data
    const updatedFormResponse = await apiClient.get(
      `/admin/onboarding-form/${userData.formId}`
    );

    return {
      user: response.data,
      form: updatedFormResponse.data,
    };
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const createMigratedKiosk = async (kioskData: {
  name: string;
  location: string;
  pin: string;
  facility: string; // use migrated facility ID
  formId: string; // use onboarding form ID
  originalId: string; // use original kiosk ID
}) => {
  try {
    console.log("Kiosk data: ", kioskData);
    const response = await apiClient.post("/building/create/kiosk", kioskData);

    // After successful kiosk creation, fetch the updated form data
    const updatedFormResponse = await apiClient.get(
      `/admin/onboarding-form/${kioskData.formId}`
    );

    return {
      kiosk: response.data,
      form: updatedFormResponse.data,
    };
  } catch (error) {
    console.error("Error creating kiosk:", error);
    throw error;
  }
};

export const createMigratedMap = async (mapData: {
  name: string;
  fields: Array<any>;
  facility: string; // use migrated facility ID
  formId: string; // use onboarding form ID
  originalId: string; // use original map ID
}) => {
  try {
    const createdMap = await apiClient.post(
      `/building/create-map/${mapData.facility}`,
      {
        mapData: {
          collectionTitle: mapData.name,
          rows: mapData.fields,
        },
        formId: mapData.formId,
        originalId: mapData.originalId,
      }
    );

    // After successful map creation, fetch the updated form data
    const updatedFormResponse = await apiClient.get(
      `/admin/onboarding-form/${mapData.formId}`
    );

    return {
      map: createdMap.data,
      form: updatedFormResponse.data,
    };
  } catch (error) {
    console.error("Error creating map:", error);
    throw error;
  }
};

export const createMigratedTask = async (taskData: {
  title: string;
  taskType: string;
  category: string;
  source: string;
  recurrence: { frequency: string };
  map: string;
  inputs: Array<any>;
  details: string;
  skipWeekends: boolean;
  sourceTemplateId: string;
  startDate: string;
  facility: string;
  formId: string;
  originalId: string;
}) => {
  try {
    const response = await apiClient.post("/recurring-tasks/create", {
      ...taskData,
      facility: taskData.facility,
      notify: false, // default value
    });

    return response.data;
  } catch (error) {
    console.error("Error creating task:", error);
    throw error;
  }
};

export const getFacilityMaps = async (facilityId: string) => {
  try {
    const response = await apiClient.get(`/admin/maps/list/${facilityId}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching facility maps: ", error);
    throw error;
  }
};
