import React, { Fragment, useState } from "react";
import axios from "axios";
import { DeleteOutline, SearchOffOutlined } from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { RecurringTask } from "../../types/RecurringTask";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Table from "../general/Table";
import Spinner from "../general/Spinner";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";

type PageType = "life-safety" | "prev-maint" | "administrative";
interface RecurringTasksProps {
  recurringTasks: RecurringTask[];
  type: PageType;
}

const RecurringTasks: React.FC<RecurringTasksProps> = ({
  recurringTasks,
  type,
}) => {
  const { handleViewClick, isLoading } = useTaskScheduler();
  const { isMobile } = useDeviceWidth();
  const returnTitle = (type: PageType) => {
    switch (type) {
      case "life-safety":
        return "Life Safety";
      case "prev-maint":
        return "Preventative Maintenance";
      case "administrative":
        return "Administrative";
      default:
        return "Recurring Tasks";
    }
  };
  const columns = [
    {
      label: "Title",
      key: "title",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          <div className="sm:hidden bg-primary px-4 py-2 flex flex-col gap-1">
            <span>{capitalizeString(recurringTask.title)}</span>
            <div className="text-secondary-300 text-xs flex flex-col gap-1">
              <span>

                {capitalizeString(recurringTask.recurrence.frequency)}
              </span>
              <span>{capitalizeString(recurringTask.category)}</span>
              <span>{capitalizeString(recurringTask.source)}</span>
              {recurringTask.startDate && (
                <span>
                  {formatDate(recurringTask.startDate, "YYYY-MM-DD", "utc")}
                </span>
              )}
            </div>
          </div>
          <span className="hidden sm:block">
            {capitalizeString(recurringTask.title)}
          </span>
        </>
      ),
      className: "",
    },

    {
      label: "Type",
      key: "taskType",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{returnTitle(recurringTask.taskType as PageType)}</>
      ),

      className: "hidden sm:table-cell truncate",
    },
    {
      label: "Frequency",
      key: "frequency",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.recurrence.frequency)}</>
      ),
      className: "hidden sm:table-cell truncate",
    },
    {
      label: "Category",
      key: "category",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.category)}</>
      ),
      className: "hidden sm:table-cell truncate",
    },

    {
      label: "Start Date",
      key: "startDate",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          {recurringTask.startDate &&
            formatDate(recurringTask.startDate, "YYYY-MM-DD", "utc")}
        </>
      ),
      className: "hidden sm:table-cell truncate",
    },

    {
      label: "Source",
      key: "source",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.source)}</>
      ),
      className: "hidden sm:table-cell truncate",
    },
  ];

  return (
    <Table
      columns={columns}
      data={recurringTasks}
      onRowClick={(task) => handleViewClick(task)}
      paginationScheme="none"
      showHeader={isMobile ? false : true}
      noDataMessage={
        isLoading ? (
          <Spinner size="xl" />
        ) : (
          // Display an svg
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching tasks.
            </span>
          </div>
        )
      }
    />
  );
};

export default RecurringTasks;
