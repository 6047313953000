import React from 'react';
import { useEffect } from 'react';

type LoaderSize = 'small' | 'default' | 'large';

interface LoaderProps {
  /**
   * Size of the loader
   * @default 'default'
   */
  size?: LoaderSize;
  /**
   * Whether to display the loader fullscreen with overlay
   * @default false
   */
  fullScreen?: boolean;
  /**
   * Optional className for additional styling
   */
  className?: string;
  /**
   * Optional overlay background color class
   * @default 'bg-black bg-opacity-50'
   */
  overlayClass?: string;
}

const Loader: React.FC<LoaderProps> = ({ 
  size = 'default', 
  fullScreen = false,
  className = '',
  overlayClass = 'bg-black bg-opacity-50'
}) => {
  useEffect(() => {
    if (fullScreen) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      // Re-enable scrolling on unmount
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [fullScreen]);

  const sizeClasses: Record<LoaderSize, string> = {
    small: 'w-4 h-4',
    default: 'w-8 h-8',
    large: 'w-12 h-12'
  };

  const wrapperClasses = fullScreen
    ? `fixed inset-0 ${overlayClass} flex items-center justify-center z-50 pointer-events-auto cursor-wait`
    : 'flex items-center justify-center';

  return (
    <div 
      className={`${wrapperClasses} ${className}`}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.preventDefault()}>
      <div className={`${sizeClasses[size]} animate-spin`}>
        <div 
          className="h-full w-full border-4 border-t-accent-500 border-r-accent-300 border-b-accent-200 border-l-accent-100 rounded-full" 
          role="status"
          aria-label="Loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;