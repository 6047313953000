import apiClient from "../apiClient";

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}


export const fetchWorkOrderData = async (
  facilityId: string,
  dateParams: DateParams,
  filters: { [key: string]: string }[]
) => {
  try {

    const response = await apiClient.get(`/home/work-orders/${facilityId}`, {
      params: {
        startDate: dateParams.startDate,
        endDate: dateParams.endDate,
        dateQuery: dateParams.selectedRange,
        filters: JSON.stringify(filters),
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching work order data:", error);
  }
};

export const fetchRecurringTableData = async (
  facilityId: string,
  dateParams: DateParams,
  filters: { [key: string]: string }[]
) => {
  try {
    const response = await apiClient.get(
      `/home/recurring-table/${facilityId}`,
      {
        params: {
          startDate: dateParams.startDate,
          endDate: dateParams.endDate,
          dateQuery: dateParams.selectedRange,
          filters: JSON.stringify(filters),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recurring table data:", error);
  }
};