import React, { useState, useEffect, memo } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
  set,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Modal from "../../updatedcomponents/general-ui/Modal";
import Input, { InputType } from "../general-ui/Input";
import Button from "../general/Button";
import { LabelOutlined } from "@mui/icons-material";
import { decodedString } from "../../utils/FormatFunctions";
import Select from "react-select";
import { TaskTemplate } from "../../types/TaskTemplate";
import {
  UpdateTemplate,
  CreateNewTemplate,
} from "../../api/services/AdminService";
import { useToast } from "../../hooks/useToast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

interface CreateTemplateProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  existingLog: any;
  setExistingLog: React.Dispatch<any>;
  onTemplateCreate: (template: TaskTemplate) => void;
  onTemplateUpdate: (template: TaskTemplate) => void;
  regulationData?: { value: string; label: string }[];
}

// Template validation schema
const validationSchema = Yup.object().shape({
  taskType: Yup.string().required("Task type is required"),
  title: Yup.string().required("Title is required"),
  frequency: Yup.string().required("Frequency is required"),
  category: Yup.string().required("Category is required"),
  source: Yup.string().required("Source is required"),
  details: Yup.string()
    .required("Details are required")
    .min(10, "Details must be at least 10 characters"),
  inputs: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        dataType: Yup.string().required(),
        validator: Yup.object().shape({
          lowerLimit: Yup.number().nullable(),
          upperLimit: Yup.number().nullable(),
          required: Yup.boolean(),
        }),
      })
    )
    .min(1, "At least one input field is required"),
  suggestedForOnboarding: Yup.boolean().default(false),
  regulations: Yup.array().of(Yup.string()),
});

// Memoized form content component
const FormContent = memo(
  ({ onSubmit, existingLog, setShowPopup, regulationData }: any) => {
    const {
      register,
      control,
      formState: { errors },
      watch,
      setValue,
    } = useFormContext();
    const [inputItem, setInputItem] = useState("");
    const [selectedDataType, setSelectedDataType] = useState("text");
    const [validationCriteria, setValidationCriteria] = useState({
      lowerLimit: null,
      upperLimit: null,
    });

    const formInputs = [
      {
        id: "title",
        label: "Title",
        type: "text" as InputType,
      },
      {
        id: "frequency",
        label: "Frequency",
        type: "select" as InputType,
        options: [
          { value: "daily", label: "Daily" },
          { value: "weekly", label: "Weekly" },
          { value: "bi-weekly", label: "Biweekly" },
          { value: "monthly", label: "Monthly" },
          { value: "quarterly", label: "Quarterly" },
          { value: "semi-annually", label: "Semi-Annually" },
          { value: "annually", label: "Annually" },
          { value: "biennially", label: "2-Year" },
          { value: "3-year", label: "3-Year" },
          { value: "4-year", label: "4-Year" },
          { value: "5-year", label: "5-Year" },
        ],
      },
      {
        id: "suggestedForOnboarding",
        label: "Suggested For Onboarding",
        type: "switch" as InputType,
      },
      {
        id: "category",
        label: "Category",
        type: "select" as InputType,
        options: [
          { value: "fire safety", label: "Fire Safety" },
          { value: "electrical safety", label: "Electrical Safety" },
          { value: "operational safety", label: "Operational Safety" },
          { value: "gas safety", label: "Gas/Air Safety" },
        ],
      },
      {
        id: "taskType",
        label: "Task Type",
        type: "select" as InputType,
        options: [
          { value: "life-safety", label: "Life Safety" },
          { value: "prev-maint", label: "Preventative Maintenance" },
        ],
      },
      {
        id: "source",
        label: "Source",
        type: "select" as InputType,
        options: [
          { value: "internal", label: "Internal" },
          { value: "external", label: "External" },
        ],
      },
      {
        id: "details",
        label: "Details",
        type: "textarea" as InputType,
      },
    ];

    const handleAddInput = () => {
      if (inputItem.trim()) {
        const isRequired = watch("newInput.validator.required");
        const newInput = {
          name: encodeURIComponent(inputItem),
          dataType: selectedDataType,
          validator:
            selectedDataType === "number"
              ? {
                  lowerLimit: Number(validationCriteria.lowerLimit) || null,
                  upperLimit: Number(validationCriteria.upperLimit) || null,
                  required: isRequired,
                }
              : {
                  required: isRequired,
                },
        };

        const currentInputs = watch("inputs") || [];
        setValue("inputs", [...currentInputs, newInput]);

        setInputItem("");
        setSelectedDataType("text");
        setValidationCriteria({ lowerLimit: null, upperLimit: null });
        setValue("newInput.validator.required", false);
      }
    };

    const handleRemoveInput = (index: number) => {
      const currentInputs = watch("inputs") || [];
      setValue(
        "inputs",
        currentInputs.filter((_: any, i: number) => i !== index)
      );
    };
    useEffect(() => {
      console.log("errors", errors);
    }, [errors]);

    return (
      <form onSubmit={onSubmit} className="space-y-6">
        {formInputs.map((input) => (
          <div key={input.id} className="space-y-2">
            <Input
              name={input.id}
              label={input.label}
              type={input.type}
              options={input.options}
              control={control}
              register={register}
              errors={errors[input.id]}
            />
          </div>
        ))}

        {/* Regulation */}
        <div className="grid grid-cols-3">
          <label className="block text-sm font-medium leading-6 mb-2">
            Regulations
          </label>
          <Controller
            name="regulations"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                options={regulationData}
                className="basic-multi-select col-span-2"
                classNamePrefix="select"
                placeholder="Select files (optional)"
                isClearable={false}
                onChange={(selected) => {
                  field.onChange(selected.map((item) => item.value));
                }}
                value={regulationData?.filter(
                  (option: { label: string; value: string }) =>
                    (field.value ?? []).includes(option.value)
                )}
                styles={{
                  input: (base) => ({
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                    primary25: "lightgray",
                  },
                })}
              />
            )}
          />
        </div>

        {/* Input Fields */}
        <div className="space-y-2">
          <label className="block text-sm font-medium">Input Fields</label>
          <div className="space-y-2 bg-gray-50 p-4 rounded-lg">
            {/* Existing Inputs */}
            <div className="bg-white rounded-lg p-2">
              {watch("inputs")?.map((input: any, index: number) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-1"
                >
                  <div className="flex items-center gap-2">
                    <div className="w-5 h-5 flex items-center">
                      <LabelOutlined className="text-gray-400 w-4 h-4" />
                    </div>
                    <span className="text-gray-700">
                      {decodedString(input.name)}
                    </span>
                    <span className="bg-gray-100 px-2 py-0.5 rounded text-xs text-gray-600">
                      {input.dataType}
                    </span>
                    {input.validator?.required && (
                      <span className="bg-red-100 px-2 py-0.5 rounded text-xs text-gray-600">
                        {"Required"}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveInput(index)}
                    className="text-red-500 hover:text-red-600 text-sm"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>

            {/* Add New Input */}
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={inputItem}
                onChange={(e) => setInputItem(e.target.value)}
                placeholder="Input Name"
                className="flex-1 rounded-lg border-0 p-2 bg-white text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-800 focus:ring-2 focus:ring-teal-500 active:ring-teal-500 placeholder:text-gray-400 active:bg-teal-50/10"
              />
              <select
                value={selectedDataType}
                onChange={(e) => setSelectedDataType(e.target.value)}
                className="rounded-lg border-0 p-2 bg-white text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-800 focus:ring-2 focus:ring-teal-500 active:ring-teal-500 active:bg-teal-50/10"
              >
                <option value="">Select an option</option>
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="radio">Pass/Fail</option>
                <option value="radio-na">Pass/Fail/NA</option>
                <option value="file">File</option>
              </select>
              <button
                type="button"
                onClick={handleAddInput}
                className="bg-teal-500 hover:bg-teal-600 text-white rounded-lg p-2 w-10 h-10 flex items-center justify-center"
              >
                <span className="text-xl">+</span>
              </button>
            </div>

            {/* Validators */}
            <div className="col-span-full bg-primary p-2">
              <span className="flex py-2 w-full border-b border-secondary-1000">
                Validators
              </span>
              <div className="grid grid-cols-2 gap-2 py-2">
                <label className="text-sm font-medium">Required field</label>
                <Input
                  name="newInput.validator.required"
                  label="Required"
                  type="switch"
                  showLabel={false}
                  control={control}
                  errors={
                    errors["newInput.validator.required" as keyof typeof errors]
                  }
                />
              </div>
              {selectedDataType === "number" && (
                <div className="grid grid-cols-2 gap-2">
                  <Input
                    name="newInput.validator.lowerLimit"
                    label="Lower Limit"
                    placeholder="Lower Limit"
                    type="number"
                    control={control}
                    errors={
                      errors[
                        "newInput.validator.lowerLimit" as keyof typeof errors
                      ]
                    }
                  />
                  <Input
                    name="newInput.validator.upperLimit"
                    label="Upper Limit"
                    placeholder="Upper Limit"
                    type="number"
                    control={control}
                    errors={
                      errors[
                        "newInput.validator.upperLimit" as keyof typeof errors
                      ]
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <Button
            type="button"
            onClick={() => setShowPopup(false)}
            styleString="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" styleString="primary">
            {existingLog ? "Save Changes" : "Create Template"}
          </Button>
        </div>
      </form>
    );
  }
);

const CreateTemplate: React.FC<CreateTemplateProps> = ({
  showPopup,
  setShowPopup,
  existingLog,
  setExistingLog,
  onTemplateCreate,
  onTemplateUpdate,
  regulationData,
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      taskType: "",
      title: "",
      frequency: "",
      category: "",
      source: "",
      details: "",
      inputs: [],
      suggestedForOnboarding: false,
      regulations: [],
    },
  });

  const { showResponse } = useToast();

  useEffect(() => {
    if (existingLog) {
      methods.reset({
        taskType: existingLog.taskType || "",
        title: existingLog.title || "",
        frequency: existingLog.recurrence?.frequency || "",
        category: existingLog.category || "",
        source: existingLog.source || "",
        details: existingLog.details || "",
        inputs: existingLog.inputs || [],
        suggestedForOnboarding: existingLog.suggestedForOnboarding || false,
        regulations: existingLog.regulations || [],
      });
    }
  }, [existingLog, methods.reset]);

  const onSubmit = async (data: any) => {
    try {
      if (existingLog) {
        const response = await UpdateTemplate(existingLog._id, data);
        onTemplateUpdate(response);
        showResponse(
          "Success",
          "Template updated successfully",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      } else {
        const response = await CreateNewTemplate(data);
        onTemplateCreate(response);
        showResponse(
          "Success",
          "Template created successfully",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
      setShowPopup(false);
    } catch (error) {
      showResponse(
        "Error",
        "Error saving template. Please try again later.",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error saving template:", error);
    }
  };

  return (
    <Modal
      open={showPopup}
      setOpen={setShowPopup}
      title={existingLog ? "Edit Template" : "Create New Template"}
      subtitle="Configure the template details below"
      content={
        <FormProvider {...methods}>
          <FormContent
            onSubmit={methods.handleSubmit(onSubmit)}
            existingLog={existingLog}
            setShowPopup={setShowPopup}
            regulationData={regulationData}
          />
        </FormProvider>
      }
    />
  );
};

export default CreateTemplate;
