import React, { useState, Fragment, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close, Error, Warning } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { User } from "../../types/User";
import { Facility } from "../../types/Facility";
import { useFacility } from "../../hooks/useFacility";
import { useTeam } from "../../hooks/useTeam";
import Button from "../general/Button";

type InputType = {
  id: string;
  label: string;
  type: string;
  placeholder: string;
};

const inputs: InputType[] = [
  {
    id: "firstname",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
  },
  {
    id: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "email@example.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "tel",
    placeholder: "(123) 456-7890",
  },
];

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  isVerified?: boolean;
  joinDate?: Date;
  facility: string;
}

const NewUserForm: React.FC = () => {
  const { currentUser } = useAuth();
  const { selectedFacility } = useFacility();
  const { updateModalShowState, createNewUser } = useTeam();

  const validationSchema = yup.object().shape({
    firstname: yup.string().required("*Required field"),
    lastname: yup.string().required("*Required field"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("*Required field"),
    phone: yup.string().required("*Required field"),
    access: yup.string().required("*Required field"),
    isVerified: yup.boolean(),
    joinDate: yup.date(),
    facility: yup.string().required("*Required field"),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      facility: selectedFacility,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const data = getValues();

  return (
    <div>
      <form
        onSubmit={handleSubmit(createNewUser)}
        className="flex h-full flex-col overflow-y-scroll hide-scrollbar-arrows"
        noValidate
      >
        <div className="grid grid-cols-2 gap-4">
          {inputs.map((input) => (
            <div key={input.id}>
              <label
                htmlFor={input.id}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {input.label}
              </label>
              <input
                type={input.type}
                placeholder={input.placeholder}
                className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
                {...register(input.id as keyof FormValues)}
              />
              {errors && errors[input.id as keyof FormValues] && (
                <p className="text-reds-500 text-xs">
                  {errors[input.id as keyof FormValues]?.message}
                </p>
              )}
            </div>
          ))}
        </div>
        {(currentUser.access === "regional" ||
          currentUser.access === "organization-admin" ||
          currentUser.access === "system-admin") && (
          <div key={"access"}>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Access
            </label>

            <div className="sm:col-span-2">
              <select
                id={"access"}
                className={`block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-300 sm:text-sm sm:leading-6 `}
                {...register("access")}
              >
                <option value={"worker"}>Worker</option>
                <option value={"regional"}>Regional</option>
                <option value={"director"}>Director</option>
                {currentUser.access === "system-admin" && (
                  <option value={"administrator"}>Administrator</option>
                )}
              </select>
            </div>
          </div>
        )}

        {/* {currentUser.access === "system-admin" &&
          (data.access === "regional" ||
            data.access === "organization-admin") && (
            <div
              className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
            >
              <label
                htmlFor="project-name"
                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
              >
                Facilities
              </label>
              <div className="sm:col-span-2">
                {facilities.map((facility: Facility) => (
                  <div
                    key={facility._id}
                    className="relative flex items-start py-1"
                  >
                    <div className="min-w-0 flex-1 text-sm leading-6">
                      <label
                        htmlFor={`person-${facility._id}`}
                        className="select-none text-gray-900"
                      >
                        {facility.name}
                      </label>
                    </div>
                    <div className="ml-3 flex h-6 items-center">
                      <input
                        id="facility"
                        value={facility._id}
                        checked={data.facility?.includes(facility._id) ?? false}
                        {...register("facility")}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-secondary-300 focus:ring-secondary-300"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )} */}
        <div className="flex w-full gap-2 justify-between border-t border-secondary-1000 mt-4">
          <Button
            type="button"
            styleString="secondary"
            onClick={() => updateModalShowState("showNewUser", false)}
            children="Cancel"
            overrideStyles="w-full"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Submit"
            disabled={isSubmitting}
            overrideStyles="w-full"
          />
        </div>
      </form>
    </div>
  );
};

export default NewUserForm;
