import { useState, useEffect } from 'react';

// Define breakpoints based on the codebase's media queries
const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200,
  extraLargeDesktop: 1440,
  ultraWideDesktop: 1792,
} as const;

type Breakpoint = keyof typeof breakpoints;

interface DeviceWidth {
  width: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isExtraLargeDesktop: boolean;
  isUltraWideDesktop: boolean;
  isGreaterThan: (breakpoint: Breakpoint) => boolean;
  isLessThan: (breakpoint: Breakpoint) => boolean;
  isBetween: (min: Breakpoint, max: Breakpoint) => boolean;
}

export const useDeviceWidth = (): DeviceWidth => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isGreaterThan = (breakpoint: Breakpoint): boolean => {
    return width >= breakpoints[breakpoint];
  };

  const isLessThan = (breakpoint: Breakpoint): boolean => {
    return width < breakpoints[breakpoint];
  };

  const isBetween = (min: Breakpoint, max: Breakpoint): boolean => {
    return width >= breakpoints[min] && width < breakpoints[max];
  };

  return {
    width,
    isMobile: width < breakpoints.tablet,
    isTablet: width >= breakpoints.tablet && width < breakpoints.desktop,
    isDesktop: width >= breakpoints.desktop && width < breakpoints.largeDesktop,
    isLargeDesktop: width >= breakpoints.largeDesktop && width < breakpoints.extraLargeDesktop,
    isExtraLargeDesktop: width >= breakpoints.extraLargeDesktop && width < breakpoints.ultraWideDesktop,
    isUltraWideDesktop: width >= breakpoints.ultraWideDesktop,
    isGreaterThan,
    isLessThan,
    isBetween,
  };
}; 