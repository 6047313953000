import { useState, FC } from "react";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import { useRecurring } from "../../hooks/useRecurring";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import { useSearchParams } from "react-router-dom";
import { updateSearchParams } from "../../utils/ParamUtils";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import Selector from "../../updatedcomponents/general-ui/Selector";
import Button from "../../updatedcomponents/general/Button";

interface FilterOption {
  label: string;
  type: string;
  name: string;
  options?: { label: string; value: string }[];
}

interface Response {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}

const Tasks: FC = () => {
  const {
    recurringTasks,
    users,
    showState: { showFilter },
    updateShowState,
    handleResetFilters,
    applyFilters,
    applyIndividualFilter,
    handleLogInstanceView,
    filters,
    view,
  } = useRecurring();

  const [searchParams, setSearchParams] = useSearchParams();

  const filterOptions: FilterOption[] = [
    {
      label: "Start Date",
      type: "date",
      name: "dueDateFrom",
    },
    {
      label: "End Date",
      type: "date",
      name: "dueDateTo",
    },
    {
      label: "Task Type",
      type: "selector",
      options: [
        { label: "All Types", value: "" },
        { label: "Preventative Maintenance", value: "prev-maint" },
        { label: "Life Safety", value: "life-safety" },
      ],
      name: "taskType",
    },
    {
      label: "Status",
      type: "selector",
      options: [
        { label: "All Statuses", value: "" },
        { label: "Incomplete", value: "incomplete" },
        { label: "In Progress", value: "in progress" },
        { label: "Overdue", value: "overdue" },
        { label: "Complete", value: "complete" },
        { label: "Failed", value: "failed" },
      ],
      name: "status",
    },
    {
      label: "Task Frequency",
      type: "selector",
      options: [
        { label: "All Frequencies", value: "" },
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-weekly", value: "bi-weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Semi-annually", value: "semi-annually" },
        { label: "Annually", value: "annually" },
        { label: "2 Year", value: "biennially" },
        { label: "3 Year", value: "3-year" },
        { label: "4 Year", value: "4-year" },
        { label: "5 Year", value: "5-year" },
      ],
      name: "frequency",
    },
    {
      label: "Source",
      type: "selector",
      options: [
        { label: "All Sources", value: "" },
        { label: "Internal", value: "internal" },
        { label: "External", value: "external" },
      ],
      name: "source",
    },
    {
      label: "Assigned To",
      type: "selector",
      options: [
        { label: "All Users", value: "" },
        ...users.map((user) => ({
          label: user.firstname + " " + user.lastname,
          value: user._id,
        })),
      ],
      name: "assignedTo",
    },
    {
      label: "Ready to Complete",
      type: "checkbox",
      name: "readyToComplete",
    },
  ];

  const [isActiveSearch, setIsActiveSearch] = useState(false);

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={"Recurring Tasks"}
        subtitle={"All tasks waiting to be closed out"}
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={filters.title || ""}
              handleSearchChange={(searchTerm) =>
                applyIndividualFilter("title", searchTerm)
              }
            />
          </div>
        }
      />
      <div className="flex lg:hidden w-full justify-between items-center bg-primary shadow border-b-2 border-secondary-1000 py-2.5 px-4 text-secondary-400 gap-2">
        <div
          className={`flex gap-4 text-sm ${
            isActiveSearch ? "hidden" : "w-fit"
          }`}
        >
          <button
            onClick={() => updateShowState("showFilter", true)}
            className="py-2 px-3 border border-secondary-1000 rounded-lg flex items-center gap-2"
          >
            <AdjustmentsHorizontalIcon className="h-4 w-4" />
            <span> Filter</span>
          </button>
        </div>

        <div
          className={`flex items-center ${
            isActiveSearch ? "w-full" : "w-fit"
          } px-3 border border-secondary-1000 rounded-lg justify-end h-full`}
        >
          <div
            className={`${
              isActiveSearch
                ? "opacity-100 w-full flex justify-between items-center"
                : "opacity-0 w-0 overflow-hidden"
            } flex transition-all duration-200 ease-in-out`}
          >
            <input
              type="text"
              className={`flex flex-row-reverse py-1.5 px-2 border-none focus:outline-none focus:ring-transparent  transition-opacity duration-500 ease-in-out
                ${
                  isActiveSearch
                    ? "opacity-100 w-full items-center"
                    : "opacity-0 w-0"
                }
                `}
              value={filters.title || ""}
              onChange={(e) => applyIndividualFilter("title", e.target.value)}
              placeholder="Search..."
            />
            <button
              className={`transition-opacity duration-200 ease-in-out ${
                isActiveSearch
                  ? "opacity-100 w-fit items-center"
                  : "opacity-0 w-0"
              }`}
              onClick={() => {
                setIsActiveSearch(false);
                applyIndividualFilter("q", "");
              }}
            >
              <span className="sr-only">Clear search</span>
              <XMarkIcon className="h-5 w-5 text-secondary-400" />
            </button>
          </div>

          <button
            onClick={() => setIsActiveSearch(!isActiveSearch)}
            className={` ${isActiveSearch ? "opacity-0 w-0" : "w-5"}`}
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="hidden lg:flex border-b border-secondary-1000 w-full justify-between items-center px-6 py-3">
        <div className="flex gap-2 ">
          {filterOptions
            .filter((option) => option.type === "selector")
            .map((filterOption) => (
              <Selector
                className="flex-1 flex "
                key={filterOption.name}
                placeholder={filterOption.label}
                options={filterOption.options || []}
                value={
                  filters[filterOption.name as keyof typeof filters] !==
                  undefined
                    ? filterOption.options?.find(
                        (option) =>
                          option.value ===
                          filters[filterOption.name as keyof typeof filters]
                      )
                    : filterOption.options?.[0] // Provide a default value if undefined
                }
                onChange={(selectedOption) =>
                  applyIndividualFilter(
                    filterOption.name,
                    String(selectedOption.value)
                  )
                }
              />
            ))}
          <div className="flex items-center gap-2 border border-secondary-1000 rounded-lg p-2">
            <input
              type="checkbox"
              id="readyToComplete"
              checked={JSON.parse(filters.readyToComplete ?? "true")}
              onChange={(e) => {
                applyIndividualFilter(
                  "readyToComplete",
                  e.target.checked.toString()
                );
              }}

              className="h-4 w-4 rounded border-secondary-1000 text-secondary-100 focus:ring-secondary-100 focus:ring-offset-0"
            />
            <label
              htmlFor="readyToComplete"
              className="text-sm text-secondary-400"
            >
              Ready to Complete
            </label>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Button
            styleString="secondary"
            children="Reset Filters"
            icon="Refresh"
            onClick={handleResetFilters}
          />
        </div>
      </div>
      {view === "list" ? (
        <div className="bg-primary">
          {/* <div className="sm:m-2">
            <FilterButton
              setShowFilterModal={() => updateShowState("showFilter", true)}
            />
          </div> */}

          <FilterModal
            filters={JSON.stringify(filters)}
            filterOptions={filterOptions}
            show={showFilter}
            setShow={() => updateShowState("showFilter", false)}
            applyFilters={applyFilters}
            resetFilters={handleResetFilters}
          />
          {/* {viewButtons()} */}
        </div>
      ) : (
        <></>
        /* <ToDoCalendar
            data={recurringTasks}
            dateParams={dateParams}
            setDateParams={setDateParams}
            /> */
      )}
      <ToDoTable
        data={recurringTasks}
        users={users}
        handleLogInstanceView={handleLogInstanceView}
      />
    </div>
  );
};

export default Tasks;
