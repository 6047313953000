import React from "react";
import axios from "axios";
import {
  AttachmentOutlined,
  CalendarMonthOutlined,
  Person,
} from "@mui/icons-material";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString, formatDate } from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { WorkOrder } from "../../types/WorkOrder";
import { User } from "../../types/User";
import { useAuth } from "../../hooks/useAuth";
import { SetURLSearchParams } from "react-router-dom";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import WorkOrderCard from "../cards/WorkOrderCard";
import Spinner from "../general/Spinner";

interface WorkOrderTableProps {}

const WorkOrdersTable = ({}: WorkOrderTableProps) => {
  const {
    workOrders,
    isLoading,
    handleView,
    users,
    handleSort,
    sortColumn,
    sortOrder,
    handleAssignment,
    pageSize,
    page,
    handlePageChange,
    handlePageSizeChange,
    totalPages,
    handleRefresh,
    selectedWorkOrders,
    setSelectedWorkOrders,
  } = useWorkOrder();

  const { currentUser } = useAuth();

  const columns = [
    {
      label: "",
      key: "checked",
      sortable: false,
      render: (workOrder: WorkOrder) => (
        <div className="cursor-default py-2 flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            className="cursor-pointer h-4 w-4 rounded border-secondary-300 text-accent focus:ring-accent"
            onChange={() => {
              setSelectedWorkOrders((prev) =>
                prev.some((w_id) => w_id === workOrder._id)
                  ? prev.filter((w_id) => w_id !== workOrder._id)
                  : [...prev, workOrder._id]
              );
            }}
            checked={selectedWorkOrders.includes(workOrder._id)}
          />
        </div>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "ID",
      key: "shortId",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className="hidden sm:inline-flex">{workOrder.shortId}</span>
          <div className="flex w-full sm:hidden" key={workOrder._id}>
            <WorkOrderCard
              workOrder={workOrder}
              handleView={handleView}
              selectedWorkOrders={selectedWorkOrders}
              setSelectedWorkOrders={setSelectedWorkOrders}
              hideCheckbox={false}
            />
          </div>
        </>
      ),
      className: "truncate ",
    },
    {
      label: "Request Title",
      key: "title",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <span>{capitalizeString(workOrder.title)}</span>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Description",
      key: "details",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>{capitalizeString(workOrder.details)}</>
      ),
      className: "truncate hidden sm:table-cell",
    },
    {
      label: "Location",
      key: "location",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span
            className="hidden sm:inline-flex"
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeString(workOrder.location)}
          </span>
        </>
      ),
      className: "hidden xl:table-cell truncate",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>{formatDate(workOrder.dateReported, "MM-DD-YYYY")}</>
      ),
      className: "truncate hidden sm:table-cell",
    },

    {
      label: "Status",
      key: "status",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.status} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <AssignmentSelector
              users={users}
              selectedUser={workOrder.assignedTo ? workOrder.assignedTo : null}
              handleChange={(user) => handleAssignment(workOrder, user)}
            />
          </div>
        </>
      ),
      className: ` ${
        currentUser.access !== "worker" ? "hidden sm:table-cell" : "hidden"
      }`,
    },
  ];

  return (
    <Table
      data={workOrders}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortOrder}
      showHeader={window.innerWidth > 640 ? true : false}
      onRowClick={(workOrder) => handleView(workOrder._id)}
      columns={columns}
      stickyFirstColumn
      noDataMessage={
        isLoading ? (
          <Spinner size="xl" />
        ) : (
          // Display an svg
          <div className="flex flex-col gap-4 items-center justify-center">
            <img src="/no-data.svg" className="w-1/2" />
            <span className="text-secondary-300 text-sm sm:text-md">
              No matching orders.
            </span>
          </div> 
        )
      }
      paginationScheme="infiniteScroll"
      pageSize={pageSize}
      page={page}
      totalPages={totalPages}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      loading={isLoading}
      handleRefresh={() => handleRefresh()}
    />
  );
};

export default WorkOrdersTable;
