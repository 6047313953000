import { Add } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import CreateTemplate from "../../updatedcomponents/forms/CreateTemplate";
import axios from "axios";
import TemplateList from "../../updatedcomponents/tables/TemplateList";
import { TaskTemplate } from "../../types/TaskTemplate";
import {
  fetchRegulations,
  fetchTemplates,
} from "../../api/services/AdminService";
import Modal from "../../updatedcomponents/general-ui/Modal";

const ScheduledTasks = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [data, setData] = useState<TaskTemplate[]>([]);

  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplate | null>(
    null
  );
  const [regulationData, setRegulationData] = useState<
    { _id: string; label: string; value: string }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      const templates = await fetchTemplates();
      const regulations = await fetchRegulations();
      setData(templates);
      setRegulationData(regulations);
    }
    fetchData();
  }, []);

  const handleEditTemplate = (template: TaskTemplate) => {
    setSelectedTemplate(template);
    setShowPopup(true);
  };

  const handleAddTemplate = () => {
    setSelectedTemplate(null);
    setShowPopup(true);
  };

  const handleTemplateUpdate = (updatedTemplate: TaskTemplate) => {
    setData((currentTemplates) =>
      currentTemplates.map((template) =>
        template._id === updatedTemplate._id ? updatedTemplate : template
      )
    );
  };

  const handleTemplateCreate = (newTemplate: TaskTemplate) => {
    setData((currentTemplates) => [...currentTemplates, newTemplate]);
  };

  return (
    <div>
      <div className="fixed bottom-8 right-8 z-10">
        <button
          type="button"
          className="rounded-full bg-accent-500 p-2 text-primary shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
          onClick={handleAddTemplate}
        >
          <Add className="h-10 w-10" aria-hidden="true" />
        </button>
      </div>
      <TemplateList templates={data} handleEditTemplate={handleEditTemplate} />

      <CreateTemplate
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        existingLog={selectedTemplate}
        setExistingLog={setSelectedTemplate}
        onTemplateCreate={handleTemplateCreate}
        onTemplateUpdate={handleTemplateUpdate}
        regulationData={regulationData}
      />
    </div>
  );
};

export default ScheduledTasks;
