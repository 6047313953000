import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { FC } from "react";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}

interface PaginationProps {
  itemsToShow: string;
  currentPage: string | number;
  totalPages: number;
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  setCurrentPage: (currentPage: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  itemsToShow,
  currentPage,
  totalPages,
  handlePageSizeChange,
  setCurrentPage,
}) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const parsedCurrentPage = typeof currentPage === 'string' ? parseInt(currentPage) : currentPage;

  const handleItemsToShowChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsToShow = e.target.value;
    handlePageSizeChange({ pageSize: newItemsToShow, page: 1 });
  };
  
  return (
    <div className="fixed w-full h-14 bottom-0 bg-primary">
      <nav className=" flex items-center justify-between border-t border-secondary-1000  px-0 h-full">
        {/* Items per page selector */}
        <div className="ml-4">
          <select
            className="w-fit border text-xs border-secondary-1000 rounded text-secondary-100  outline-none focus:border-transparent focus:ring-accent-500"
            value={itemsToShow}
            onChange={handleItemsToShowChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          <span className="text-sm text-secondary-100 ml-2">
            Items
          </span>
        </div>

        <div className="flex">
          {parsedCurrentPage > 1 && (
            <button
              className="inline-flex items-center text-sm font-medium text-secondary-100 hover:border-secondary-1000 hover:text-secondary-200"
              onClick={() => setCurrentPage(parsedCurrentPage - 1)}
              disabled={parsedCurrentPage == 1}
            >
              <ArrowBackIos
                className="mr-3"
                style={{ fontSize: "0.9rem" }}
                aria-hidden="true"
              />
            </button>
          )}
          {pages.map((pageNumber) => {
            if (
              pageNumber === 1 ||
              pageNumber === totalPages ||
              (pageNumber >= parsedCurrentPage - 1 &&
                pageNumber <= parsedCurrentPage + 1)
            ) {
              return (
                <button
                  key={pageNumber}
                  className={
                    pageNumber !== parsedCurrentPage
                      ? "inline-flex items-center border-t-2 border-transparent px-4 py-2 text-sm font-medium text-secondary-100 hover:border-secondary-300 hover:text-secondary-300"
                      : "inline-flex items-center px-4  text-sm font-medium text-primary  bg-secondary-100 rounded-md"
                  }
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            } else if (
              pageNumber === parsedCurrentPage - 2 ||
              pageNumber === parsedCurrentPage + 2
            ) {
              // Render ellipsis for the second page, second to last page, and pages that are +-2 from the current page
              return (
                <div className="inline-flex items-center text-sm font-medium text-secondary-100">

              <EllipsisHorizontalIcon className="h-5 w-5" />
              </div>
              );
            }
          })}
          {parsedCurrentPage < totalPages && (
            <button
              disabled={parsedCurrentPage == totalPages}
              onClick={() => setCurrentPage(parsedCurrentPage + 1)}
              className="inline-flex items-center border-transparent text-sm font-medium text-secondary-100"
            >
              <ArrowForwardIos
                className="ml-3"
                style={{ fontSize: "0.9rem" }}
                aria-hidden="true"
              />
            </button>
          )}
        </div>
        <div></div>
      </nav>
    </div>
  );
};

export default Pagination;
