import React from "react";
import { WorkOrder } from "../../types/WorkOrder";
import StatusBadge from "../general-ui/StatusBadges";
import { formatDate, getFirstInitial } from "../../utils/FormatFunctions";
import {
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  PaperClipIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { UserIcon as UserIconSolid } from "@heroicons/react/20/solid";

interface WorkOrderCardProps {
  workOrder: WorkOrder;
  selectedWorkOrders?: string[];
  setSelectedWorkOrders?: React.Dispatch<React.SetStateAction<string[]>>;
  handleView?: (id: string) => void;
  hideCheckbox?: boolean;
}
const WorkOrderCard: React.FC<WorkOrderCardProps> = ({
  workOrder,
  selectedWorkOrders = [],
  setSelectedWorkOrders = () => {},
  handleView = () => {},
  hideCheckbox = true,
}) => {
  return (
    <div
      className="flex flex-col w-full bg-primary"
      onClick={() => handleView(workOrder._id)}
    >
      <div className="px-5 py-3 gap-2.5 flex w-full justify-between">
        <div
          className="flex gap-4 items-center"
          onClick={(e) => e.stopPropagation()}
        >
          {!hideCheckbox && (
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-secondary-300 text-accent focus:ring-accent"
              onChange={() => {
                setSelectedWorkOrders((prev) =>
                  prev.some((w_id) => w_id === workOrder._id)
                    ? prev.filter((w_id) => w_id !== workOrder._id)
                    : [...prev, workOrder._id]
                );
              }}
              checked={selectedWorkOrders.includes(workOrder._id)}
            />
          )}
          <StatusBadge status={workOrder.status} />
          <StatusBadge status={workOrder.urgency} />
        </div>
        <div className="text-sm font-light">
          {formatDate(workOrder.dateReported)}
        </div>
      </div>
      <div className="px-5 pb-3 flex flex-col gap-2">
        <span className="text-sm font-medium">{workOrder.title}</span>
        <span className="text-sm font-light w-3/4 truncate">
          {workOrder.details}
        </span>
      </div>
      <div className="border-t border-secondary-1000 px-5 py-2 flex justify-between">
        <div>
          {workOrder.assignedTo ? (
            <span className="text-sm font-light flex items-center gap-2">
              {workOrder.assignedTo.avatar ? (
                <img
                  src={workOrder.assignedTo.avatar}
                  alt=""
                  className="h-4 w-4 flex-shrink-0 rounded-full"
                />
              ) : (
                <UserIconSolid className="w-4 h-4 bg-secondary-900 text-primary p-0.5 rounded-xl" />
              )}{" "}
              {getFirstInitial(workOrder.assignedTo.firstname)}.{" "}
              {workOrder.assignedTo.lastname}
            </span>
          ) : (
            <span className="text-sm font-light flex items-center gap-2">
              <UserIconSolid className="w-4 h-4 bg-secondary-900 text-primary p-0.5 rounded-xl" />
              Unassigned
            </span>
          )}
        </div>
        <div className="flex items-center text-sm gap-5">
          <div className="flex items-center gap-2">
            {workOrder.notes.length}
            <DocumentTextIcon className="h-4 w-4" />
          </div>
          <div className="flex items-center gap-2">
            {workOrder.attachments.length}
            <PaperClipIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderCard;
