import { FC } from "react";
import {
  BusinessOutlined,
  EmailOutlined,
  Home,
  HomeOutlined,
  Person2Outlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { useVendor } from "../../hooks/useVendor";
import { Vendor } from "../../types/Vendor";
import Table from "../general/Table";
import Button from "../general/Button";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../general/Spinner";

const VendorsTable: FC = () => {
  const { currentUser } = useAuth();
  const { vendors, updateShowModalState, setSelectedVendor, isLoading } =
    useVendor();
  const permissions = currentUser.access;

  const columns = [
    {
      label: "Company",
      key: "name",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <div className="text-sm p-4 lg:p-0">
            <div className="flex items-center">
              <div className="flex-shrink-0 text-accent-300 bg-accent-1000 h-8 rounded-full w-8 flex items-center justify-center">
                <BusinessOutlined className="" style={{ fontSize: "1.2rem" }} />
              </div>
              <div className="ml-4">
                <div className="font-medium text-secondary">{vendor.name}</div>
                <div className="lg:hidden mt-1 text-secondary-400 gap-2 flex items-center">
                  <Person2Outlined style={{ fontSize: "1rem" }} />
                  {capitalizeString(vendor.contact)}
                </div>
                <div className="mt-1  text-secondary-400 gap-2 flex items-center max-w-40 lg:max-w-52 md:max-w-96 overflow-hidden ">
                  <EmailOutlined style={{ fontSize: "1rem" }} />
                  <span className="truncate">{vendor.email}</span>
                </div>
                <div className="mt-1  text-secondary-400 gap-2 flex items-center">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(vendor.phone)}
                </div>
                <div className="mt-1  text-secondary-400 gap-2 flex items-center max-w-40 lg:max-w-52 md:max-w-96 overflow-hidden ">
                  <HomeOutlined style={{ fontSize: "1rem" }} />
                  <span className="truncate">{vendor.address}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      className: "truncate",
    },
    {
      label: "Type",
      key: "type",
      sortable: false,
      render: (vendor: Vendor) => <>{capitalizeString(vendor.type)}</>,
      className: "hidden lg:table-cell truncate",
    },
    {
      label: "Address",
      key: "address",
      sortable: false,
      render: (vendor: Vendor) => <>{capitalizeString(vendor.address)}</>,
      className: "hidden  truncate",
    },
    {
      label: "Email",
      key: "email",
      sortable: false,
      render: (vendor: Vendor) => <>{vendor.email}</>,
      className: "hidden  truncate",
    },
    {
      label: "Phone",
      key: "phone",
      sortable: false,
      render: (vendor: Vendor) => <>{formatPhoneNumber(vendor.phone)}</>,
      className: "hidden truncate",
    },
    {
      label: "Contact Person",
      key: "contact",
      sortable: false,
      render: (vendor: Vendor) => <>{vendor.contact}</>,
      className: "hidden lg:table-cell truncate",
    },
    {
      label: "",
      key: "delete",
      sortable: false,
      render: (vendor: Vendor) =>
        ["organization-admin", "regional"].includes(permissions) && (
          <Button
            onClick={() => {
              setSelectedVendor(vendor);
              updateShowModalState("showDelete", true);
            }}
            icon="Delete"
            styleString="danger"
            children={null}
            overrideStyles="w-6"
          />
        ),
      className: "lg:table-cell truncate",
    },
  ];
  return (
    <Table
      columns={columns}
      data={vendors}
      isClickable={false}
      showHeader={window.innerWidth > 640}
      noDataMessage={isLoading ? <Spinner /> : "No vendors found"}
      paginationScheme="none"
      stickyFirstColumn
    />
  );
};

export default VendorsTable;
