import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { useToast } from "../../hooks/useToast";

interface ToastProps {}

const Toast: React.FC<ToastProps> = () => {
  const { response, hideResponse, timeToShow } = useToast();

  // ONce the component is rendered, we will set a timer to hide the toast and update the progress
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (response.show) {
      const interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, timeToShow / 100);

      return () => {
        setProgress(0);
        clearInterval(interval);
      };
    }
  }, [response.show]);

  return (
    <>
      <div
        aria-live="assertive"
        className="z-40 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={response.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-sm z-50 bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{response?.icon}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {response?.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {response?.body}
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2"
                      onClick={() => hideResponse()}
                    >
                      <span className="sr-only">Close</span>
                      <Close style={{ fontSize: "1rem" }} aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="relative mt-4 h-1 w-full bg-gray-200">
                <div
                  className="absolute left-0 h-full bg-secondary-100"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Toast;
