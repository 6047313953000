export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the previous timeout if called within the delay
    }
    timeoutId = setTimeout(() => {
      func(...args); // Call the function after the delay with the provided arguments
    }, delay);
  };
};

export const returnTypeString = (
  type: "life-safety" | "prev-maint" | "administrative"
) => {
  switch (type) {
    case "life-safety":
      return "Life Safety";
    case "prev-maint":
      return "Preventative Maintenance";
    case "administrative":
      return "Administrative";
    default:
      return "Recurring Tasks";
  }
};
