import { useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import WorkOrderDetails from "./WorkOrderDetails";
import { ArrowBack, ChevronLeft, ChevronRight } from "@mui/icons-material";

import { useSwipeable } from "react-swipeable";
import { Tooltip } from "react-tooltip";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeftIcon, ShareIcon } from "@heroicons/react/24/outline";

const WorkOrderInstance = () => {
  const { selectedWorkOrder, fetchAdjacentWorkOrder, searchParams } =
    useWorkOrder();
  const location = useLocation();
  const navigate = useNavigate();
  const [returnUrl] = useState(location.state?.returnUrl);

  const handleBack = () => {
    navigate(returnUrl || "/");
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      fetchAdjacentWorkOrder("next");
    },
    onSwipedRight: () => {
      fetchAdjacentWorkOrder("prev");
    },
  });

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        variant="secondary"
        title={
          <div className="flex justify  justify-between items-center w-full">
            <button className="cursor-pointer" onClick={handleBack}>
              <ChevronLeftIcon className="h-5 w-5" />
            </button>

            <h2 className="font-normal text-sm text-secondary">
              Order: ID {selectedWorkOrder?.shortId}
            </h2>
            <button className="cursor-pointer">
              <ShareIcon className="h-5 w-5" />
            </button>
          </div>
        }
        subtitle={""}
        buttons={
          <div className="gap-2  hidden sm:flex">
            <button
              className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
              onClick={() => fetchAdjacentWorkOrder("prev")}
              data-tooltip-id="adjacent_work_order"
              data-tooltip-content="Previous Work Order"
              data-tooltip-place="bottom"
            >
              <ChevronLeft />
            </button>
            <button
              className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
              onClick={() => fetchAdjacentWorkOrder("next")}
              data-tooltip-id="adjacent_work_order"
              data-tooltip-content="Next Work Order"
              data-tooltip-place="bottom"
            >
              <ChevronRight />
            </button>
            <Tooltip id="adjacent_work_order" place="bottom" />
          </div>
        }
      />
      <div
        className="flex flex-1 sm:hidden flex-col flex-grow h-full overflow-hidden w-full"
        {...swipeHandlers}
      >
        {selectedWorkOrder && <WorkOrderDetails />}
      </div>
    </div>
  );
};

export default WorkOrderInstance;
