import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface SearchInputProps {
  searchTerm: string;
  handleSearchChange: (searchTerm: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({searchTerm, handleSearchChange }) => {
  return (
    <div className="grid grid-cols-1">
      <input
        placeholder="Search"
        className="col-start-1 row-start-1 block w-full rounded-lg bg-primary py-1.5 pl-10 pr-3 text-base text-secondary ring-1 ring-inset ring-secondary-1000 focus:ring-accent focus:ring-2 outline-none border-none placeholder:text-secondary-500 sm:pl-9 sm:text-sm/6"
       value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      <MagnifyingGlassIcon
        aria-hidden="true"
        className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-secondary-300 sm:size-4"
      />
    </div>
  );
};

export default SearchInput;