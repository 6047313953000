import { Fragment, useEffect, useState, memo, useMemo } from "react";
import Button from "../general/Button";
import {
  Controller,
  useFieldArray,
  useForm,
  FormProvider,
  useFormContext,
  UseFormRegister,
  Control,
  FieldErrors,
  UseFormClearErrors,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useFacility } from "../../hooks/useFacility";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import { capitalizeString } from "../../utils/FormatFunctions";
import { LabelOutlined } from "@mui/icons-material";
import { ClipboardIcon, TrashIcon } from "@heroicons/react/24/outline";
import Input, { InputType } from "../general-ui/Input";
import { Vendor } from "../../types/Vendor";
import { Map } from "../../types/Map";
import Select from "react-select";
import { File } from "../../types/File";
import RecurringTaskPreview from "../data-display/RecurringTaskPreview";

interface RecurringTaskFormProps {
  isEditing?: boolean;
  onUpdateComplete?: () => void;
}

type Input = {
  id: string;
  type: InputType;
  label: string;
  options?: { value: string; label: string }[];
  validator?: Validator;
  isDeleted?: boolean;
};

type Validator = {
  lowerLimit?: number | null;
  upperLimit?: number | null;
  required?: boolean;
  requireNote?: boolean;
};

export interface NewRecurringTaskFormData {
  taskType: string;
  category: string;
  title: string;
  startDate: string;
  endDate?: string;
  recurrence: {
    frequency: string;
  };
  source: string;
  details: string;
  inputs: {
    isDeleted?: boolean;
    name: string;
    dataType: string;
    options?: string[];
    validator: Validator;
  }[];
  notifyVendor: boolean;
  skipWeekends: boolean;
  vendor?: string;
  map?: string;
  attachments: string[];
  sourceTemplateId: string;
  facility: string;
  newInput?: {
    name: string;
    dataType: string;
    validator: Validator;
  };
  skippable?: boolean;
}

interface FormFieldProps {
  input: Input;
  register: UseFormRegister<NewRecurringTaskFormData>;
  control: Control<NewRecurringTaskFormData>;
  errors: FieldErrors<NewRecurringTaskFormData>;
  clearErrors: UseFormClearErrors<NewRecurringTaskFormData>;
}

// Memoized form field component to prevent unnecessary re-renders
const FormField = memo(
  ({ input, register, control, errors, clearErrors }: FormFieldProps) => {
    const { watch, trigger } = useFormContext<NewRecurringTaskFormData>();

    // Watch the field value
    const fieldValue = watch(input.id as keyof NewRecurringTaskFormData);

    // Function to get nested errors
    const getNestedError = (fieldName: string) => {
      if (fieldName.includes(".")) {
        const [parent, child] = fieldName.split(".");
        return (errors[parent as keyof NewRecurringTaskFormData] as any)?.[
          child
        ];
      }
      return errors[fieldName as keyof NewRecurringTaskFormData];
    };

    // Clear error when value changes
    useEffect(() => {
      if (fieldValue) {
        clearErrors(input.id as keyof NewRecurringTaskFormData);
      }
    }, [fieldValue, input.id, clearErrors]);

    return (
      <div className="py-2 grid grid-cols-3">
        <label
          htmlFor={input.id}
          className="flex items-end text-sm font-medium leading-6 mb-2"
        >
          {input.label}
        </label>
        <div className="col-span-2">
          <Input
            key={input.id}
            label={input.label}
            name={input.id}
            type={input.type}
            options={input.options}
            register={register}
            control={control}
            errors={getNestedError(input.id)}
            showLabel={false}
            onBlur={() => {
              trigger(input.id as keyof NewRecurringTaskFormData);
            }}
          />
        </div>
      </div>
    );
  }
);

const newInputSchema = Yup.object().shape({
  name: Yup.string().required("Input name is required."),
  dataType: Yup.string().required("Please select an option."),
  validator: Yup.object().shape({
    lowerLimit: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    upperLimit: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("lowerLimit", (lowerLimit, schema) => {
        return lowerLimit
          ? schema.min(
              lowerLimit as unknown as number,
              "Upper limit must be greater than or equal to lower limit"
            )
          : schema;
      }),
    required: Yup.boolean(),
    requireNote: Yup.boolean(),
  }),
});

const validationSchema = Yup.object().shape({
  taskType: Yup.string().required("Task type is required"),
  category: Yup.string().required("Category is required"),
  title: Yup.string().required("Title is required"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string(),
  recurrence: Yup.object({
    frequency: Yup.string().required("Please select a frequency"),
  }).required(),
  source: Yup.string().required("Please select a source"),
  details: Yup.string()
    .required("Task details are required")
    .min(10, "Details must be at least 10 characters"),
  inputs: Yup.array()
    .of(
      Yup.object().shape({
        isDeleted: Yup.boolean(),
        name: Yup.string().required(),
        dataType: Yup.string().required(),
        validator: Yup.object().shape({
          lowerLimit: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          upperLimit: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when("lowerLimit", (lowerLimit, schema) => {
              return lowerLimit
                ? schema.min(
                    lowerLimit as unknown as number,
                    "Upper limit must be greater than or equal to lower limit"
                  )
                : schema;
            }),
          required: Yup.boolean(),
          requireNote: Yup.boolean(),
        }),
      })
    )
    .min(1, "At least one input field is required")
    .required(),
  notifyVendor: Yup.boolean().required(),
  skipWeekends: Yup.boolean().required(),
  sourceTemplateId: Yup.string().default(""),
  vendor: Yup.string(),
  map: Yup.string(),
  attachments: Yup.array().of(Yup.string().required()).required(),
  facility: Yup.string().required(),
  skippable: Yup.boolean(),
});


// Memoized main form content component
const FormContent = memo(
  ({
    formInputs,
    attachmentOptions,
    isEditing,
    updateShowModalState,
    onUpdateComplete,
    onSubmit,
  }: {
    formInputs: Input[];
    attachmentOptions: { value: string; label: string }[];
    isEditing: boolean;
    updateShowModalState: (modal: string, value: boolean) => void;
    onUpdateComplete?: () => void;
    onSubmit: (data: NewRecurringTaskFormData) => Promise<void>;
  }) => {
    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      watch,
      setValue,
      getValues,
      setError,
      trigger,
      clearErrors,
    } = useFormContext<NewRecurringTaskFormData>();

    const { fields, append, remove } = useFieldArray({
      control,
      name: "inputs",
    });

    const newDataType = watch("newInput.dataType");
    const formValues = getValues();

    const validateField = async (
      name: keyof NewRecurringTaskFormData,
      value: any
    ) => {
      try {
        await trigger(name);
        if (value && errors[name]) {
          clearErrors(name);
        }
      } catch (error) {
        console.error("Validation error:", error);
      }
    };

    const handleAddInput = async () => {
      let newNameInput: string = getValues("newInput.name").trim();
      const newInput = {
        name: newNameInput,
        dataType: getValues("newInput.dataType"),
        validator: {
          required: getValues("newInput.validator.required") ?? false,
          ...(getValues("newInput.dataType") === "number" && {
            lowerLimit: getValues("newInput.validator.lowerLimit"),
            upperLimit: getValues("newInput.validator.upperLimit"),
          }),
        },
      };

      try {
        await newInputSchema.validate(newInput, { abortEarly: false });

        const lowerLimit = newInput.validator.lowerLimit;
        const upperLimit = newInput.validator.upperLimit;

        if (newInput.dataType === "number" && lowerLimit && upperLimit) {
          if (Number(lowerLimit) > Number(upperLimit)) {
            setError("newInput.validator.upperLimit", {
              type: "manual",
              message: "Upper limit must be greater than lower limit",
            });
            return;
          }
        }

        append({
          ...newInput,
          isDeleted: false,
        });

        clearErrors("inputs");

        setValue("newInput", {
          name: "",
          dataType: "",
          validator: {
            required: true,
          },
        });
      } catch (validationError) {
        if (validationError instanceof Yup.ValidationError) {
          validationError.inner.forEach((error) => {
            const path = error.path?.replace(/^newInput\./, "");
            setError(`newInput.${path}` as keyof NewRecurringTaskFormData, {
              type: "manual",
              message: error.message,
            });
          });
        }
      }
    };

    const handleDeleteInput = (index: number) => {
      formValues.inputs[index].isDeleted = true;
      setValue("inputs", formValues.inputs);
      remove(index);
    };

    const renderInputFields = useMemo(() => {
      return fields
        .filter((input) => !input.isDeleted)
        .map((inputObject, index) => (
          <div key={inputObject.id} className="flex items-center gap-2">
            <LabelOutlined className="text-secondary-100" fontSize="small" />
            {capitalizeString(decodeURIComponent(inputObject.name))}
            <span className="bg-secondary-1100 py-1 px-2 w-fit text-xs rounded-full">
              {capitalizeString(inputObject.dataType)}
            </span>
            <button
              type="button"
              className="relative -mr-1 h-3.5 w-3.5 rounded-sm"
              onClick={() => handleDeleteInput(index)}
            >
              <TrashIcon className="h-3.5 w-3.5 stroke-reds hover:stroke-reds-400/75" />
            </button>
          </div>
        ));
    }, [fields, handleDeleteInput]);

    const formFields = useMemo(
      () =>
        formInputs.map((input) => (
          <FormField
            key={input.id}
            input={input}
            register={register}
            control={control}
            errors={errors}
            clearErrors={
              clearErrors as UseFormClearErrors<NewRecurringTaskFormData>
            }
          />
        )),
      [formInputs, register, control, errors, clearErrors]
    );

    return (
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          validateField(
            target.name as keyof NewRecurringTaskFormData,
            target.value
          );
        }}
        className="my-4 max-w-screen-sm"
      >
        {formFields}

        <div className="py-2 grid grid-cols-3">
          <label className="flex items-end text-sm font-medium leading-6 mb-2">
            Input Fields
          </label>
          <div className="col-span-2 bg-secondary-1200 rounded p-2">
            {fields.length > 0 && (
              <div className="bg-primary border border-secondary-1000 rounded p-1 my-1">
                {renderInputFields}
              </div>
            )}
            <div className="col-span-2">
              {errors.inputs && errors.inputs.message && (
                <p className="text-reds-500 text-sm mt-1">
                  {errors.inputs.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-5 gap-2 my-2">
              <div className="col-span-2 py-1">
                <Input
                  name="newInput.name"
                  label="Input Name"
                  showLabel={false}
                  placeholder="Input Name"
                  type="text"
                  register={register}
                  errors={errors.newInput?.name}
                />
              </div>
              <div className="col-span-2 py-1">
                <Input
                  name="newInput.dataType"
                  label="Input Type"
                  showLabel={false}
                  placeholder="Select Type"
                  type="select"
                  options={[
                    { label: "Pass/Fail", value: "radio" },
                    { label: "Pass/Fail/Na", value: "radio-na" },
                    { label: "Number", value: "number" },
                    { label: "Text", value: "text" },
                    { label: "File", value: "file" },
                  ]}
                  register={register}
                  errors={errors.newInput?.dataType}
                />
              </div>
              <div className="col-span-1 flex items-center w-fit">
                <Button
                  icon="Add"
                  styleString="bold"
                  type="button"
                  onClick={() => handleAddInput()}
                />
              </div>
              <div className="col-span-full bg-primary p-2">
                <span className="flex py-2 w-full border-b border-secondary-1000">
                  Validators
                </span>
                <div className="grid grid-cols-2 gap-2 py-2">
                  <label className="text-sm font-medium">Required field</label>
                  <Input
                    name="newInput.validator.required"
                    label="Required"
                    type="switch"
                    showLabel={false}
                    control={control}
                    errors={
                      errors[
                        "newInput.validator.required" as keyof typeof errors
                      ]
                    }
                  />
                </div>
                {newDataType === "number" && (
                  <div className="grid grid-cols-2 gap-2">
                    <Input
                      name="newInput.validator.lowerLimit"
                      label="Lower Limit"
                      placeholder="Lower Limit"
                      type="number"
                      control={control}
                      errors={
                        errors[
                          "newInput.validator.lowerLimit" as keyof typeof errors
                        ]
                      }
                    />
                    <Input
                      name="newInput.validator.upperLimit"
                      label="Upper Limit"
                      placeholder="Upper Limit"
                      type="number"
                      control={control}
                      errors={
                        errors[
                          "newInput.validator.upperLimit" as keyof typeof errors
                        ]
                      }
                    />
                    {errors.newInput?.validator?.upperLimit && (
                      <span className="text-xs text-reds-500 mt-1">
                        {errors.newInput.validator.upperLimit.message}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3">
          <label className="block text-sm font-medium leading-6 mb-2">
            Attachments
          </label>
          <Controller
            name="attachments"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                options={attachmentOptions}
                className="basic-multi-select col-span-2"
                classNamePrefix="select"
                placeholder="Select files (optional)"
                isClearable={false}
                onChange={(selected) => {
                  field.onChange(selected.map((item) => item.value));
                }}
                value={attachmentOptions.filter((option) =>
                  (field.value ?? []).includes(option.value)
                )}
                styles={{
                  input: (base) => ({
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                    primary25: "lightgray",
                  },
                })}
              />
            )}
          />
        </div>

        {isEditing && (
          <div className="flex justify-end gap-2 mt-4">
            <Button
              styleString="secondary"
              onClick={() => {
                updateShowModalState("viewTask", true);
                onUpdateComplete?.();
              }}
              children="Cancel"
            />
            <Button
              styleString="primary"
              type="submit"
              children="Save Changes"
              icon="Save"
            />
          </div>
        )}
      </form>
    );
  }
);

const RecurringTaskForm: React.FC<RecurringTaskFormProps> = ({
  isEditing = false,
  onUpdateComplete,
}) => {
  const { selectedFacility } = useFacility();
  const {
    selectedTask,
    updateShowModalState,
    createTask,
    updateTask,
    templates,
    attachments,
    maps,
    vendors,
  } = useTaskScheduler();

  const attachmentOptions = attachments.map((document: File) => ({
    value: document._id,
    label: document.name,
  }));

  const [steps, setSteps] = useState([
    {
      id: "01",
      name: isEditing ? "Review Current" : "Select template",
      stepCount: 1,
      current: true,
      disabled: false,
    },
    {
      id: "02",
      name: isEditing ? "Make Changes" : "Customize template",
      stepCount: 2,
      current: false,
      disabled: false,
    },
    { id: "03", name: "Preview", stepCount: 3, current: false, disabled: true },
  ]);

  const currentStepCount = steps.find((step) => step.current)?.stepCount || 1;

  const methods = useForm<NewRecurringTaskFormData>({
    defaultValues: {
      notifyVendor: false,
      skipWeekends: false,
      attachments: [],
      vendor: "",
      map: "",
      sourceTemplateId: "",
      taskType: "",
      category: "",
      title: "",
      startDate: "",
      endDate: "",
      recurrence: {
        frequency: "",
      },
      source: "",
      details: "",
      inputs: [],
      facility: selectedFacility,
      newInput: {
        name: "",
        dataType: "",
        validator: {
          required: true,
        },
      },
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const toggleStep = async (stepCount: number) => {
    if (currentStepCount === 2 && stepCount > currentStepCount) {
      const isValid = await methods.trigger();

      if (isValid) {
        setSteps(
          steps.map((item) =>
            item.stepCount === stepCount
              ? { ...item, current: true }
              : { ...item, current: false }
          )
        );
      } else {
        await methods.trigger();
      }
    } else {
      setSteps(
        steps.map((item) =>
          item.stepCount === stepCount
            ? { ...item, current: true }
            : { ...item, current: false }
        )
      );
    }
  };

  const selectedTemplateId = methods.watch("sourceTemplateId");
  const selectedTemplate = templates.find(
    (template) => template._id === selectedTemplateId
  );

  useEffect(() => {
    if (isEditing && selectedTask) {
      const { _id, ...formValues } = selectedTask;
      const formattedValues = {
        ...formValues,
        startDate: formValues.startDate
          ? new Date(formValues.startDate).toISOString().split("T")[0]
          : "",
        endDate: formValues.endDate
          ? new Date(formValues.endDate).toISOString().split("T")[0]
          : "",
        vendor: formValues.vendor || "",
        map: formValues.map || "",
        newInput: {
          name: "",
          dataType: "",
          validator: {
            required: true,
          },
        },
      };
      methods.reset(formattedValues as any);
    } else if (!isEditing && selectedTemplate) {
      methods.setValue("title", selectedTemplate.title || "");
      methods.setValue("taskType", selectedTemplate.taskType || "");
      methods.setValue("category", selectedTemplate.category || "");
      methods.setValue("source", selectedTemplate.source || "");
      methods.setValue("notifyVendor", selectedTemplate.notifyVendor || false);
      methods.setValue(
        "recurrence.frequency",
        selectedTemplate.recurrence.frequency || ""
      );
      methods.setValue(
        "inputs",
        selectedTemplate.inputs.map((input) => ({
          ...input,
          validator: {
            ...input.validator,
            lowerLimit: input.validator.lowerLimit
              ? Number(input.validator.lowerLimit)
              : null,
            upperLimit: input.validator.upperLimit
              ? Number(input.validator.upperLimit)
              : null,
          },
        })) || []
      );
      methods.setValue("details", selectedTemplate.details || "");
      methods.setValue("sourceTemplateId", selectedTemplate._id || "");
      methods.setValue("skipWeekends", selectedTemplate.skipWeekends || false);
    }
  }, [
    isEditing,
    selectedTask,
    methods.reset,
    selectedTemplate,
    methods.setValue,
  ]);

  const onSubmit = async (data: NewRecurringTaskFormData) => {
    try {
      if (isEditing) {
        if (selectedTask) {
          await updateTask(selectedTask._id, data);
          onUpdateComplete?.();
        }
      } else {
        await createTask(data);
        updateShowModalState("createTask", false);
      }
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const formInputs: Input[] = [
    {
      id: "title",
      label: "Task Title",
      type: "text",
    },
    {
      id: "taskType",
      label: "Task Type",
      type: "select",
      options: [
        { value: "life-safety", label: "Life Safety" },
        { value: "prev-maint", label: "Preventative Maintenance" },
      ],
    },
    {
      id: "category",
      label: "Regulation Category",
      type: "select",
      options: [
        { value: "fire safety", label: "Fire Safety" },
        { value: "electrical safety", label: "Electrical Safety" },
        { value: "operational safety", label: "Operational Safety" },
        { value: "gas safety", label: "Gas/Air Safety" },
      ],
    },
    {
      id: "recurrence.frequency",
      label: "Frequency",
      type: "select",
      options: [
        { value: "daily", label: "Daily" },
        { value: "weekly", label: "Weekly" },
        { value: "bi-weekly", label: "Biweekly" },
        { value: "monthly", label: "Monthly" },
        { value: "quarterly", label: "Quarterly" },
        { value: "semi-annually", label: "Semi-Annually" },
        { value: "annually", label: "Annually" },
        { value: "biennially", label: "2-Year" },
        { value: "3-year", label: "3-Year" },
        { value: "4-year", label: "4-Year" },
        { value: "5-year", label: "5-Year" },
      ],
    },
    {
      id: "skipWeekends",
      label: "Skip Weekends",
      type: "switch",
    },
    {
      id: "skippable",
      label: "Allow Task Skipping",
      type: "switch",
    },
    {
      id: "source",
      label: "Source",
      type: "select",
      options: [
        { value: "internal", label: "Internal" },
        { value: "external", label: "External" },
      ],
    },
    {
      id: "vendor",
      label: "Vendor",
      type: "select",
      options: [
        { value: "", label: "No Vendor" },
        ...vendors.map((vendor: Vendor) => ({
          value: vendor._id,
          label: vendor.name,
        })),
      ],
    },
    {
      id: "notifyVendor",
      label: "Notify Vendor",
      type: "switch",
    },
    {
      id: "details",
      label: "Task Details",
      type: "textarea",
    },
    {
      id: "startDate",
      label: "Start Date",
      type: "date",
    },
    {
      id: "endDate",
      label: "End Date",
      type: "date",
    },
    {
      id: "map",
      label: "Map",
      type: "select",
      options: [
        { value: "", label: "No Map" },
        ...maps.map((map: Map) => ({
          value: map._id,
          label: map.name,
        })),
      ],
    },
  ];

  const templateSelection = () => {
    return (
      <div className="px-4 sm:grid sm:grid-cols-3 sm:space-y-0 sm:px-6">
        <div>
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-800 sm:mt-1.5"
          >
            Select a template
          </label>
        </div>
        <div className="sm:col-span-2">
          <select
            className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
            {...methods.register("sourceTemplateId")}
          >
            <option value={""}>Build from new</option>

            {templates.map((template) => (
              <option key={template._id} value={template._id}>
                {capitalizeString(template.title)}
              </option>
            ))}
          </select>
        </div>
        <div className="sm:col-span-3">
          {selectedTemplate ? (
            <div className="mt-6 overflow-y-auto scrollbar-thin bg-secondary-1200 my-4 max-h-96 p-2">
              <dl className="">
                <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <h2 className="text-secondary-100 text-lg pb-4">
                    Template Overview
                  </h2>
                </div>
                {[
                  {
                    label: "Task Title",
                    value: capitalizeString(selectedTemplate.title),
                  },
                  {
                    label: "Task Type",
                    value: capitalizeString(selectedTemplate.taskType),
                  },
                  {
                    label: "Task Category",
                    value: capitalizeString(selectedTemplate.category),
                  },
                  {
                    label: "Frequency",
                    value: capitalizeString(
                      selectedTemplate.recurrence.frequency
                    ),
                  },
                  {
                    label: "Inputs",
                    value: selectedTemplate.inputs.map((input, index) => (
                      <li
                        key={`${input}-${index}`}
                        className="flex flex-col text-xs"
                      >
                        <div className="flex items-center gap-2">
                          <LabelOutlined
                            className="text-secondary-100"
                            fontSize="small"
                          />
                          {capitalizeString(decodeURIComponent(input.name))}
                          <span className="bg-secondary-1100 py-1 px-2 w-fit text-xs rounded-full">
                            {capitalizeString(input.dataType)}
                          </span>
                        </div>
                      </li>
                    )),
                  },
                  {
                    label: "Description",
                    value: capitalizeString(selectedTemplate.details),
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt className="text-sm font-medium leading-6 text-secondary-100">
                      {item.label}
                    </dt>
                    <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {Array.isArray(item.value) ? (
                          <ul>{item.value}</ul>
                        ) : (
                          item.value
                        )}
                      </span>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          ) : (
            <div className="bg-secondary-1200 h-36 my-4 flex justify-center p-6">
              <div className="flex flex-col gap-4 items-center text-secondary-500 font-light">
                <ClipboardIcon className="h-12 w-12" />
                <span className="text-sm">
                  Click next step to begin customizing the task
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return (
        <FormProvider {...methods}>
          <FormContent
            formInputs={formInputs}
            attachmentOptions={attachmentOptions}
            isEditing={isEditing}
            updateShowModalState={updateShowModalState}
            onUpdateComplete={onUpdateComplete}
            onSubmit={onSubmit}
          />
        </FormProvider>
      );
    }

    return (
      <FormProvider {...methods}>
        <div className="max-w-5xl">
          <div className="flex flex-col sm:flex-row w-full gap-2 justify-between px-6 py-2">
            {steps.map((step, index) => (
              <Fragment key={step.name}>
                <div>
                  <button
                    onClick={() => toggleStep(step.stepCount)}
                    disabled={step.disabled && step.stepCount === 3}
                    className={`cursor-pointer group flex w-full items-center gap-2 ${
                      currentStepCount >= step.stepCount
                        ? "text-accent-500"
                        : "text-secondary-1000"
                    } disabled:border-secondary-1000`}
                  >
                    <span
                      className={`border ${
                        currentStepCount >= step.stepCount
                          ? "border-accent"
                          : ""
                      } rounded-full h-10 w-10 flex items-center justify-center`}
                    >
                      {step.id}
                    </span>
                    <span className="font-light">{step.name}</span>
                  </button>
                </div>
                {index < steps.length - 1 && (
                  <hr
                    className={`my-auto h-0.5 w-12 ${
                      currentStepCount < step.stepCount + 1
                        ? "bg-secondary-1000"
                        : "bg-accent"
                    } border-0`}
                  />
                )}
              </Fragment>
            ))}
          </div>

          {currentStepCount === 1 && templateSelection()}
          {currentStepCount === 2 && (
            <FormContent
              formInputs={formInputs}
              attachmentOptions={attachmentOptions}
              isEditing={isEditing}
              updateShowModalState={updateShowModalState}
              onUpdateComplete={onUpdateComplete}
              onSubmit={onSubmit}
            />
          )}
          {currentStepCount === 3 && (
            <RecurringTaskPreview taskData={methods.getValues()} />
          )}

          <div className="flex justify-end gap-2 mt-4">
            <Button
              styleString="secondary"
              onClick={
                currentStepCount > 1
                  ? () => toggleStep(currentStepCount - 1)
                  : () => updateShowModalState("createTask", false)
              }
              children={currentStepCount > 1 ? "Previous Step" : "Cancel"}
              icon={currentStepCount > 1 ? "ArrowBack" : undefined}
            />
            <Button
              styleString="primary"
              onClick={
                currentStepCount !== 3
                  ? () => toggleStep(currentStepCount + 1)
                  : methods.handleSubmit(onSubmit)
              }
              children={currentStepCount !== 3 ? "Next Step" : "Submit"}
              icon={currentStepCount !== 3 ? "ArrowForward" : undefined}
              type="button"
              reverse={true}
            />
          </div>
        </div>
      </FormProvider>
    );
  };

  return renderContent();
};

export default RecurringTaskForm;
