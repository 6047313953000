import React, { useState } from "react";
import { PhotoCameraBackOutlined } from "@mui/icons-material";
import { WorkOrder } from "../../types/WorkOrder";
import { retrieveFileName } from "../../utils/FormatFunctions";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import Lightbox from "./LightBox";

interface AttachmentsProps {}

const Attachments: React.FC<AttachmentsProps> = ({}) => {
  const { selectedWorkOrder, images } = useWorkOrder();
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  if (!selectedWorkOrder) {
    return null;
  }
  return (
    <>
      <div className="px-4 py-4 sm:gap-4 sm:px-0 flex justify-center w-full">
        {images.length > 0 ? (
          <ul className="list-none space-y-4 w-full">
            {images.map((image, index) => (
              <li
                className=" cursor-pointer"
                key={index}
                onClick={() => {
                  setSelectedImage(index);
                  setShowLightbox(true);
                }}
              >
                <div className="flex w-full justify-between mx-auto">
                  <div className="flex gap-2 items-center">
                    <img
                      src={image}
                      alt="work order image"
                      className="w-12 h-12 object-contain rounded-md border border-secondary-1100"
                    />
                    <p className="text-xs truncate max-w-32 text-secondary-100 font-semibold">
                      {retrieveFileName(selectedWorkOrder.attachments[index])}
                    </p>
                  </div>
                  <button
                    className="text-secondary-100 text-sm underline"
                    onClick={() => {
                      setSelectedImage(index);
                      setShowLightbox(true);
                    }}
                  >
                    View
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className=" rounded-md w-full h-52 px-1.5 py-2  text-gray-500  justify-center items-center border border-secondary-1000 border-dashed flex flex-col gap-2">
            <div className="bg-secondary-1100 p-2 rounded text-secondary">
              <PhotoCameraBackOutlined />
            </div>
            <span className="text-sm text-secondary text-center">
              No images attached to this work order.
            </span>
          </div>
        )}
      </div>

      {showLightbox && (
        <Lightbox
          images={images}
          selectedImage={selectedImage}
          show={showLightbox}
          setShow={setShowLightbox}
        />
      )}
    </>
  );
};

export default Attachments;
