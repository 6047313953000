import React, { useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import RecurringTasks from "../../updatedcomponents/tables/RecurringTaskList";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Modal from "../../updatedcomponents/general-ui/Modal";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import RecurringTaskForm from "../../updatedcomponents/forms/RecurringTaskForm";
import RecurringTaskPreview from "../../updatedcomponents/data-display/RecurringTaskPreview";

const TaskScheduler: React.FC = () => {
  const { currentUser } = useAuth();
  const {
    tasks,
    setSearchTerm,
    showModalState,
    updateShowModalState,
    selectedTask,
    handleDeleteTask,
  } = useTaskScheduler();

  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
    // Don't show the view modal when editing
    updateShowModalState("viewTask", false);
  };

  const handleUpdateComplete = () => {
    setIsEditing(false);
    // Show the view modal again after update
    updateShowModalState("viewTask", true);
  };

  return (
    <div>
      <PageHeader
        title="Task Scheduler"
        buttons={
          ["regional", "organization-admin"].includes(currentUser?.access) && (
            <Button
              styleString="primary"
              icon="Add"
              onClick={() => updateShowModalState("createTask", true)}
              children={"Schedule Recurring Task"}
            />
          )
        }
      />
      <div className="flex flex-col gap-y-2">
        <div className="relative shadow-sm mx-2 mt-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              aria-hidden="true"
              className="h-5 w-5 text-secondary-400"
            />
          </div>
          <input
            id="task-search-input"
            type="text"
            placeholder="Search for a task..."
            className="block w-full rounded-lg border-0 py-1.5 pl-10 text-secondary-100 ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-800 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <RecurringTasks
          recurringTasks={tasks}
          type={"life-safety"}
        />

       
      </div>

      {/* Delete Modal */}
      <GenericModal
        title={"Delete this recurring task."}
        item={selectedTask}
        handleFunction={handleDeleteTask}
        showModal={showModalState.deleteTask}
        setShowModal={() => updateShowModalState("deleteTask", false)}
      />

      {/* Create Modal */}
      <Modal
        open={showModalState.createTask}
        setOpen={() => updateShowModalState("createTask", false)}
        title="Schedule a Recurring Task"
        subtitle="Get started by filling in the information below to schedule a new recurring task"
        content={<RecurringTaskForm />}
      />

      {/* View Modal */}
      <Modal
        open={showModalState.viewTask && !isEditing}
        setOpen={() => {
          if (showModalState.deleteTask) {
            return;
          } else {
            updateShowModalState("viewTask", false);
            setIsEditing(false);
          }
        }}
        title="View Recurring Task"
        subtitle="View the details of this recurring task"
        content={
          <RecurringTaskPreview
            taskData={selectedTask}
            onEdit={handleEdit}
            onDelete={() => updateShowModalState("deleteTask", true)}
          />
        }
      />

      {/* Edit Modal */}
      <Modal
        open={isEditing}
        setOpen={() => {
          setIsEditing(false);
          updateShowModalState("viewTask", true);
        }}
        title="Update Recurring Task"
        subtitle="Update the information below to modify this recurring task"
        content={
          <RecurringTaskForm
            isEditing={true}
            onUpdateComplete={handleUpdateComplete}
          />
        }
      />
    </div>
  );
};

export default TaskScheduler;
