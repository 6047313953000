import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import SliderContainer from "../general-ui/SliderContainer";
import {
  AdjustmentsHorizontalIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import Button from "../general/Button";

interface FilterOption {
  name: string;
  label: string;
  type: string;
  joinId?: string;
  options?: { value: string; label: string }[];
}

interface FilterModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  filterOptions: FilterOption[];
  filters: string;
  applyFilters: (filters: { [key: string]: any }) => void;
  resetFilters: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  show,
  setShow,
  filterOptions,
  filters,
  applyFilters,
  resetFilters,
}) => {
  const filtersString =
    typeof filters === "string" ? filters : JSON.stringify(filters);
  const parsedFilters = JSON.parse(filtersString || "{}");
  const [localFilters, setLocalFilters] = useState<{ [key: string]: any }>(
    parsedFilters
  );
  const [dateError, setDateError] = useState<string>("");

  // useEffect to update localFilters when filters prop changes
  useEffect(() => {
    try {
      const newParsedFilters = JSON.parse(filtersString || "{}");
      setLocalFilters(newParsedFilters);
    } catch (error) {
      setLocalFilters({});
    }
  }, [filtersString]); // Watch filtersString for changes

  const validateDates = (startDate: string, endDate: string): string => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (start > end) {
        return "End date cannot be before start date";
      }
    }
    return "";
  };

  const handleLocalFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target as HTMLInputElement;
    const checked = (event.target as HTMLInputElement).checked;

    setLocalFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (type === "checkbox") {
        if (checked) {
          updatedFilters[name] = checked;
        } else {
          updatedFilters[name] = false;
        }
      } else {
        updatedFilters[name] = value;
      }

      // Validate dates when either date field changes
      if (name === "dueDateFrom" || name === "dueDateTo") {
        const startDate =
          name === "dueDateFrom" ? value : updatedFilters.dueDateFrom;
        const endDate = name === "dueDateTo" ? value : updatedFilters.dueDateTo;

        const error = validateDates(startDate, endDate);
        setDateError(error);
      }

      return updatedFilters;
    });
  };

  const handleApplyFilters = () => {
    // Only apply filters if there's no date error
    if (!dateError) {
      applyFilters(localFilters);
      setShow(false);
    }
  };

  const handleResetFilters = () => {
    resetFilters();
    setLocalFilters({});
    setShow(false);
  };

  const renderDateFields = (
    option: FilterOption,
    pairedField: FilterOption
  ) => (
    <div key={option.joinId} className="flex-1">
      <div className="flex gap-4">
        {/* Start Date Field */}
        <div className="flex-1">
          <label
            htmlFor={option.name}
            className="block truncate text-sm font-medium leading-6 text-gray-900"
          >
            {option.label}
          </label>
          <input
            type="date"
            name={option.name}
            id={option.name}
            className={`mt-2 block w-full rounded-lg border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ${
              dateError ? "ring-red-500" : "ring-gray-300"
            } focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6`}
            value={localFilters[option.name] || ""}
            onChange={handleLocalFilterChange}
          />
        </div>

        {/* End Date Field */}
        <div className="flex-1">
          <label
            htmlFor={pairedField.name}
            className="block truncate text-sm font-medium leading-6 text-gray-900"
          >
            {pairedField.label}
          </label>
          <input
            type="date"
            name={pairedField.name}
            id={pairedField.name}
            className={`mt-2 block w-full rounded-lg border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ${
              dateError ? "ring-red-500" : "ring-gray-300"
            } focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6`}
            value={localFilters[pairedField.name] || ""}
            onChange={handleLocalFilterChange}
          />
        </div>
      </div>

      {/* Error Message */}
      {dateError && (
        <div className="mt-2 text-sm text-red-600 bg-red-50 p-2 rounded-lg">
          {dateError}
        </div>
      )}
    </div>
  );

  const renderRegularField = (option: FilterOption) => (
    <div key={option.name} className="">
      <label
        htmlFor={option.name}
        className="block truncate text-sm font-medium leading-6 text-gray-900"
      >
        {option.label}
      </label>
      {option.type === "selector" ? (
        <select
          name={option.name}
          id={option.name}
          className="mt-2 block w-full rounded-lg border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
          value={localFilters[option.name] || ""}
          onChange={handleLocalFilterChange}
        >
          {option.options?.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      ) : option.type === "checkbox" ? (
        <div className="mt-2">
          <input
            type={option.type}
            name={option.name}
            id={option.name}
            className="mr-2 rounded-lg text-secondary-100 focus:ring-accent-500"
            checked={localFilters[option.name] || false}
            onChange={handleLocalFilterChange}
          />
          <span className="text-sm font-semibold">{option.label}</span>
        </div>
      ) : (
        <input
          type={option.type}
          name={option.name}
          id={option.name}
          className="mt-2 block w-full rounded-lg border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
          placeholder={option.label}
          value={localFilters[option.name] || ""}
          onChange={handleLocalFilterChange}
        />
      )}
    </div>
  );

  const content = (
    <div className="flex h-full justify-between gap-1 flex-wrap flex-col px-2">
      <div>
        {filterOptions.map((option, index, array) => {
          // Handle date range fields
          if (option.name === "dueDateFrom") {
            const endDateOption = array.find((opt) => opt.name === "dueDateTo");
            if (endDateOption) {
              return (
                <React.Fragment key={`date-range-${index}`}>
                  {renderDateFields(option, endDateOption)}
                </React.Fragment>
              );
            }
          }
          // Skip the end date field since it's handled in the start date field
          if (option.name === "dueDateTo") return null;
          // Handle regular fields
          return (
            <React.Fragment key={`regular-field-${index}`}>
              {renderRegularField(option)}
            </React.Fragment>
          );
        })}
      </div>
      <div className="border-t border-secondary-1000 w-full flex ">
        <div className="flex w-full gap-4 my-4">
          <Button
            styleString="secondary"
            children="Reset Filters"
            onClick={handleResetFilters}
          />
          <Button
            styleString="primary"
            children="Apply Filters"
            onClick={handleApplyFilters}
            disabled={dateError ? true : false}
          />
        </div>
      </div>
    </div>
  );

  return (
    <SliderContainer
      show={show}
      setShow={setShow}
      title={
        <div className="flex items-center gap-2 pt-10">
          <AdjustmentsHorizontalIcon className="h-5 w-5" />{" "}
          <span className="text-xl font-medium">Advanced Filters</span>
        </div>
      }
      children={content}
    />
  );
};

export default FilterModal;
