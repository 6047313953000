import React from "react";
import { DateRangeOutlined } from "@mui/icons-material";
import {
  capitalizeString,
  formatDate,
  classNames,
} from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { TaskInstance } from "../../types/TaskInstance";
import StatusBadge from "../general-ui/StatusBadges";
import { returnTypeString } from "../../utils/Helpers";
import { useHome } from "../../hooks/useHome";
import RecurringTaskCard from "../cards/RecurringTaskCard";

type PageType = "life-safety" | "prev-maint" | "administrative";

const HomeRecurringTasks: React.FC = () => {
  const { tasks } = useHome();

  const columns = [
    {
      label: "Task",
      key: "title",
      render: (taskInstance: TaskInstance) => (
        <>
          <span className="hidden sm:inline-flex">{taskInstance.title}</span>
          <div className="flex w-full sm:hidden" key={taskInstance.date+taskInstance.parentTask._id}>
            <RecurringTaskCard taskInstance={taskInstance} />
          </div>
        </>
      ),
      className: "w-full max-w-0 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Status",
      key: "status",
      render: (taskInstance: TaskInstance) => (
        <span>{<StatusBadge status={taskInstance.status} />}</span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Type",
      key: "category",
      sortable: false,
      render: (taskInstance: TaskInstance) => (
        <>
          {capitalizeString(
            returnTypeString(taskInstance.parentTask.taskType as PageType)
          )}
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Frequency",
      key: "frequency",
      sortable: false,
      render: (taskInstance: TaskInstance) => (
        <>
          {taskInstance.recurrence
            ? capitalizeString(taskInstance.recurrence.frequency)
            : "N/A"}
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Due Date",
      key: "date",
      render: (taskInstance: TaskInstance) => (
        <span
          className={classNames(
            "flex items-center gap-1",
            taskInstance.status === "overdue"
              ? "text-reds-600"
              : "text-secondary-400"
          )}
        >
          <DateRangeOutlined style={{ fontSize: "1rem" }} />
          {formatDate(taskInstance.date, "MMM DD, YYYY", "utc")}
        </span>
      ),
      className: "hidden sm:table-cell",
    },

    {
      label: "Source",
      key: "source",
      render: (taskInstance: TaskInstance) => (
        <span>{capitalizeString(taskInstance.source)}</span>
      ),
      className: "hidden sm:table-cell",
    },
  ];
  return (
    <Table
      columns={columns}
      data={tasks}
      noDataMessage="No recurring tasks to display."
      paginationScheme="none"
    />
  );
};

export default HomeRecurringTasks;
