import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useFacility } from "../hooks/useFacility";
import moment from "moment-timezone";
import useFetchData from "../hooks/useFetchData";
import { WorkOrder } from "../types/WorkOrder";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchRecurringTableData,
  fetchWorkOrderData,
} from "../api/services/HomeService";
import { determineChangeType } from "../utils/FormatFunctions";
import { ClockIcon, PlusIcon } from "@heroicons/react/24/outline";
import { debounce } from "../utils/Helpers";
import { fetchFacilityUsers } from "../api/services/WorkOrderService";
import { User } from "../types/User";
import { TaskInstance } from "../types/TaskInstance";

interface RecurringTableData {
  recurringTasks: TaskInstance[];
}

interface Stat {
  name: string;
  stat: string | number;
  currentContent?: any;
  previousStat?: string | number;
  change?: string;
  changeType?: string;
  canView: boolean;
  icon?: React.ReactNode;
  color?: string;
  handleClick?: () => void;
}

interface WorkOrderData {
  workOrders: WorkOrder[];
}

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}

// Define the type for the context values
interface HomeContextType {
  currentRecurringTableData: RecurringTableData;
  currentWorkOrders: WorkOrderData;
  dateParams: DateParams;
  workOrderStats: Stat[];
  recurringTaskStats: Stat[];
  tasks: TaskInstance[];
  wo_filters: { [key: string]: string };
  rec_filters: { [key: string]: string };
  users: User[];
  setDateParams: React.Dispatch<React.SetStateAction<DateParams>>;
  handleReset: () => void;
  resetFilters: () => void;
  applyIndividualFilter: (
    key: string,
    value: string,
    filterType: "wo" | "rec"
  ) => void;
}

// Define the type for the provider props
interface HomeProviderProps {
  children: ReactNode;
}

// Create the context
export const HomeContext = createContext<HomeContextType | undefined>(
  undefined
);

// Create the provider component
export const HomeProvider = ({ children }: HomeProviderProps) => {
  const { selectedFacility } = useFacility();
  const navigate = useNavigate();
  const [dateParams, setDateParams] = useState<DateParams>({
    displayText: "last week",
    startDate: moment().subtract(1, "week").toDate(),
    endDate: moment().toDate(),
    selectedRange: "week",
  });

  // State management for the work orders
  const [workOrderData, setWorkOrderData] = useState<WorkOrderData>({
    workOrders: [],
  });

  const generateStatPath = useCallback(
    (path: string, status: string | string[], dateType: string) => {
      const formattedStartDate = moment(dateParams.startDate).format(
        "YYYY-MM-DD"
      );

      const formattedEndDate = moment(dateParams.endDate).format("YYYY-MM-DD");
      const filters: any = { status: status };
      if (dateType) {
        filters[`${dateType}From`] = formattedStartDate;
        filters[`${dateType}To`] = formattedEndDate;
      }

      return `/${path}?filters=${JSON.stringify(filters)}`;
    },
    [dateParams]
  );

  const handleNavigate = useCallback(
    (path: string, status: string | string[], dateType: string) => {
      const calculatedPath = generateStatPath(path, status, dateType);
      navigate(calculatedPath);
    },
    [generateStatPath]
  );

  // Data to be displayed in the widgets for the work orders
  const getInitialWorkOrderStats = useCallback(
    () => [
      {
        name: "Created",
        stat: 0,
        previousStat: 0,
        change: "0.00%",
        icon: <PlusIcon className="h-6 w-6 rounded-sm text-secondary-100 " />,
        changeType: "increase",
        canView: true,
        handleClick: () =>
          handleNavigate(
            "work-orders",
            ["incomplete", "complete", "closed", "unable"],
            "dateReported"
          ),
      },
      {
        name: "Avg. Time to Complete",

        stat: "0 days",
        previousStat: "0 days",
        icon: <ClockIcon className="h-6 w-6 rounded-sm text-secondary-100" />,
        change: "0.00%",
        changeType: "increase",
        canView: false,
      },
      {
        name: "Incomplete",
        stat: 0,
        previousStat: 0,
        change: "0.00%",
        changeType: "increase",
        canView: true,
        color: "bg-secondary-1000",
        handleClick: () => handleNavigate("work-orders", "incomplete", ""),
      },
      {
        name: "Completed",

        stat: 0,
        previousStat: 0,
        change: "0.00%",
        changeType: "increase",
        canView: true,
        color: "bg-accent-500",
        handleClick: () =>
          handleNavigate("work-orders", "complete", "dateCompleted"),
      },
      {
        name: "Closed",

        stat: 0,
        previousStat: 0,
        change: "0.00%",
        changeType: "increase",
        canView: true,
        color: "bg-accent-200",
        handleClick: () =>
          handleNavigate("work-orders", "closed", "dateClosed"),
      },
      {
        name: "Unable",

        stat: 0,
        previousStat: 0,
        change: "0.00%",
        changeType: "increase",
        canView: true,
        color: "bg-reds-300",
        handleClick: () => handleNavigate("work-orders", "unable", ""),
      },
    ],
    [handleNavigate]
  );

  const [workOrderStats, setWorkOrderStats] = useState<Stat[]>(
    getInitialWorkOrderStats()
  );

  useEffect(() => {
    setWorkOrderStats(getInitialWorkOrderStats());
  }, [getInitialWorkOrderStats]);

  // State management for the recurring tasks
  const [currentRecurringTableData, setCurrentRecurringTableData] =
    useState<RecurringTableData>({
      recurringTasks: [],
    });

  // Data to be displayed in the widgets for the recurring tasks
  const [recurringStats, setRecurringStats] = useState<Stat[]>([
    {
      name: "Incomplete",
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
      color: "bg-secondary-1000",
      handleClick: () => handleNavigate("tasks", "incomplete", ""),
    },
    {
      name: "Completed",

      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
      color: "bg-accent-500",
      handleClick: () =>
        handleNavigate("tasks", ["complete", "closed"], "dateCompleted"),
    },
    {
      name: "Closed",

      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
      color: "bg-accent-200",
      handleClick: () => handleNavigate("tasks", "closed", "dateClosed"),
    },
    {
      name: "Overdue",

      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
      color: "bg-reds-500",
      handleClick: () => handleNavigate("tasks", "overdue", ""),
    },
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const wo_filters = searchParams.get("wo_filters") || "";
  const rec_filters = searchParams.get("rec_filters") || "";

  const applyIndividualFilter = (
    key: string,
    value: string,
    filterType: "wo" | "rec"
  ) => {
    const filterParam = filterType === "wo" ? "wo_filters" : "rec_filters";
    const currentFilters: { [key: string]: string } =
      filterType === "wo"
        ? wo_filters
          ? JSON.parse(wo_filters)
          : {}
        : rec_filters
        ? JSON.parse(rec_filters)
        : {};

    const updatedFilters = { ...currentFilters, [key]: value };

    if (value === "") {
      delete updatedFilters[key];
    }

    setSearchParams({ [filterParam]: JSON.stringify(updatedFilters) });
  };
  const resetFilters = () => {
    setSearchParams({});
  };

  const [users, setUsers] = useState([]);
  // Fetch facility users for selector
  const fetchUsers = useCallback(async () => {
    if (!selectedFacility) return;
    const returnedUsers = await fetchFacilityUsers(selectedFacility);
    setUsers(returnedUsers);
  }, [selectedFacility]);

  // Load the work order data
  const loadWorkOrderData = useCallback(async () => {
    try {
      const filterObject = wo_filters ? JSON.parse(wo_filters) : {};
      const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
      const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");

      if (startDate === "Invalid date" || endDate === "Invalid date") return;

      const { workOrders, workOrderCounts } = await fetchWorkOrderData(
        selectedFacility,
        dateParams,
        filterObject
      );

      const {
        createdWorkOrders,
        incompleteWorkOrders,
        completeWorkOrders,
        closedWorkOrders,
        unableWorkOrders,
        timeWorkOrders,
      } = workOrderCounts;

      setWorkOrderData({
        workOrders,
      });
      setWorkOrderStats((prevStats) => [
        {
          ...prevStats[0],
          stat: createdWorkOrders.current,
          currentContent: createdWorkOrders.current,
          previousStat: createdWorkOrders.previous,
          change: createdWorkOrders.change,
          changeType: determineChangeType(createdWorkOrders.change),
        },
        {
          ...prevStats[1],
          stat: `${timeWorkOrders.current} Days`,
          previousStat: `${timeWorkOrders.previous} Days`,
          change: timeWorkOrders.change,
          changeType: determineChangeType(timeWorkOrders.change),
        },
        {
          ...prevStats[2],
          stat: incompleteWorkOrders.current,
          currentContent: incompleteWorkOrders.current,
          previousStat: incompleteWorkOrders.previous,
          change: incompleteWorkOrders.change,
          changeType: determineChangeType(incompleteWorkOrders.change),
        },
        {
          ...prevStats[3],
          stat: completeWorkOrders.current,
          currentContent: completeWorkOrders.current,
          previousStat: completeWorkOrders.previous,
          change: completeWorkOrders.change,
          changeType: determineChangeType(completeWorkOrders.change),
        },
        {
          ...prevStats[4],
          stat: closedWorkOrders.current,
          currentContent: closedWorkOrders.current,
          previousStat: closedWorkOrders.previous,
          change: closedWorkOrders.change,
          changeType: determineChangeType(closedWorkOrders.change),
        },
        {
          ...prevStats[5],
          stat: unableWorkOrders.current,
          currentContent: unableWorkOrders.current,
          previousStat: unableWorkOrders.previous,
          change: unableWorkOrders.change,
          changeType: determineChangeType(unableWorkOrders.change),
        },
      ]);
    } catch (error) {
      console.error("Error fetching work order data:", error);
    }
  }, [selectedFacility, dateParams, wo_filters]);

  // Load the recurring table data
  const loadRecurringData = useCallback(
    debounce(async () => {
      try {
        const filterObject = rec_filters ? JSON.parse(rec_filters) : {};
        const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
        const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");

        if (startDate === "Invalid date" || endDate === "Invalid date") return;

        const { recurringTasks, taskCounts } = await fetchRecurringTableData(
          selectedFacility,
          dateParams,
          filterObject
        );

        setCurrentRecurringTableData({
          recurringTasks,
        });
        setRecurringStats((prevStats) => [
          {
            ...prevStats[0],
            stat: taskCounts.incompleteRecurring.current,
            previousStat: taskCounts.incompleteRecurring.previous,
            change: taskCounts.incompleteRecurring.change,
            changeType: determineChangeType(
              taskCounts.incompleteRecurring.change
            ),
          },
          {
            ...prevStats[1],
            stat: taskCounts.completeRecurring.current,
            previousStat: taskCounts.completeRecurring.previous,
            change: taskCounts.completeRecurring.change,
            changeType: determineChangeType(
              taskCounts.completeRecurring.change
            ),
          },
          {
            ...prevStats[2],
            stat: taskCounts.closedRecurring.current,
            previousStat: taskCounts.closedRecurring.previous,
            change: taskCounts.closedRecurring.change,
            changeType: determineChangeType(taskCounts.closedRecurring.change),
          },
          {
            ...prevStats[3],
            stat: taskCounts.overdueRecurring.current,
            previousStat: taskCounts.overdueRecurring.previous,
            change: taskCounts.overdueRecurring.change,
            changeType: determineChangeType(taskCounts.overdueRecurring.change),
          },
        ]);
      } catch (error) {
        console.error("Error fetching recurring table data:", error);
      }
    }, 300),
    [selectedFacility, dateParams, rec_filters]
  );

  useEffect(() => {
    if (selectedFacility) {
      loadRecurringData();
      loadWorkOrderData();
      fetchUsers();
    }
  }, [selectedFacility, loadRecurringData, loadWorkOrderData]);

  const handleReset = () => {
    setDateParams({
      displayText: "Week",
      startDate: moment().subtract(1, "week").toDate(),
      endDate: moment().toDate(),
      selectedRange: "week",
    });
  };


  return (
    <HomeContext.Provider
      value={{
        currentRecurringTableData,
        currentWorkOrders: workOrderData,
        workOrderStats,
        recurringTaskStats: recurringStats,
        tasks: currentRecurringTableData.recurringTasks,
        dateParams,
        wo_filters: wo_filters ? JSON.parse(wo_filters ?? {}) : {},
        rec_filters: rec_filters ? JSON.parse(rec_filters ?? {}) : {},
        users,
        setDateParams,
        handleReset,
        applyIndividualFilter,
        resetFilters,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
