import apiClient from "../apiClient";

export const fetchFiles = async (selectedFacility: string) => {
  try {
    const response = await apiClient.get(`/building/files/${selectedFacility}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Document or Policies files:", error);
    return [];
  }
};

export const fetchKiosks = async (selectedFacility: string) => {
  try {
    const response = await apiClient.get(
      `/building/kiosks/${selectedFacility}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching kiosks:", error);
    return [];
  }
};

export const fetchMaps = async (selectedFacility: string) => {
  try {
    const response = await apiClient.get(
      `/building/get-maps/${selectedFacility}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching maps:", error);
    return [];
  }
};

export const createMap = async (facilityId: string, mapData: any) => {
  try {
    const response = await apiClient.post(
      `/building/create-map/${facilityId}`,
      {
        mapData,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating map:", error);
  }
};
export const updateMap = async (mapId: string, mapData: any) => {
  try {
    const response = await apiClient.post(`/building/update-map/${mapId}`, {
      mapData,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating map:", error);
  }
};

export const deleteMap = async (id: string) => {
  try {
    await apiClient.delete(`/building/delete-map/${id}`);
  } catch (error) {
    console.error("Error deleting map:", error);
  }
};

export const createKiosk = async (kioskData: any) => {
  try {
    const response = await apiClient.post(`/building/create/kiosk`, {
      ...kioskData,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating kiosk:", error);
  }
};

export const updateKiosk = async (kioskData: any) => {
  try {
    const response = await apiClient.post(`/building/edit/kiosk`, {
      kioskData,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating kiosk:", error);
  }
};

export const updateKioskStatus = async (kioskId: string, status: string) => {
  try {
    const response = await apiClient.put(`/building/update/kiosk/${kioskId}`, {
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating kiosk status:", error);
  }
};

export const fetchFile = async (fileId: string) => {
  try {
    const response = await apiClient.get(`/building/files/fetch/${fileId}`);
    return response;
  } catch (error) {
    console.error("Error fetching file:", error);
    throw error;
  }
};

export const deleteFile = async (fileId: string) => {
  try {
    await apiClient.delete(`/building/files/delete/${fileId}`);
  } catch (error) {
    console.error("Error deleting file:", error);
  }
};

export const uploadFile = async (
  formData: FormData,
  fileType: "document" | "policy",
  facilityId: string
) => {
  try {
    const response = await apiClient.post(
      `building/upload/${fileType}/${facilityId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
