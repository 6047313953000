import React from "react";
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import { TimerOutlined } from "@mui/icons-material";

interface WidgetProps {
  color: string;
  status: string;
  count: number | string;
  icon?: React.ReactNode;
  percentage?: string;
  changeType?: string;
  comparison?: string | number;
  variant?: "default" | "detailed";
  onClick?: () => void;
  interval?: string;
}

const Widget: React.FC<WidgetProps> = ({
  color,
  status,
  count,
  icon,
  percentage,
  changeType,
  comparison,
  variant = "default",
  interval = "",
  onClick = null,
}) => {
  if (variant === "detailed") {
    return (
      <div
        onClick={() => onClick && onClick()}
        className={`flex flex-col flex-1 sm:flex-row h-fit gap-3 sm:pl-4 cursor-pointer`}
      >
        <div className="flex gap-3">

          <div className="flex items-center justify-center w-9 h-9 my-auto bg-secondary-1200 border border-secondary-1000 rounded-lg">
            {icon}
          </div>
          <span className="text-sm sm:hidden">{status}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm hidden sm:block">{status}</span>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              <span className="font-semibold text-lg">{count}</span>
              {percentage !== "invalid" && (
                <span className="text-sm flex items-center gap-1">
                  {percentage}
                  {changeType === "increase" ? (
                    <ArrowTrendingUpIcon className="w-4 h-4 text-accent-500" />
                  ) : (
                    <ArrowTrendingDownIcon className="w-4 h-4 text-reds-500" />
                  )}
                </span>
              )}
            </div>
            {comparison && (
              <span className="text-xs font-light">
                Compared to {comparison} {interval}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() => onClick && onClick()}
      className="sm:flex flex-col p-4 border border-secondary-1000 bg-secondary-1200 rounded-lg w-full cursor-pointer"
    >
      <span className="flex items-center gap-2 text-sm">
        <div className={`relative w-3 h-3 rounded-full ${color}`}>
          <div
            className={`absolute animate-pulse -top-0.5 -left-0.5 h-4 w-4 rounded-full opacity-15 ${color}`}
          ></div>
        </div>
        {status}
      </span>
      <div className="flex gap-4 items-center">
        <span className="font-semibold text-lg">{count}</span>
        {percentage && percentage !== "invalid" && changeType && (
          <span className="text-sm flex items-center gap-1">
            {percentage}
            {changeType === "increase" ? (
              <ArrowTrendingUpIcon className="w-4 h-4 text-accent-500 " />
            ) : (
              <ArrowTrendingDownIcon className="w-4 h-4 text-reds-500" />
            )}
          </span>
        )}
      </div>
      {comparison !== undefined && (
        <span className="text-xs font-light">
          Compared to {comparison} {interval}
        </span>
      )}
    </div>
  );
};

export default Widget;
