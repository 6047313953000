import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFacility } from "../hooks/useFacility";
import {
  createKiosk,
  createMap,
  deleteFile,
  deleteMap,
  fetchFile,
  fetchFiles,
  fetchKiosks,
  fetchMaps,
  updateKiosk,
  updateKioskStatus,
  updateMap,
  uploadFile,
} from "../api/services/BuildingService";
import { Kiosk } from "../types/Kiosk";
import { File } from "../types/File";
import { Map } from "../types/Map";

type FileType = "document" | "policy";
type Statuses = "active" | "inactive";

// Define the type for the context values
interface BuildingContextType {
  loading: boolean;
  showState: {
    showMapCreate: boolean;
    showFileAdd: boolean;
    showKioskAdd: boolean;
    showDelete: boolean;
    showDeactivate: boolean;
    showReactivate: boolean;
    showDeleteFile: boolean;
  };
  updateShowState: (modal: string, value: boolean) => void;
  maps: Map[];
  selectedMap: Map | null;
  setSelectedMap: (map: Map | null) => void;
  handleShowMap: (map: Map | null) => void;
  handleDeleteMap: (map: Map) => void;
  onSubmitMap: (data: any) => void;
  kiosks: Kiosk[];
  selectedKiosk: Kiosk | null;
  handleShowKiosk: (kiosk: Kiosk | null) => void;
  handleCreateKiosk: (data: any) => void;
  handleKioskDeactivate: (kiosk: Kiosk) => void;
  handleKioskReactivate: (kiosk: Kiosk) => void;
  handleUpdateKioskStatus: (status: Statuses) => void;
  docs: { documents: File[]; policies: File[] };
  handleFileView: (file: File) => void;
  handleAddFile: (type: FileType) => void;
  handleDeleteFileClick: (file: File) => void;
  loadingFile: boolean;
  fileType: FileType | null;
  selectedFile: File | null;
  handleCreateFile: (data: any) => void;
  handleDeleteFile: () => void;
}

// Define the type for the provider props
interface BuildingProviderProps {
  children: ReactNode;
}

// Create the context
export const BuildingContext = createContext<BuildingContextType | undefined>(
  undefined
);

// Create the provider component
export const BuildingProvider = ({ children }: BuildingProviderProps) => {
  const { selectedFacility } = useFacility();
  const [loading, setLoading] = useState(false);
  const [showState, setShowState] = useState({
    showMapCreate: false,
    showFileAdd: false,
    showKioskAdd: false,
    showDelete: false,
    showDeactivate: false,
    showReactivate: false,
    showDeleteFile: false,
  });

  const updateShowState = (modal: string, value: boolean) => {
    setShowState((prev) => ({ ...prev, [modal]: value }));
  };

  const resetShowState = () => {
    setShowState({
      showMapCreate: false,
      showFileAdd: false,
      showKioskAdd: false,
      showDelete: false,
      showDeactivate: false,
      showReactivate: false,
      showDeleteFile: false,
    });
  };

  const [maps, setMaps] = useState<Map[]>([]);

  const loadMaps = useCallback(async () => {
    if (!selectedFacility) return;
    const mapResponse = await fetchMaps(selectedFacility);
    setMaps(mapResponse);
  }, [selectedFacility]);
  const [selectedMap, setSelectedMap] = useState<Map | null>(null);

  const handleShowMap = (map: Map | null) => {
    setSelectedMap(map);
    updateShowState("showMapCreate", true);
  };

  const onSubmitMap = async (data: any) => {
    try {
      if (!selectedMap || selectedMap === undefined) {
        await createMap(selectedFacility, data);
        updateShowState("showMapCreate", false);
      } else {
        await updateMap(selectedMap._id, data);
        updateShowState("showMapCreate", false);
      }
    } catch (error) {
      console.error("Error sending map data to the backend", error);
    }
    loadMaps();
    updateShowState("showMapCreate", false);
  };

  const handleDeleteMap = async (map: Map) => {
    await deleteMap(map._id);
    loadMaps();
    updateShowState("showDelete", false);
  };

  const handleShowKiosk = (kiosk: Kiosk | null) => {
    setSelectedKiosk(kiosk);
    updateShowState("showKioskAdd", true);
  };

  const handleCreateKiosk = async (data: any) => {
    console.log("data", data);
    try {
      if (!selectedKiosk || selectedKiosk === undefined) {
        await createKiosk(data);
      } else {
        await updateKiosk(data);
      }

      loadKiosks();
      resetShowState();
    } catch (error) {
      console.error("Error creating kiosk", error);
    }
  };

  const handleKioskReactivate = (kiosk: Kiosk) => {
    setSelectedKiosk(kiosk);
    updateShowState("showReactivate", true);
  };

  const handleKioskDeactivate = (kiosk: Kiosk) => {
    console.log("kiosk", kiosk);
    setSelectedKiosk(kiosk);
    updateShowState("showDeactivate", true);
  };

  const handleUpdateKioskStatus = async (status: Statuses) => {
    try {
      if (!selectedKiosk) return;
      await updateKioskStatus(selectedKiosk._id, status);
      resetShowState();
      loadKiosks();
    } catch (error) {
      console.error("Error deactivating kiosk", error);
    }
  };

  const [documents, setDocuments] = useState<{
    documents: File[];
    policies: File[];
  }>({ documents: [], policies: [] });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<FileType | null>(null);

  const handleAddFile = (type: FileType) => {
    setFileType(type);
    updateShowState("showFileAdd", true);
  };

  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const handleFileView = async (file: File) => {
    setLoadingFile(true);
    try {
      const response = await fetchFile(file._id);

      if (response.status === 200) {
        window.open(response.data.url, "_blank");
      } else {
        console.error("Error fetching file:", response);
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    } finally {
      setLoadingFile(false);
    }
  };

  const handleDeleteFileClick = (file: File) => {
    setSelectedFile(file);
    updateShowState("showDeleteFile", true);
  };

  const handleCreateFile = async (data: any) => {
    const file = data.file;
    const formData = new FormData();
    formData.append("file", file[0]);
    
    if (!fileType) return;

    try {
      await uploadFile(formData, fileType, selectedFacility);
      loadFiles();
      resetShowState();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDeleteFile = async () => {
    if (!selectedFile) return;
    await deleteFile(selectedFile._id);
    loadFiles();
    resetShowState();
  };

  const [kiosks, setKiosks] = useState<Kiosk[]>([]);
  const [selectedKiosk, setSelectedKiosk] = useState<Kiosk | null>(null);

  const loadKiosks = useCallback(async () => {
    if (!selectedFacility) return;
    const kioskResponse = await fetchKiosks(selectedFacility);
    setKiosks(kioskResponse);
  }, [selectedFacility]);

  const loadFiles = useCallback(async () => {
    if (!selectedFacility) return;
    const fileResponse = await fetchFiles(selectedFacility);
    setDocuments(fileResponse);
  }, [selectedFacility]);

  useEffect(() => {
    if (!selectedFacility) return;
    loadKiosks();
    loadFiles();
    loadMaps();
    setLoading(false);
  }, [selectedFacility]);

  return (
    <BuildingContext.Provider
      value={{
        loading,
        showState,
        updateShowState,
        maps,
        selectedMap,
        setSelectedMap,
        handleShowMap,
        handleDeleteMap,
        onSubmitMap,
        kiosks,
        selectedKiosk,
        handleShowKiosk,
        handleCreateKiosk,
        handleKioskDeactivate,
        handleKioskReactivate,
        handleUpdateKioskStatus,
        docs: documents,
        handleAddFile,
        handleDeleteFileClick,
        handleFileView,
        loadingFile,
        fileType,
        selectedFile,
        handleCreateFile,
        handleDeleteFile,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};
