import React from "react";

interface SpinnerProps {
  size?: "sm" | "md" | "lg" | "xl";
}

const sizeClasses = {
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
  xl: "h-12 w-12",
};

const Spinner = ({ size = "md" }: SpinnerProps) => {
  return (
    <div className="flex items-center justify-center space-x-2 py-6">
      <div
        className="w-4 h-4 bg-secondary-100  rounded-full animate-bounce"
        style={{ animationDelay: "0ms", animationDuration: ".5s" }}
      />
      <div
        className="w-4 h-4 bg-secondary-400  rounded-full animate-bounce" 
        style={{ animationDelay: "150ms", animationDuration: ".5s" }}
      />
      <div
        className="w-4 h-4 bg-accent-500 rounded-full animate-bounce"
        style={{ animationDelay: "300ms", animationDuration: ".5s" }}
      />
    </div>
  );
};

export default Spinner;
