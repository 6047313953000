import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFacility } from "../hooks/useFacility";
import { debounce } from "../utils/Helpers";
import { RecurringTask } from "../types/RecurringTask";
import {
  createRecurringTask,
  deleteRecurringTask,
  fetchDocuments,
  fetchMaps,
  fetchRecurringTasks,
  fetchTemplates,
  fetchVendors,
  updateRecurringTask,
} from "../api/services/RecurringTaskService";
import { TaskTemplate } from "../types/TaskTemplate";
import { File } from "../types/File";
import { Map } from "../types/Map";
import { Vendor } from "../types/Vendor";
import { NewRecurringTaskFormData } from "../updatedcomponents/forms/RecurringTaskForm";
import { useToast } from "../hooks/useToast";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

// Define the type for the context values
interface TaskSchedulerContextType {
  tasks: RecurringTask[];
  selectedTask: RecurringTask | null;
  templates: TaskTemplate[];
  attachments: File[];
  maps: Map[];
  vendors: Vendor[];
  showModalState: Record<string, boolean>;

  updateShowModalState: (modal: string, value: boolean) => void;
  isLoading: boolean;
  setSearchTerm: (searchTerm: string) => void;
  createTask: (task: NewRecurringTaskFormData) => void;
  updateTask: (taskId: string, task: NewRecurringTaskFormData) => void;
  handleDeleteClick: (task: RecurringTask) => void;
  handleDeleteTask: (task: RecurringTask) => void;
  handleViewClick: (task: RecurringTask) => void;
}

// Define the type for the provider props
interface TaskSchedulerProviderProps {
  children: ReactNode;
}

// Create the context
export const TaskSchedulerContext = createContext<
  TaskSchedulerContextType | undefined
>(undefined);

// Create the provider component
export const TaskSchedulerProvider = ({
  children,
}: TaskSchedulerProviderProps) => {
  const { selectedFacility } = useFacility();
  const { showResponse } = useToast();
  const [tasks, setTasks] = useState<RecurringTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<RecurringTask | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [showModalState, setShowModalState] = useState({
    onboarding: false,
    createTask: false,
    deleteTask: false,
    viewTask: false,
  });

  const [templates, setTemplates] = useState<TaskTemplate[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [maps, setMaps] = useState<Map[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const updateShowModalState = (modal: string, value: boolean) => {
    setShowModalState((prev) => ({ ...prev, [modal]: value }));
  };

  const loadTasks = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        if (!selectedFacility) {
          return;
        }
        const data = await fetchRecurringTasks(selectedFacility, searchTerm);
        const template = await fetchTemplates();
        const attachments = await fetchDocuments(selectedFacility);
        const maps = await fetchMaps(selectedFacility);
        const vendors = await fetchVendors(selectedFacility);
        setTasks(data);
        setTemplates(template);
        setAttachments(attachments);
        setMaps(maps);
        setVendors(vendors);
        setIsLoading(false);

      } catch (error) {
        setTasks([]);
        setIsLoading(false);
        setTemplates([]);
        console.error("Error fetching vendors:", error);
        setIsLoading(false);

      }
    }, 100),
    [searchTerm, selectedFacility]
  );

  useEffect(() => {
    if (!selectedFacility) return;
    loadTasks();
  }, [searchTerm, selectedFacility]);

  const createTask = async (formData: NewRecurringTaskFormData) => {
    try {
      await createRecurringTask(formData);
      loadTasks();
      showResponse(
        "Task Created",
        "Task has been successfully Created",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error creating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error creating the task:", error);
    }
  };

  const updateTask = async (
    taskId: string,
    formData: NewRecurringTaskFormData
  ) => {
    try {
      const updatedTask = await updateRecurringTask(taskId, formData);
      setSelectedTask(updatedTask);
      loadTasks();
      updateShowModalState("viewTask", true);
      showResponse(
        "Task Updated",
        "Task has been successfully updated",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error updating task:", error);
    }
  };

  const handleDeleteClick = (task: RecurringTask) => {
    setSelectedTask(task);
    updateShowModalState("deleteTask", true);
  };

  const handleDeleteTask = async () => {
    try {
      if (!selectedTask) return;
      await deleteRecurringTask(selectedTask._id);
      loadTasks();
      updateShowModalState("deleteTask", false);
      updateShowModalState("viewTask", false);
      showResponse(
        "Task Deleted",
        "Task has been successfully deleted",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error deleting the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error deleting task:", error);
    }
  };

  const handleViewClick = (task: RecurringTask) => {
    setSelectedTask(task);
    updateShowModalState("viewTask", true);
  };

  return (
    <TaskSchedulerContext.Provider
      value={{
        tasks,
        templates,
        attachments,
        maps,
        vendors,
        selectedTask,
        showModalState,
        updateShowModalState,
        isLoading,
        setSearchTerm,
        createTask,
        updateTask,
        handleDeleteClick,
        handleDeleteTask,
        handleViewClick,
      }}
    >
      {children}
    </TaskSchedulerContext.Provider>
  );
};
