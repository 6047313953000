import React, { useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close, DescriptionOutlined, NoteOutlined } from "@mui/icons-material";
import { formatDate } from "../../utils/FormatFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import Button from "../general/Button";
interface FieldValues {
  noteText: string;
}
const TaskNotes: React.FC = () => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    selectedWorkOrder,
    modalShowState,
    updateModalShowState,
    handleAddNotes,
  } = useWorkOrder();

  const schema = yup.object().shape({
    noteText: yup
      .string()
      .required("Note is required")
      .max(150, "Note must be less than 150 characters"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);
  return (
    <div className="">
      <div className="flex flex-col">
        <h3 className="text-secondary-100 text-md w-full max-w-3xl truncate">
          {selectedWorkOrder ? selectedWorkOrder.title : ""}
        </h3>
        <span className="text-xs text-secondary-300">
          {formatDate(selectedWorkOrder?.dateReported, "YYYY-MM-DD h:mm:ss a")}
        </span>
      </div>
      <div className="mt-2 max-h-60 overflow-y-auto text-left bg-secondary-50 border-secondary-1000 border p-2 rounded-md">
        {selectedWorkOrder && selectedWorkOrder.notes.length !== 0 ? (
          <ul>
            {selectedWorkOrder.notes.map((note, index) => (
              <li key={index}>
                <span className="text-sm text-secondary-300">
                  {note.userId
                    ? `${note.userId.firstname.charAt(0)}. ${
                        note.userId.lastname
                      }`
                    : "Unknown user"}
                </span>
                {": "}
                {
                  <span className="text-sm text-secondary">{note.text}</span>
                }{" "}
                {
                  <div
                    className=" text-secondary-400"
                    style={{ fontSize: "10px" }}
                  >
                    {formatDate(note.timestamp, "YYYY-MM-DD h:mm:ss a")}
                  </div>
                }
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex flex-col text-secondary-700  justify-center items-center">
            <NoteOutlined />
            <span className="text-xs  text-center">No previous notes</span>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(handleAddNotes)}>
        <div className="mt-2 text-left flex flex-col">
          <textarea
            {...register("noteText")}
            rows={3}
            className={` resize-none block w-full rounded-md border-0  text-secondary-100 shadow-sm placeholder:text-secondary-400 focus:ring-2 focus:ring-inset  ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
              errors.noteText
                ? "ring-2 ring-reds-500"
                : "ring-1 ring-secondary-1000"
            }`}
            placeholder="Place note here..."
          />
          {errors.noteText && (
            <p className="text-xs text-reds-500">{errors.noteText.message}</p>
          )}
        </div>

        <div className="mt-5 sm:mt-6 flex gap-2 w-full">
          <Button
            type="button"
            props={{ ref: cancelButtonRef }}
            onClick={() => updateModalShowState("showNotes", false)}
            children="Cancel"
            styleString="secondary"
          />
          <Button type="submit" children="Save Note" />
        </div>
      </form>
    </div>
  );
};

export default TaskNotes;
