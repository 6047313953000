import {
  BuildOutlined,
  CalendarMonthOutlined,
  PlumbingOutlined,
} from "@mui/icons-material";
import React, { useContext, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { ActiveShape } from "recharts/types/util/types";

type RenderProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  payload: any;
  fill: string;
  percent: number;
  value: number;
};

interface ProgressWheelProps {
  type: "recurring" | "work-orders";
  values: {
    closed: number;
    complete: number;
    incomplete: number;
    unable?: number;
    overdue?: number;
  };
}

const ProgressWheel: React.FC<ProgressWheelProps> = ({ type, values }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const navigate = useNavigate();

  const onPieClick = (data: any) => {
    let path;
    if (type === "recurring") {
      path = "/tasks";
    } else {
      path = "/work-orders";
    }

    switch (data.payload.payload.name) {
      case "Closed":
        navigate("/logs");
        break;
      case "Complete":
        navigate(
          `${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`
        );
        break;
      case "Incomplete":
        navigate(
          `${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`
        );
        break;
      case "Unable":
        navigate(
          `${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`
        );
        break;
      case "Overdue":
        navigate(
          `${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`
        );
        break;
      default:
        console.log("No Task");
    }
  };

  const { closed, complete, incomplete, unable, overdue } = values;


  const data = [
    ...(closed > 0
      ? [{ name: "Closed", value: closed, color: "#004643" }]
      : []),
    ...(complete > 0
      ? [{ name: "Complete", value: complete, color: "#00B0A8" }]
      : []),
    ...(incomplete > 0
      ? [{ name: "Incomplete", value: incomplete, color: "#B8B7B7" }]
      : []),
    ...(unable && unable > 0
      ? [{ name: "Unable", value: unable, color: "#8D001C" }]
      : []),
    ...(overdue && overdue > 0
      ? [{ name: "Overdue", value: overdue, color: "#8D001C" }]
      : []),
  ];

  const totalCount = incomplete + complete + (unable ?? 0) + (overdue ?? 0) + (closed ?? 0);

  let title;
  let icon;
  switch (type) {
    case "recurring":
      title = "Recurring Tasks";
      icon = <CalendarMonthOutlined className="text-secondary-100 flex item" />;
      break;
    case "work-orders":
      title = "Work Orders";
      icon = <BuildOutlined className="text-secondary-100" />;
      break;
    default:
      title = "No Tasks";
  }

  return (
    <ResponsiveContainer width={"100%"} height={"100%"} minHeight={350}>
      <PieChart width={244} height={244}>
        <Pie
          activeIndex={activeIndex}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={140}
          dataKey="value"
          onMouseEnter={onPieEnter}
          onClick={onPieClick}
          className="cursor-pointer"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend
          verticalAlign="bottom"
          align="center"
          layout="horizontal"
          iconType="circle"
          wrapperStyle={{ fontSize: "12px" }}
        />
        <foreignObject
          x="50%"
          y="50%"
          width="100"
          height="100"
          transform="translate(-50, -50)"
        >
          <div className="flex flex-col items-center justify-center w-full h-full ">
            <span className="text-secondary-400">Total Orders</span>
            <span className="font-semibold text-3xl">{totalCount}</span>
          </div>
        </foreignObject>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ProgressWheel;
