import React, { useEffect, useRef, useCallback, useState } from "react";
import Spinner from "../general/Spinner";

interface InfiniteScrollProps {
  next: () => void;
  hasMore: boolean;
  loader?: React.ReactNode;
  refreshFunction: () => void;
  pullDownToRefresh?: boolean;
  pullDownToRefreshThreshold?: number;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  next,
  hasMore,
  refreshFunction,
  pullDownToRefresh = false,
  pullDownToRefreshThreshold = 100,
  className,
  children,
  disabled = true,
  loading = false,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const pullDownRef = useRef<HTMLDivElement>(null);
  const startY = useRef<number>(0);
  const currentY = useRef<number>(0);
  const isPullingDown = useRef<boolean>(false);
  const [bottomReached, setBottomReached] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (scrollRef.current && !disabled) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 150 &&
        hasMore &&
        !bottomReached
      ) {
        setBottomReached(true);
        next();
      } else if (scrollTop + clientHeight < scrollHeight - 150) {
        setBottomReached(false);
      }
    }
  }, [hasMore, next, disabled, bottomReached]);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (pullDownToRefresh && !disabled && scrollRef.current?.scrollTop === 0) {
      startY.current = e.touches[0].clientY;
      isPullingDown.current = true;
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isPullingDown.current && !disabled) {
      currentY.current = e.touches[0].clientY;
      const distance = Math.min(currentY.current - startY.current, 50);
      if (distance > 0) {
        if (pullDownRef.current) {
          pullDownRef.current.style.paddingTop = `${distance}px`;
        }
      }
    }
  };

  const handleTouchEnd = () => {
    if (isPullingDown.current && !disabled) {
      isPullingDown.current = false;
      if (pullDownRef.current) {
        if (currentY.current - startY.current >= pullDownToRefreshThreshold) {
          setIsRefreshing(true);
          refreshFunction();
          setTimeout(() => {
            setIsRefreshing(false);
          }, 1000); // Adjust the timeout duration as needed
        }
        pullDownRef.current.style.paddingTop = "0px";
      }
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement && !disabled && !loading) {
      scrollElement.addEventListener("scroll", handleScroll, {
        passive: false,
      });
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, disabled]);

  return (
    <div
      ref={scrollRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={`relative ${className}`}
    >
      {pullDownToRefresh && (
        <div
          ref={pullDownRef}
          className="duration-300 ease-in-out bg-secondary-1000"
        >
          {isRefreshing && <Spinner size="lg" />}
        </div>
      )}
      {children}
      {!disabled && hasMore && loading && bottomReached && (
        <Spinner size="lg" />
      )}
    </div>
  );
};

export default InfiniteScroll;
