import React, { useState } from "react";
import axios from "axios";
import { Person } from "@mui/icons-material";
import { User, UserType } from "../../types/User";
import { capitalizeString } from "../../utils/FormatFunctions";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { useAuth } from "../../hooks/useAuth";
import { UserIcon } from "@heroicons/react/24/solid";
import Button from "../general/Button";

interface WorkOrderListPrintProps {
  showPrintWorkOrders: boolean;
  setShowWorkOrderPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkOrderListPrint: React.FC<WorkOrderListPrintProps> = ({
  setShowWorkOrderPrint,
}) => {
  const { currentUser } = useAuth();
  const { users } = useWorkOrder();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleUserCheckboxChange = (userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handlePrintList = async () => {
    if (selectedUsers.length === 0) {
      console.log("No users selected.");
      return;
    }

    try {
      const response = await axios.post(
        "/api/work-order/to-do/print",
        { userIds: selectedUsers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "arraybuffer",
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      window.open(url, "work-orders");
    } catch (error) {
      console.error("Error fetching work orders:", error);
    }
  };
  let usersToSelect: User[] = [currentUser];

  currentUser.access !== "worker" && usersToSelect.push(...users);

  return (
    <div className="h-full px-5 flex flex-col justify-between">
      <div>
        <fieldset>
          <legend className="text-base font-semibold leading-6 text-gray-700">
            Available Members
          </legend>
          <div className="mt-4 ">
            {usersToSelect.map((user, userIdx) => (
              <div key={userIdx} className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`user-${user._id}`}
                    className="select-none text-secondary"
                  >
                    <span className="flex items-center gap-4">
                      {user.avatar ? (
                        <img
                          className="h-7 w-7 rounded-full flex"
                          src={user.avatar}
                          alt=""
                        />
                      ) : (
                        <span>
                          <UserIcon className="p-1 h-7 w-7 rounded-full bg-secondary-900 text-primary" />
                        </span>
                      )}
                      <span className="text-base text-secondary font-normal">
                        {capitalizeString(user.firstname)}{" "}
                        {capitalizeString(user.lastname)}
                      </span>
                    </span>
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    id={`user-${user._id}`}
                    name={`user-${user._id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-accent-500 focus:ring-accent-500"
                    value={user._id}
                    checked={selectedUsers.includes(user._id)}
                    onChange={() => handleUserCheckboxChange(user._id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <div className="flex my-4 border-t border-gray-200 pt-4 gap-4 -mx-6 px-4">
        <Button
          styleString="secondary"
          children="Cancel"
          onClick={() => setShowWorkOrderPrint(false)}
          overrideStyles="flex-1"
        />
        <Button
          styleString="primary"
          children="Print"
          onClick={handlePrintList}
          overrideStyles="flex-1"
        />
       
      </div>
    </div>
  );
};

export default WorkOrderListPrint;
