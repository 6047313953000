import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Check, Person } from "@mui/icons-material";
import { classNames, getFirstInitial } from "../../utils/FormatFunctions";
import { User } from "../../types/User";
import { WorkOrder } from "../../types/WorkOrder";
import { TaskInstance } from "../../types/TaskInstance";
import { UserIcon } from "@heroicons/react/24/outline";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useInteractions,
  useRole,
  useDismiss,
  FloatingFocusManager,
  useTransitionStyles,
} from "@floating-ui/react";

interface AssignmentSelectorProps {
  users: User[];
  selectedUser: User | null;
  handleChange: (selectedUser: string | null) => void;
}

const AssignmentSelector: React.FC<AssignmentSelectorProps> = ({
  users,
  selectedUser,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8), // Add spacing between button and menu
      flip({
        padding: 8, // Add padding to flip calculation
        fallbackPlacements: ["top-start"], // Only flip to top when needed
      }),
      shift({ padding: 8 }), // Keep menu in viewport
    ],
    whileElementsMounted: autoUpdate,
    placement: "bottom-start", // Default to dropping down
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { styles: transitionStyles, isMounted } = useTransitionStyles(
    context,
    {}
  );

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <div className="relative flex w-full">
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className="flex gap-1 w-full cursor-pointer rounded-lg bg-white py-2.5 pl-2 text-left text-gray-900 ring-transparent ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent-500 text-sm sm:leading-6"
      >
        <span className="h-full w-4 flex items-center justify-center">
          {selectedUser && selectedUser.avatar ? (
            <img
              src={selectedUser.avatar}
              alt=""
              className="h-3 w-3 rounded-full"
            />
          ) : (
            <span className="bg-gray-100 rounded-full w-3 h-3 sm:w-4 sm:h-4 flex items-center justify-center">
              <UserIcon className="h-3 w-3" />
            </span>
          )}
        </span>
        <span className="flex-grow truncate text-sm mr-6">
          {selectedUser
            ? `${selectedUser.firstname} ${selectedUser.lastname}`
            : "Unassigned"}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-secondary-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`h-5 w-5 transition-transform duration-300 transform ${
              isOpen ? "rotate-180" : ""
            }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </button>

      {isMounted && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              ...transitionStyles,
            }}
            {...getFloatingProps()}
            className="z-50 max-h-56 w-full overflow-auto thin-scrollbar rounded-md bg-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs sm:text-sm"
          >
            <div
              key={"unassigned"}
              className="hover:bg-secondary-1000  cursor-pointer text-sm text-secondary-300 relative  select-none py-2 pl-3 pr-9"
              onClick={() => {
                handleChange("");
                setIsOpen(false);
              }}
            >
              <div className="flex items-center">
                <span className="bg-secondary-1000 rounded-full w-3 h-3 flex items-center justify-center">
                  <Person
                    className="text-secondary-100"
                    style={{
                      fontSize: "1rem",
                      height: "0.75rem",
                      width: "0.75rem",
                    }}
                  />
                </span>
                <span
                  className={classNames(
                    !selectedUser ? "font-semibold" : "font-normal",
                    "ml-3 block text-xs sm:text-sm"
                  )}
                >
                  Unassigned
                </span>
              </div>
            </div>
            {users.map((user) => {
              return (
                <div
                  key={user._id}
                  className="hover:bg-secondary-1000 cursor-pointer text-sm text-secondary-300 relative  select-none py-2 pl-3 pr-9"
                  onClick={() => {
                    handleChange(user._id);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-center">
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt=""
                        className="h-3 w-3 flex-shrink-0 rounded-full"
                      />
                    ) : (
                      <span className="bg-secondary-1000 rounded-full w-3 h-3 flex items-center justify-center">
                        <Person
                          className="text-secondary-100"
                          style={{
                            fontSize: "1rem",
                            height: "0.75rem",
                            width: "0.75rem",
                          }}
                        />
                      </span>
                    )}
                    <span
                      className={classNames(
                        selectedUser === user ? "font-semibold" : "font-normal",
                        "ml-3 block text-xs sm:text-sm"
                      )}
                    >
                      {getFirstInitial(user.firstname)}. {user.lastname}
                    </span>
                    {selectedUser && selectedUser._id === user._id && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <Check className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default AssignmentSelector;
