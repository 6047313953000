import {
  Fragment,
  useRef,
  useState,
  useContext,
  MutableRefObject,
  RefObject,
  useEffect,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  ExpandMore,
} from "@mui/icons-material";
import { classNames, decodedString } from "../../utils/FormatFunctions";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import { RecurringTask } from "../../types/RecurringTask";
import { useLog } from "../../hooks/useLog";
import Button from "../general/Button";
import Input from "../general-ui/Input";
import Loader from "./Loader";

interface LogPrintProps {}

const LogPrint: React.FC<LogPrintProps> = ({}) => {
  const {
    logs,
    showState,
    updateShowState,
    selectedTab,
    submitPrint,
    isPrinting,
  } = useLog();

  interface FormData {
    startDate: string;
    endDate: string | null;
    logs: { [key: string]: any };
    selected: { [key: string]: boolean };
    fileType: "pdf" | "excel";
  }

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<FormData>({
    defaultValues: {
      startDate: moment().startOf("year").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      logs: {},
      selected: {},
      fileType: "pdf",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const [month, setMonth] = useState(moment().month());

  const days = Array.from({ length: 42 }, (_, i) => {
    const date = moment().month(month).startOf("month").add(i, "days");

    return {
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: date.month() === month,
      isToday: date.isSame(moment(), "day"),
      isSelected:
        date.isSame(watch("startDate"), "day") ||
        date.isSame(watch("endDate"), "day"),
    };
  });

  const [expandedLogs, setExpandedLogs] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleLog = (id: string) => {
    setExpandedLogs((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const calendar = () => {
    const handleDateSelect = (date: string) => {
      const startDate = watch("startDate");
      const endDate = watch("endDate");

      if (!startDate || moment(date).isBefore(startDate)) {
        setValue("startDate", date);
      } else if (!endDate || moment(date).isAfter(endDate)) {
        setValue("endDate", date);
      } else {
        setValue("startDate", date);
        setValue("endDate", null);
      }
    };
    return (
      <Fragment>
        <div className="flex items-center text-secondary-100">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-secondary-200 hover:text-secondary-300"
            onClick={() => setMonth(month - 1)}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto text-center text-sm font-semibold">
            {moment().month(month).format("MMMM")}
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-secondary-200 hover:text-secondary-300"
            onClick={() => setMonth(month + 1)}
          >
            <span className="sr-only">Next month</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-secondary-100">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-secondary-1000 text-sm shadow ring-1 ring-secondary-1100">
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                "py-1.5 hover:bg-secondary-1100 focus:z-10",
                day.isCurrentMonth ? "bg-primary" : "bg-primary",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-primary",
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-secondary-100",
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-secondary-700",
                day.isToday && !day.isSelected && "text-accent-500",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg"
              )}
              onClick={() => handleDateSelect(day.date)}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-accent-500",
                  day.isSelected && !day.isToday && "bg-secondary-100"
                )}
              >
                {day &&
                  day.date &&
                  day.date.split("-").pop()?.replace(/^0/, "")}
              </time>
            </button>
          ))}
        </div>
      </Fragment>
    );
  };

  const dateRange = () => {
    return (
      <div className=" flex flex-col gap-2 pr-2 mb-1">
        <h3 className="text-center text-secondary-100 font-medium border-b border-accent-500">
          Select date range
        </h3>
        <div className="justify-center col-span-2 flex space-x-2 items-center text-secondary-400">
          <input
            {...register("startDate")}
            type="date"
            className="text-sm border border-secondary-1000  rounded-sm focus:ring-1 focus:ring-secondary-700 focus:border-transparent"
          />
          <span className="text-xs font-semibold text-secondary-100">To</span>

          <input
            {...register("endDate")}
            type="date"
            className="text-sm border border-secondary-1000 rounded-sm focus:ring-1 focus:ring-secondary-700 focus:border-transparent"
          />
        </div>
        <div className="col-span-2">{calendar()}</div>
      </div>
    );
  };

  const selectSections = () => {
    // Add check for logSections being null/undefined
    const sections = Array.isArray(logs) ? logs : [];
    return (
      <div className=" flex flex-col  px-2 items-center justify-start">
        <h3 className="w-full text-center text-secondary-100 font-medium border-b border-accent-500">
          Select sections
        </h3>
        <ul className="w-full h-full overflow-y-auto bg-secondary-1200 max-h-[350px] px-4">
          {sections.map((log: RecurringTask) => (
            <li
              className="flex flex-col items-start my-4  justify-start"
              key={log._id}
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-4">
                  <input
                    {...register(`selected.${log._id}`)}
                    defaultChecked={true}
                    onChange={(e) => {
                      log.inputs.forEach((input) => {
                        setValue(
                          `logs.${log._id}.${input.name}`,
                          e.target.checked,
                          { shouldDirty: true }
                        );
                      });
                    }}
                    type="checkbox"
                    id={log._id}
                    className="text-sm rounded-full ring-accent-500 focus:ring-accent-500 text-accent-500"
                  />

                  <label
                    htmlFor={log._id}
                    className="text-sm text-secondary-300 truncate max-w-32"
                  >
                    {log.title}
                  </label>
                </div>
                <button type="button" onClick={() => toggleLog(log._id)}>
                  {expandedLogs[log._id] ? <ExpandMore /> : <ChevronRight />}
                </button>
              </div>

              <ul
                className={`ml-2 pl-2 border-l border-secondary-1000 ${
                  expandedLogs[log._id] ? "" : "hidden"
                }`}
              >
                {log.inputs.map((input) => (
                  <li key={input.name} className="flex items-center gap-4 my-1">
                    <Controller
                      control={control}
                      name={`logs.${log._id}.${input.name}`}
                      defaultValue={true}
                      render={({ field }) => (
                        <input
                          {...field}
                          checked={field.value}
                          type="checkbox"
                          id={input.name}
                          className="text-sm rounded-full ring-accent-500 focus:ring-accent-500 text-accent-500"
                        />
                      )}
                    />

                    <label
                      htmlFor={input.name}
                      className="text-xs text-secondary-300 truncate max-w-20"
                    >
                      {decodedString(input.name)}
                    </label>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="flex w-full gap-2 ">
          <button
            type="button"
            className="bg-accent-400 text-primary py-1 text-xs rounded-sm flex w-full justify-center"
            onClick={() => {
              sections.forEach((log: RecurringTask) => {
                setValue(`selected.${log._id}`, true, { shouldDirty: true });
                log.inputs.forEach((input) => {
                  setValue(`logs.${log._id}.${input.name}`, true, {
                    shouldDirty: true,
                  });
                });
              });
            }}
          >
            Select all
          </button>
          <button
            type="button"
            className="bg-secondary-1000 text-secondary-100  py-1 text-xs rounded-sm flex w-full justify-center"
            onClick={() => {
              sections.forEach((log: RecurringTask) => {
                setValue(`selected.${log._id}`, false, { shouldDirty: true });
                log.inputs.forEach((input) => {
                  setValue(`logs.${log._id}.${input.name}`, false, {
                    shouldDirty: true,
                  });
                });
              });
            }}
          >
            Deselect all
          </button>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(submitPrint)} className="flex h-full flex-col">
      {isPrinting && (
        <Loader fullScreen size="large" overlayClass="bg-black bg-opacity-60" />
      )}
      <div className="justify-center  pb-2 min-h-96 max-h-screen flex flex-col sm:flex-row divide-x">
        {dateRange()}
        {selectedTab !== "work-orders" && selectSections()}
      </div>
      {selectedTab === "work-orders" && (
        <Input
          type="select"
          options={[
            { label: "PDF", value: "pdf" },
            { label: "Excel", value: "excel" },
          ]}
          label="Export Type"
          name="fileType"
          register={register}
        />
      )}
      <div className="flex w-full gap-2 pt-2 border-t  border-secondary-1000 ">
        <Button
          type="button"
          onClick={() => updateShowState("showPrintState", false)}
          children="Cancel"
          styleString="secondary"
        />
        <Button type="submit" children="Print" styleString="primary" />
      </div>
    </form>
  );
};

export default LogPrint;
